import { Translation } from "~/common/Translation"
import { Card, CardContent } from "~/ui/card"
import { H3, P } from "~/ui/typography"
import { useEffect, useRef, useCallback, useMemo } from "react"
import debounce from "lodash/debounce"
import { Button } from "~/ui/button"
import { useScreenVariant } from "~/screens/ScreenVariantsProvider"
import { useLead } from "~/common/LeadContext"
import { Form } from "~/ui/form"
import {
  AhoyEventTypeEnum,
  CommunitySlug,
  ReferralSourceEnum,
  ScreenEnum,
  UserResponsibilitiesInput,
  UserResponsibilityQuestionsEnum,
  UserSignupInput,
} from "~/__generated__/graphql"
import { gql } from "~/__generated__"
import { LEAD_UPDATE_MUTATION } from "~/common/useLead"
import { useForm } from "react-hook-form"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { useSafeMutation } from "~/common/useSafeMutation"
import { TextField } from "~/components/forms/TextField"
import { ResponsibilityQuestions } from "./ApplicationLandingScreen/ResponsibilityQuestions"
import { MadlibsQuestions } from "./ApplicationLandingScreen/MadlibsQuestions"
import { displayErrors } from "~/common/validations"
import { useTranslation } from "react-i18next"
import { camelCase } from "change-case"
import {
  applicationFitWaitingScreenPath,
  applicationMostExcitedAboutPath,
} from "~/common/paths"
import { useLogEvent } from "~/analytics/EventsContext"
import { useInternalSearchParams } from "~/common/useInternalSearchParams"
import { useApplicationLayout } from "../layouts/ApplicationLayout"
import { cn } from "~/lib/utils"
import { useConfig } from "~/common/ConfigProvider"

const responsibilityQuestionsSchema = z.object({
  // Safespace
  definingGuidingAndImplementingThePeopleStrategy: z.boolean(),
  actingAsAKeyDecisionMaker: z.boolean(),
  recruitingAmazingTalent: z.boolean(),
  onboardingCompensationPerformanceManagement: z.boolean(),
  managingHrBudgetAndAllocatingResources: z.boolean(),
  designingAndImplementingTrainingPrograms: z.boolean(),
  allOperationsWithinMyCompany: z.boolean(),
  noneOfTheAbove: z.boolean(),

  // BoardRoom
  leadingOrAdvisingOnLongTermStrategy: z.boolean(),
  leadingOrContributingToCorporateDevelopmentEfforts: z.boolean(),
  managingMyPatientsAndTheirHealthJourney: z.boolean(),
  overseeingDailyOperationsThatHelpMyOrgGrowAndOperate: z.boolean(),
  ensuringOrganizationalComplianceWithHealthcareLaws: z.boolean(),
  contributingToHealthcareInvestmentResearchAndOrAnalysis: z.boolean(),
  workingWithInfluentialStakeholdersToDriveHealthSystem: z.boolean(),
  facilitatingDevelopmentAndOrBrokerageOfRealEstate: z.boolean(),
  expandingBusinessDevelopmentEffortsSalesMarketing: z.boolean(),

  // Marketingland
  managementOfAMarketingTeam: z.boolean(),
  developmentAndExecutionOfStrategicMarketingPlans: z.boolean(),
  marketResearchAndConsumerInsights: z.boolean(),
  contentAndBrandStrategy: z.boolean(),
  drivingRevenueThroughLeadGenerationEfforts: z.boolean(),
  salesBusinessDevelopmentTeam: z.boolean(),
  aStrategyAndBudgetForAtLeastOneMarketingChannel: z.boolean(),
  assessmentAndSelectionOfOutsideVendorsToolsAndAgencies: z.boolean(),

  // Go to Millions
  makingProductAndBrandLaunchesSuccessful: z.boolean(),
  theMarginLtvAndOverallGrowthOfOurEcomBusiness: z.boolean(),
  supplyChainAndOperations: z.boolean(),
  ourCompanySMarketingBudgetInPartOrFull: z.boolean(),
  theDevelopmentAndExecutionOfStrategicMarketingPlans: z.boolean(),
  theDesignEditingOrCreativeDirectionOfOurMarketing: z.boolean(),
  managementAndSelectionOfVendorsTechnologyAndAgencies: z.boolean(),
  developingPartnershipsWithEcomBrandsAsAVendorOrAgency: z.boolean(),

  // Fintech Takes
  drivingTheDevelopmentOfInnovativeFinancialProducts: z.boolean(),
  analyzingMarketTrendsAndLeveragingDataInsights: z.boolean(),
  enhancingRiskAndComplianceFrameworks: z.boolean(),
  managingLargeScaleInitiatives: z.boolean(),
  focusingOnB2cMarketingEfforts: z.boolean(),
  buildingStrategicPartnerships: z.boolean(),
  applyingAdvancedAnalyticsOrMachineLearning: z.boolean(),
  leadingOperationalScalingEffortsThroughProcess: z.boolean(),
  handlingHrAdministrationCompanyPolicies: z.boolean(),
  // FTT - New
  buildingProductsAndServicesThatCustomersWillLove: z.boolean(),
  figuringOutWhatsHappeningInTheIndustry: z.boolean(),
  managingCreditFraudAndComplianceRisk: z.boolean(),
  developingOrMaintainingMyCompanysTechStack: z.boolean(),
  runningB2cMarketingCampaigns: z.boolean(),
  buildingPartnershipsWithThirdParties: z.boolean(),
  developingAnalyticModels: z.boolean(),
  solvingOperationalProblemsAndHelpingMyCompany: z.boolean(),
  managingHumanResourcesHr: z.boolean(),
})

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  yearsOfExperience: z.string(),
  jobFunction: z.string(),
  jobTitle: z.string(),
  teamSize: z.string(),
  madlibsRole: z.string(),
  organizationType: z.string(),
  business: z.string(),
  cupsOfCoffee: z.string(),
  ecomIndustry: z.string(),
  revenue: z.string(),
  responsibilityQuestions: responsibilityQuestionsSchema,
})

const REQUIRED_FIELDS_FOR_COMMUNITY: Record<CommunitySlug, string[]> = {
  [CommunitySlug.Safespace]: [
    "yearsOfExperience",
    "jobFunction",
    "jobTitle",
    "teamSize",
    "madlibsRole",
  ],
  [CommunitySlug.Boardroom]: [
    "yearsOfExperience",
    "jobFunction",
    "jobTitle",
    "organizationType",
  ],
  [CommunitySlug.Marketingland]: [
    "yearsOfExperience",
    "business",
    "jobFunction",
    "organizationType",
    "teamSize",
    "cupsOfCoffee",
  ],
  [CommunitySlug.Gotomillions]: [
    "yearsOfExperience",
    "jobFunction",
    "jobTitle",
    "organizationType",
    "ecomIndustry",
    "revenue",
  ],
  [CommunitySlug.Fintechtakes]: [
    "yearsOfExperience",
    "jobFunction",
    "jobTitle",
    "organizationType",
  ],
}

const getFormSchemaWithRequiredFields = (communitySlug: CommunitySlug) => {
  return formSchema.superRefine((data, ctx) => {
    if (!data.email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Email is required",
        path: ["email"],
      })
    }

    REQUIRED_FIELDS_FOR_COMMUNITY[communitySlug].forEach((field) => {
      if (!data[field as keyof typeof data]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${field} is required`,
          path: [field],
        })
      }
    })

    if (data.responsibilityQuestions) {
      const hasSelectedResponsibility = Object.values(
        data.responsibilityQuestions
      ).some((value) => value === true)

      if (!hasSelectedResponsibility) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please select at least one responsibility",
          path: ["responsibilityQuestions"],
        })
      }
    }
  })
}

export type FormValues = z.infer<typeof formSchema>
type ResponsibilityQuestionValues = z.infer<
  typeof responsibilityQuestionsSchema
>

export const ApplicationLandingScreen = () => {
  const ccls = useCommunityClassname()
  const hasLoggedEmailEvent = useRef(false)
  const preventDuplicateRef = useRef(false)
  const { logEvent } = useLogEvent()
  const internalSearchParams = useInternalSearchParams()
  const screenVariant = useScreenVariant(
    ScreenEnum.ApplicationLandingScreen,
    internalSearchParams.get("v")
  )
  const community = useCommunity()
  const { setBanner } = useApplicationLayout()

  const { userResponsibilityQuestions } = useCommunity()
  const { qaToolsEnabled } = useConfig()
  const {
    lead,
    loading: leadLoading,
    clearLead,
  } = useLead({ preventDuplicateRef })
  const [runUserCreate, { loading }] = useSafeMutation(USER_SIGNUP_MUTATION)
  const [runLeadUpdate] = useSafeMutation(LEAD_UPDATE_MUTATION)

  const form = useForm<FormValues>({
    resolver: zodResolver(getFormSchemaWithRequiredFields(community.slug)),
    defaultValues: {
      email: "",
      yearsOfExperience: "",
      jobFunction: "",
      jobTitle: "",
      madlibsRole: "",
      organizationType: "",
      teamSize: "",
      business: "",
      cupsOfCoffee: "",
      ecomIndustry: "",
      revenue: "",
      responsibilityQuestions: {
        // Safespace
        definingGuidingAndImplementingThePeopleStrategy: false,
        actingAsAKeyDecisionMaker: false,
        recruitingAmazingTalent: false,
        onboardingCompensationPerformanceManagement: false,
        managingHrBudgetAndAllocatingResources: false,
        designingAndImplementingTrainingPrograms: false,
        allOperationsWithinMyCompany: false,
        noneOfTheAbove: false,

        // BoardRoom
        leadingOrAdvisingOnLongTermStrategy: false,
        leadingOrContributingToCorporateDevelopmentEfforts: false,
        managingMyPatientsAndTheirHealthJourney: false,
        overseeingDailyOperationsThatHelpMyOrgGrowAndOperate: false,
        ensuringOrganizationalComplianceWithHealthcareLaws: false,
        contributingToHealthcareInvestmentResearchAndOrAnalysis: false,
        workingWithInfluentialStakeholdersToDriveHealthSystem: false,
        facilitatingDevelopmentAndOrBrokerageOfRealEstate: false,
        expandingBusinessDevelopmentEffortsSalesMarketing: false,

        // Marketingland
        managementOfAMarketingTeam: false,
        developmentAndExecutionOfStrategicMarketingPlans: false,
        marketResearchAndConsumerInsights: false,
        contentAndBrandStrategy: false,
        drivingRevenueThroughLeadGenerationEfforts: false,
        salesBusinessDevelopmentTeam: false,
        aStrategyAndBudgetForAtLeastOneMarketingChannel: false,
        assessmentAndSelectionOfOutsideVendorsToolsAndAgencies: false,

        // Go to Millions
        makingProductAndBrandLaunchesSuccessful: false,
        theMarginLtvAndOverallGrowthOfOurEcomBusiness: false,
        supplyChainAndOperations: false,
        ourCompanySMarketingBudgetInPartOrFull: false,
        theDevelopmentAndExecutionOfStrategicMarketingPlans: false,
        theDesignEditingOrCreativeDirectionOfOurMarketing: false,
        managementAndSelectionOfVendorsTechnologyAndAgencies: false,
        developingPartnershipsWithEcomBrandsAsAVendorOrAgency: false,

        // Fintech Takes
        drivingTheDevelopmentOfInnovativeFinancialProducts: false,
        analyzingMarketTrendsAndLeveragingDataInsights: false,
        enhancingRiskAndComplianceFrameworks: false,
        managingLargeScaleInitiatives: false,
        focusingOnB2cMarketingEfforts: false,
        buildingStrategicPartnerships: false,
        applyingAdvancedAnalyticsOrMachineLearning: false,
        leadingOperationalScalingEffortsThroughProcess: false,
        handlingHrAdministrationCompanyPolicies: false,

        // FTT - New
        buildingProductsAndServicesThatCustomersWillLove: false,
        figuringOutWhatsHappeningInTheIndustry: false,
        managingCreditFraudAndComplianceRisk: false,
        developingOrMaintainingMyCompanysTechStack: false,
        runningB2cMarketingCampaigns: false,
        buildingPartnershipsWithThirdParties: false,
        developingAnalyticModels: false,
        solvingOperationalProblemsAndHelpingMyCompany: false,
        managingHumanResourcesHr: false,
      },
    },
  })
  const { t } = useTranslation("responsibilityQuestions")
  const { setValue, watch } = form
  const hasLoggedFirstChange = useRef(false)

  // Watch all relevant form fields
  const formValues = watch([
    "yearsOfExperience",
    "cupsOfCoffee",
    "jobFunction",
    "jobTitle",
    "madlibsRole",
    "teamSize",
    "organizationType",
    "business",
  ])

  const formResponsibilityQuestions = watch("responsibilityQuestions")

  useEffect(() => {
    if (
      !hasLoggedFirstChange.current &&
      (formValues.some((value) => value) ||
        Object.values(formResponsibilityQuestions).some((value) => value))
    ) {
      logEvent(AhoyEventTypeEnum.ApplicationStarted)
      if (lead) {
        runLeadUpdate({
          variables: {
            input: {
              id: lead.id,
              applicationStartedAt: new Date(),
            },
          },
        })
      }
      hasLoggedFirstChange.current = true
    }
  }, [
    formValues,
    community.slug,
    logEvent,
    formResponsibilityQuestions,
    lead,
    runLeadUpdate,
  ])

  useEffect(() => {
    if (!leadLoading && lead && lead.email) {
      form.setValue("email", lead.email)
    }
  }, [lead, form, leadLoading])

  const onSubmit = async (formData: FormValues) => {
    const userResponsibilitiesInput: UserResponsibilitiesInput = {}

    Object.values(UserResponsibilityQuestionsEnum).forEach((question) => {
      if (!userResponsibilityQuestions.includes(question)) {
        return
      }

      userResponsibilitiesInput[
        camelCase(question) as keyof UserResponsibilitiesInput
      ] = {
        question: t(question),
        answer:
          formData.responsibilityQuestions[
            camelCase(question) as keyof FormValues["responsibilityQuestions"]
          ],
      }
    })

    const userInput: UserSignupInput = {
      email: formData.email,
      userSignupMadlibsInput: {
        yearsOfExperience: formData.yearsOfExperience,
        jobFunction: formData.jobFunction,
        jobTitle: formData.jobTitle,
        teamSize: formData.teamSize,
        madlibsRole: formData.madlibsRole,
        cupsOfCoffee: formData.cupsOfCoffee,
        business: formData.business,
        organizationType: formData.organizationType,
        ecomIndustry: formData.ecomIndustry,
        revenue: formData.revenue,
      },
      userResponsibilities: userResponsibilitiesInput,
    }

    const { errors } = await runUserCreate({
      variables: {
        input: {
          leadId: lead?.id,
          ...userInput,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      return false
    } else {
      logEvent(AhoyEventTypeEnum.ApplicationSubmitted)
      if (lead?.source === ReferralSourceEnum.AdminInvited) {
        clearLead()
        window.location.href = applicationMostExcitedAboutPath.pattern
      } else {
        clearLead()
        window.location.href = applicationFitWaitingScreenPath.pattern
      }
    }
  }

  type KeyValue = {
    key: keyof FormValues
    value: string
  }
  const FIT_QUESTIONS: Record<
    CommunitySlug,
    {
      madlibs: KeyValue[]
      responsibilityQuestions: Partial<ResponsibilityQuestionValues> | []
    }
  > = {
    [CommunitySlug.Safespace]: {
      madlibs: [
        {
          key: "yearsOfExperience",
          value: "10+",
        },
        {
          key: "jobFunction",
          value: "Compensation & Benefits",
        },
        {
          key: "madlibsRole",
          value: "am an IC",
        },
        {
          key: "teamSize",
          value: "1",
        },
        {
          key: "jobTitle",
          value: "Manager",
        },
      ],
      responsibilityQuestions: {
        definingGuidingAndImplementingThePeopleStrategy: true,
      },
    },
    [CommunitySlug.Boardroom]: {
      madlibs: [
        { key: "yearsOfExperience", value: "4-10" },
        { key: "jobFunction", value: "Consulting" },
        { key: "jobTitle", value: "Consultant" },
        { key: "organizationType", value: "Consulting Firm" },
      ],
      responsibilityQuestions: {
        leadingOrAdvisingOnLongTermStrategy: true,
      },
    },
    [CommunitySlug.Marketingland]: {
      madlibs: [
        { key: "yearsOfExperience", value: "6-10" },
        { key: "business", value: "B2C" },
        { key: "organizationType", value: "at a brand" },
        { key: "teamSize", value: "2-5" },
        { key: "cupsOfCoffee", value: "4 cups" },
        { key: "jobFunction", value: "Influencer" },
        { key: "jobTitle", value: "Marketing Manager" },
      ],
      responsibilityQuestions: {
        managementOfAMarketingTeam: true,
      },
    },
    [CommunitySlug.Gotomillions]: {
      madlibs: [
        { key: "yearsOfExperience", value: "5-10" },
        { key: "jobFunction", value: "amazon" },
        { key: "organizationType", value: "ecom brand" },
        { key: "jobTitle", value: "Brand Manager" },
        { key: "revenue", value: "$1M-$10M" },
        { key: "ecomIndustry", value: "Fitness" },
      ],
      responsibilityQuestions: {
        makingProductAndBrandLaunchesSuccessful: true,
      },
    },
    [CommunitySlug.Fintechtakes]: {
      madlibs: [
        { key: "yearsOfExperience", value: "6-12" },
        {
          key: "jobFunction",
          value: "Compliance, Legal, & Regulatory Affairs",
        },
        { key: "jobTitle", value: "Compliance Officer" },
        { key: "organizationType", value: "BaaS platform" },
      ],
      responsibilityQuestions: {
        buildingProductsAndServicesThatCustomersWillLove: true,
      },
    },
  }

  const UNFIT_QUESTIONS: Record<
    CommunitySlug,
    {
      madlibs: KeyValue[]
      responsibilityQuestions: Partial<ResponsibilityQuestionValues> | []
    }
  > = {
    [CommunitySlug.Safespace]: {
      madlibs: [
        {
          key: "yearsOfExperience",
          value: "10+",
        },
        {
          key: "jobFunction",
          value: "Compensation & Benefits",
        },
        {
          key: "madlibsRole",
          value: "manage a team(s)",
        },
        {
          key: "teamSize",
          value: "1",
        },
        {
          key: "jobTitle",
          value: "Manager",
        },
      ],
      responsibilityQuestions: {
        noneOfTheAbove: true,
      },
    },
    [CommunitySlug.Boardroom]: {
      madlibs: [
        { key: "yearsOfExperience", value: "4-10" },
        { key: "jobFunction", value: "Consulting" },
        { key: "jobTitle", value: "Consultant" },
        { key: "organizationType", value: "Consulting Firm" },
      ],
      responsibilityQuestions: {
        facilitatingDevelopmentAndOrBrokerageOfRealEstate: true,
      },
    },
    [CommunitySlug.Marketingland]: {
      madlibs: [
        { key: "yearsOfExperience", value: "0-5" },
        { key: "business", value: "B2C" },
        { key: "organizationType", value: "at an agency" },
        { key: "teamSize", value: "just me!" },
        { key: "cupsOfCoffee", value: "1 cup" },
        { key: "jobFunction", value: "Influencer" },
        { key: "jobTitle", value: "Marketing Manager" },
      ],
      responsibilityQuestions: {
        noneOfTheAbove: true,
      },
    },
    [CommunitySlug.Gotomillions]: {
      madlibs: [
        { key: "yearsOfExperience", value: "0-4" },
        { key: "jobFunction", value: "account based marketing" },
        { key: "jobTitle", value: "Brand Manager" },
        { key: "organizationType", value: "agency" },
        { key: "revenue", value: "$0-$100k" },
        { key: "ecomIndustry", value: "Fitness" },
      ],
      responsibilityQuestions: {
        noneOfTheAbove: true,
      },
    },
    [CommunitySlug.Fintechtakes]: {
      madlibs: [
        { key: "yearsOfExperience", value: "0-5" },
        { key: "jobFunction", value: "Sales" },
        { key: "jobTitle", value: "Sales Manager" },
        { key: "organizationType", value: "Other" },
      ],
      responsibilityQuestions: {
        runningB2cMarketingCampaigns: true,
      },
    },
  }

  const selectQuestions = (questionType: "fit" | "unfit") => {
    const questions =
      questionType === "fit"
        ? FIT_QUESTIONS[community.slug]
        : UNFIT_QUESTIONS[community.slug]

    questions.madlibs.forEach((question) => {
      setValue(question.key, question.value)
    })

    Object.entries(questions.responsibilityQuestions).forEach(
      ([key, value]) => {
        setValue(
          `responsibilityQuestions.${key}` as `responsibilityQuestions.${keyof ResponsibilityQuestionValues}`,
          value
        )
      }
    )
  }

  const debouncedHandleValidEmail = useMemo(
    () =>
      debounce((email: string) => {
        if (!hasLoggedEmailEvent.current || (lead && lead.email !== email)) {
          logEvent(AhoyEventTypeEnum.ApplicationEmailEntered)
          if (lead?.id) {
            runLeadUpdate({
              variables: {
                input: {
                  id: lead.id,
                  email,
                  applicationStartedAt: new Date(),
                },
              },
            })
          }
          hasLoggedEmailEvent.current = true
        }
      }, 3000),
    [lead, logEvent, runLeadUpdate]
  )

  const handleValidEmail = useCallback(
    (email: string) => debouncedHandleValidEmail(email),
    [debouncedHandleValidEmail]
  )

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    form.setValue("email", email)

    const isValidEmail = formSchema.shape.email.safeParse(email).success
    if (isValidEmail) {
      handleValidEmail(email)
    }
  }

  useEffect(() => {
    if (screenVariant?.overrides?.options?.showBanner) {
      setBanner(
        <Translation
          ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
          i18nKey="bannerCopy"
        />
      )
    }

    return () => {
      setBanner()
    }
  }, [screenVariant, setBanner])

  return (
    <div className="flex flex-col items-center gap-8">
      {qaToolsEnabled && (
        <div className="hidden sm:block fixed bottom-8 right-8 z-20">
          <div className="flex flex-col items-end space-y-4">
            <h3 className="text-sm font-medium text-gray-500">QA Tools</h3>
            <div className="flex flex-col space-y-2">
              <button
                onClick={() => selectQuestions("fit")}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 text-sm"
              >
                Select Fit
              </button>
              <button
                onClick={() => selectQuestions("unfit")}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 text-sm"
              >
                Select Unfit
              </button>
            </div>
          </div>
        </div>
      )}
      <H3 className="text-center max-w-128">
        <Translation
          ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
          i18nKey={"title"}
        />
      </H3>
      <P className="text-center">
        <Translation
          ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
          i18nKey={"subtitle"}
        />
      </P>
      <div className="relative mt-8 max-w-[700px] mx-auto">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <Card>
              <CardContent className="p-8">
                <TextField
                  containerClassName="sm:flex sm:gap-2 sm:items-center sm:space-y-0"
                  inputClassName="text-base sm:text-sm"
                  labelClassName="min-w-[140px] lg:min-w-[200px] text-lg font-normal"
                  control={form.control}
                  name="email"
                  label="Email Address"
                  type="email"
                  onChange={onEmailChange}
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent className="p-8 text-lg">
                <MadlibsQuestions control={form.control} />
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-8">
                <p className="text-lg mb-6">
                  <Translation
                    ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
                    i18nKey="responsibilityQuestionsLabel"
                  />
                </p>
                <ResponsibilityQuestions
                  control={form.control}
                  userResponsibilityQuestions={userResponsibilityQuestions}
                />
                <Button
                  type="submit"
                  className={cn(
                    "mt-8",
                    ccls({
                      [CommunitySlug.Gotomillions]: "bg-primary",
                      [CommunitySlug.Fintechtakes]: "bg-primary",
                      default: "bg-highlight",
                    })
                  )}
                  disabled={loading}
                >
                  <Translation
                    ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
                    i18nKey="submitCopy"
                  />
                </Button>
                <p className="font-sans text-muted-foreground text-3xs w-full mt-8">
                  <Translation
                    ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
                    i18nKey="disclaimer"
                  />
                </p>
              </CardContent>
            </Card>
          </form>
        </Form>
      </div>
    </div>
  )
}

const USER_SIGNUP_MUTATION = gql(`
  mutation LandingUserSignup($input: UserSignupInput!) {
    userSignup(input: $input) {
      user {
        id
      }
      sessionId
    }
  }
`)
