import { FetchResult, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMemo, useState } from "react"
import { useForm, useFormState, useWatch } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import {
  ChannelCreateInput,
  ChannelCreateMutation,
  ChannelUpdateMutation,
  ChannelVisibilityEnum,
} from "~/__generated__/graphql"
import { Form } from "~/ui/form"
import { AdminActionToolbar } from "./AdminActionToolbar"
import { Switch } from "~/ui/switch"
import { Button } from "~/ui/button"
import { TextField } from "~/components/forms/TextField"
import { GROUPS_QUERY_DOCUMENT } from "~/screens/admin/AdminGroupsScreen"
import { SearchableSelectField } from "~/components/forms/SearchableSelectField"
import { cn } from "~/lib/utils"
import { UploadDropzone } from "~/ui/UploadDropzone"

const formSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Required" })
      .max(30, { message: "Cannot exceed 30 characters" }),
    description: z
      .string()
      .max(75, { message: "Cannot exceed 75 characters" })
      .optional(),
    groupId: z.string().optional(),
    modalHeader: z.string().nullable().optional(),
    modalSubheader: z.string().nullable().optional(),
    imageId: z.string().nullable().optional(),
    active: z.boolean(),
    visibility: z.nativeEnum(ChannelVisibilityEnum),
  })
  .superRefine(({ groupId, visibility }, ctx) => {
    // groupId is required if visibility is private
    if (visibility === ChannelVisibilityEnum.Private && !groupId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["groupId"],
      })
    }

    return ctx
  })

export type ChannelFormValues = z.infer<typeof formSchema>

interface ChannelFormProps {
  initialValues?: Partial<ChannelFormValues> & { imageUrl?: string }
  onSubmit: (
    data: ChannelCreateInput
  ) => Promise<
    FetchResult<ChannelCreateMutation> | FetchResult<ChannelUpdateMutation>
  >
  visibility: ChannelVisibilityEnum
}

export const ChannelForm = ({
  initialValues,
  onSubmit,
  visibility,
}: ChannelFormProps) => {
  const form = useForm<ChannelFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      groupId: undefined,
      modalHeader: "",
      modalSubheader: "",
      imageId: undefined,
      active: false,
      visibility: visibility,
      ...(initialValues || {}),
    },
  })
  const { isDirty } = useFormState({ control: form.control })
  const [isSaving, setIsSaving] = useState(false)
  const { data, loading } = useQuery(GROUPS_QUERY_DOCUMENT)

  const handleSubmit = async ({ groupId, ...data }: ChannelFormValues) => {
    setIsSaving(true)
    try {
      await onSubmit({
        ...data,
        groupIds: groupId ? [groupId] : [],
        visibility,
      })
    } catch (e) {
      console.error(e)
      toast.error("Failed to save channel")
    }
    setIsSaving(false)
  }

  const groupIdSelectEntries = useMemo(() => {
    if (!data?.groups.nodes) return []
    return data.groups.nodes.map((group) => ({
      label: `@${group.slug}`,
      value: group.id,
    }))
  }, [data])

  const values = useWatch({ control: form.control })

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4 mb-8">
            <div>
              {Object.keys(form.formState.errors).length > 0 &&
                Object.entries(form.formState.errors).map(([key, error]) => (
                  <div key={key} className="text-error text-3xs">
                    {key} {error.message}
                  </div>
                ))}
            </div>
            <div>
              {visibility}
              {values.visibility}
            </div>
            <TextField
              control={form.control}
              name="name"
              label="Channel Name"
              required
              endAdornment={
                <div
                  className={cn(
                    "text-3xs whitespace-nowrap self-end p-1",
                    values.name && values.name.length > 30 && "text-error"
                  )}
                >
                  {values.name?.length || 0}/30
                </div>
              }
            />

            <TextField
              control={form.control}
              name="description"
              label="Channel Description"
              endAdornment={
                <div
                  className={cn(
                    "text-3xs whitespace-nowrap self-end p-1",
                    values.description &&
                      values.description.length > 75 &&
                      "text-error"
                  )}
                >
                  {values.description?.length || 0}/75
                </div>
              }
            />

            {visibility === ChannelVisibilityEnum.Private && (
              <>
                <SearchableSelectField
                  control={form.control}
                  name="groupId"
                  label="Link Existing Group"
                  selectEntries={groupIdSelectEntries}
                  disabled={loading}
                />

                <TextField
                  control={form.control}
                  name="modalHeader"
                  label="Modal Header (Optional)"
                />

                <TextField
                  control={form.control}
                  name="modalSubheader"
                  label="Modal Subheader (Optional)"
                />

                <UploadDropzone
                  control={form.control}
                  name="imageId"
                  initialUrl={initialValues?.imageUrl}
                  title={<>Upload A Channel Image</>}
                  body={
                    <>
                      Channel images are displayed in a modal when users first
                      land on the channel. These image are also used in the
                      “Feed” with options for users to “Upgrade” or “Join”.
                    </>
                  }
                />
              </>
            )}
          </div>

          <AdminActionToolbar>
            <Switch
              variant="selector"
              size="lg"
              options={["Inactive", "Active"]}
              checked={values.active}
              onCheckedChange={(value) =>
                form.setValue("active", !!value, { shouldDirty: true })
              }
            />
            <Button
              type="submit"
              isLoading={form.formState.isSubmitting}
              disabled={isSaving || !isDirty}
            >
              Save Channel
            </Button>
          </AdminActionToolbar>
        </form>
      </Form>
    </>
  )
}
