import { ApolloError, FetchResult } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { EyeNoneIcon, DotsHorizontalIcon } from "@radix-ui/react-icons"
import copy from "copy-to-clipboard"
import { AtSign } from "lucide-react"
import React, { useMemo, useRef, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import {
  GroupCreateInput,
  GroupCreateMutation,
  GroupTypeEnum,
  GroupUpdateMutation,
  TierLevelEnum,
  User_AdminFragment,
  UserSortEnum,
} from "~/__generated__/graphql"
import { AdminActionToolbar } from "~/admin/AdminActionToolbar"
import { RulesEngineConditionsBuilder } from "~/admin/RulesEngineConditionBuilder"
import { AdminTableHeader, UsersTable } from "~/admin/users/UsersTable"
import { SelectField } from "~/components/forms/SelectField"
import { TextField } from "~/components/forms/TextField"
import { UserDialog } from "~/directory/UserDialog"
import { UserName } from "~/directory/UserName"
import { useTiers } from "~/tiers/TiersProvider"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { Badge } from "~/ui/badge"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "~/ui/form"
import { IconInputAdornment } from "~/ui/input"
import { Switch } from "~/ui/switch"
import { TableCell, TableRow } from "~/ui/table"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"

const formSchema = z.object({
  slug: z.string().min(1, { message: "Required" }),
  description: z.string(),
  active: z.boolean(),
  conditions: z.any(),
  matchEveryone: z.boolean(),
  minimumTierLevel: z.string().min(1, { message: "Required" }),
  maximumTierLevel: z.string().min(1, { message: "Required" }),
})

export type GroupFormValues = z.infer<typeof formSchema>

interface GroupFormProps {
  initialValues?: GroupFormValues
  onSubmit: (
    data: GroupCreateInput
  ) => Promise<
    FetchResult<GroupCreateMutation> | FetchResult<GroupUpdateMutation>
  >
}

export const GroupForm = ({ initialValues, onSubmit }: GroupFormProps) => {
  const { tiers } = useTiers()
  const form = useForm<GroupFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues || {
      slug: "",
      description: "",
      active: true,
      conditions: null,
      matchEveryone: false,
      minimumTierLevel: TierLevelEnum.Free,
      maximumTierLevel: tiers.some((t) => t.level === TierLevelEnum.Pro)
        ? TierLevelEnum.Pro
        : TierLevelEnum.Plus,
    },
  })
  const [isSaving, setIsSaving] = useState(false)
  const values = useWatch({ control: form.control })

  const handleSubmit = async ({
    conditions,
    matchEveryone,
    minimumTierLevel,
    maximumTierLevel,
    ...data
  }: GroupFormValues) => {
    setIsSaving(true)
    try {
      const { errors } = await onSubmit({
        ...data,
        groupType: matchEveryone
          ? GroupTypeEnum.Everyone
          : GroupTypeEnum.Dynamic,
        rulesEngineConditions: matchEveryone ? null : conditions,
        minimumTierLevel: minimumTierLevel as TierLevelEnum,
        maximumTierLevel: maximumTierLevel as TierLevelEnum,
      })

      if (
        errors &&
        (errors as unknown as ApolloError).graphQLErrors.find(
          (e) => e.extensions.code === "NOT_UNIQUE_ERROR"
        )
      ) {
        form.setError("slug", {
          type: "manual",
          message: (errors as unknown as ApolloError).graphQLErrors[0].message,
        })
      }
    } catch (e) {
      console.error(e)
      toast.error("Failed to save group")
    }
    setIsSaving(false)
  }

  const usersTableQueryOptions = useMemo(() => {
    const options: Record<string, any> = {
      activeOnly: true,
      fitOnly: true,
      minimumTierLevel: values.minimumTierLevel,
      maximumTierLevel: values.maximumTierLevel,
    }

    if (!values.matchEveryone) {
      options.rulesEngineConditions = values.conditions
    }
    return options
  }, [
    values.conditions,
    values.matchEveryone,
    values.minimumTierLevel,
    values.maximumTierLevel,
  ])

  const tierSelectEntries = useMemo(() => {
    return tiers.map((tier) => ({
      label: tier.name,
      value: tier.level,
    }))
  }, [tiers])

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="grid md:grid-cols-2 gap-4">
              <TextField
                control={form.control}
                name="slug"
                label="Group Handle"
                required={true}
                startAdornment={
                  <IconInputAdornment>
                    <AtSign className="w-4 h-4" />
                  </IconInputAdornment>
                }
              />
              <TextField
                control={form.control}
                name="description"
                label="Group Purpose"
              />
            </div>
            <Card>
              <CardContent className="p-4 flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="matchEveryone"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">
                          Match Everyone?
                        </FormLabel>
                        <FormDescription>
                          If enabled, every community member that match the
                          selected tiers will automatically be included in the
                          group.
                        </FormDescription>
                      </div>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                {!form.watch("matchEveryone") && (
                  <Card>
                    <CardContent className="p-4">
                      <RulesEngineConditionsBuilder
                        onChange={(conditions) =>
                          form.setValue("conditions", conditions, {
                            shouldDirty: true,
                          })
                        }
                        initialConditions={form.watch("conditions")}
                        excludeFacts={["user.tier.level"]}
                      />
                    </CardContent>
                  </Card>
                )}
                <div className="grid md:grid-cols-2 gap-4 rounded-lg border p-4">
                  <SelectField
                    control={form.control}
                    name="minimumTierLevel"
                    label="Minimum Tier Level"
                    selectEntries={tierSelectEntries}
                  />
                  <SelectField
                    control={form.control}
                    name="maximumTierLevel"
                    label="Maximum Tier Level"
                    selectEntries={tierSelectEntries}
                  />
                  <div className="col-span-2">
                    <FormDescription>
                      Users must be at least the minimum tier level to be
                      included in this group. Users with a lower tier who would
                      otherwise meet the criteria to join the group will be
                      prompted to upgrade when they attempt to perform an action
                      that would require them to be in the group.
                    </FormDescription>
                  </div>
                </div>
              </CardContent>
            </Card>
            <div>
              <UsersTable
                withSearch
                defaultSort={UserSortEnum.Name}
                headers={HEADERS}
                queryOptions={usersTableQueryOptions}
                withSort={[UserSortEnum.Name, UserSortEnum.NameReverse]}
              >
                {(user) => <UserRow key={user.id} user={user} />}
              </UsersTable>
            </div>
          </div>

          <AdminActionToolbar>
            <Switch
              variant="selector"
              size="lg"
              options={["Inactive", "Active"]}
              checked={form.watch("active")}
              onCheckedChange={(value) =>
                form.setValue("active", !!value, { shouldDirty: true })
              }
            />
            <Button
              type="submit"
              isLoading={form.formState.isSubmitting}
              disabled={isSaving || !form.formState.isDirty}
            >
              Save Group
            </Button>
          </AdminActionToolbar>
        </form>
      </Form>
    </>
  )
}

const HEADERS: AdminTableHeader[] = [
  { label: "Name", isPinned: true },
  { label: "Homebase" },
  { label: "Subscription" },
  { label: "Tier" },
  { label: "Expertise" },
  { label: "Interests" },
  { label: "Type" },
  { label: "Hidden" },
  { label: "Actions" },
]

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

const UserRow = ({ user }: { user: User_AdminFragment }) => {
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  return (
    <>
      {userDialogOpen && (
        <UserDialog
          isOpen={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={user}
        />
      )}

      <ContextMenu>
        <ContextMenuContent>
          <ContextMenuItem
            onClick={() =>
              user.email && copy(user.email) && toast.success("Email copied")
            }
            className="cursor-pointer"
            disabled={!user.email}
          >
            Copy Email Address
          </ContextMenuItem>
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <TableRow ref={contextMenuTriggerRef} className="group">
            <TableCell className={pinnedColumnStyles}>
              <div className="flex gap-2">
                <div className="w-[30px] h-[30px]">
                  <AvatarWithFallback user={user} size="post-autocomplete" />
                </div>
                <div className="flex flex-col gap-1 items-start">
                  <div className="flex gap-2 items-center">
                    <Button
                      variant="link"
                      size="inline"
                      className="text-primary"
                      onClick={() => setUserDialogOpen(true)}
                    >
                      <UserName user={user} />
                    </Button>
                    {user.hidden && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <EyeNoneIcon className="text-pretext-grey" />
                          </TooltipTrigger>
                          <TooltipContent>
                            User is hidden from the directory
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                  {user.email && (
                    <CopyToClipboard
                      className="text-2xs text-gray-500"
                      text={user.email}
                    >
                      <div className="truncate max-w-[140px]">{user.email}</div>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                {user.place?.full || "N/A"}
              </div>
            </TableCell>
            <TableCell>
              <span className="text-xs">
                {user.activeStripeSubscription?.status || "N/A"}
              </span>
            </TableCell>
            <TableCell>
              <span className="text-xs">
                {user.tier ? (
                  <>
                    {user.tier.name}
                    {user.tier.level !== TierLevelEnum.Free &&
                      user.activeStripeSubscription?.plan &&
                      `-${
                        user.activeStripeSubscription.plan.interval === "year"
                          ? "Yearly"
                          : "Quarterly"
                      }`}
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.expertise.map((tag) => (
                  <Badge key={tag.id} variant="secondary" className="text-3xs">
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.interests.map((tag) => (
                  <Badge key={tag.id} variant="highlight" className="text-3xs">
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              {user.admin ? (
                <Badge variant="primary">Admin</Badge>
              ) : user.coach ? (
                <Badge variant="success">Coach</Badge>
              ) : (
                <Badge variant="default">User</Badge>
              )}
            </TableCell>
            <TableCell>
              <Badge variant={user.hidden ? "warning" : "success"}>
                {user.hidden ? "Hidden" : "Visible"}
              </Badge>
            </TableCell>
            <TableCell>
              <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
                <DotsHorizontalIcon />
              </Button>
            </TableCell>
          </TableRow>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}
