import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { Channel } from "~/__generated__/graphql"
import { PostCard } from "~/feed/PostCard"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"
import { FeedSidebar } from "~/feed/FeedSidebar"
import { articlePath, channelPath, feedPath, postPath } from "~/common/paths"
import ArrowLeft from "~/images/icons/arrow-left.svg?react"
import { ComposerHandle, PostComposer } from "~/post-composer/PostComposer"
import { useCreateReply } from "~/post-composer/useCreateReply"
import { useCallback, useRef } from "react"
import { ComposerContextProvider } from "~/post-composer/ComposerContext"
import { REPLIES_QUERY_DOCUMENT, RepliesFeed } from "~/posts/RepliesFeed"

export const PostScreen = () => {
  const { postId } = useParams()
  invariant(postId)

  const [searchParams] = useSearchParams()
  const autoFocusReply = !!searchParams.get("reply")

  const composerRef = useRef<ComposerHandle>(null)
  const repliesResult = useQuery(REPLIES_QUERY_DOCUMENT, {
    variables: { parentPostId: postId },
    notifyOnNetworkStatusChange: true,
  })
  const { data, loading, error } = useQuery(POST_QUERY_DOCUMENT, {
    variables: { postId },
  })
  const afterCreateReply = useCallback(() => {
    repliesResult.refetch()
  }, [repliesResult])
  const { createReply, replayIsSaving } = useCreateReply({
    parentPostId: postId,
    onSuccess: afterCreateReply,
  })

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading post." />

  const post = data.post

  return (
    <ComposerContextProvider composerRef={composerRef}>
      <PageWithRightSidebar sidebar={<FeedSidebar />}>
        <div className="flex flex-col gap-4">
          <PostCard
            post={post}
            header={
              <BackHeader
                parentPostId={post.parentPost?.id}
                articleId={post.article?.id}
                articleTitle={post.article?.approvedRevision?.title}
                channel={post.channel}
              />
            }
            isReply={!!post.isReply}
            replyDisabled
            onSinglePostPage
            trackImpressions
          />

          {!post.isReply && (
            <>
              <PostComposer
                onSave={createReply}
                isSaving={replayIsSaving}
                mentionsInputPlaceholder={"Reply to Post"}
                isReply
                ref={composerRef}
                autoFocus={autoFocusReply}
              />

              <RepliesFeed repliesResult={repliesResult} />
            </>
          )}
        </div>
      </PageWithRightSidebar>
    </ComposerContextProvider>
  )
}

type BackHeaderChannel = Pick<Channel, "slug" | "name">

const BackHeader = ({
  parentPostId,
  articleId,
  articleTitle,
  channel,
}: {
  parentPostId?: null | string
  articleId?: null | string
  articleTitle?: null | string
  channel?: BackHeaderChannel | null
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { state: locationState } = useLocation()

  return (
    <Link
      to={
        parentPostId
          ? postPath({ postId: parentPostId })
          : articleId
          ? articlePath({ articleId: articleId })
          : searchParams.get("from") === "channel" && channel
          ? channelPath({ channelSlug: channel.slug })
          : feedPath.pattern
      }
      onClick={() => {
        if (locationState?.useBrowserBack) {
          navigate(-1)
        }
      }}
      className="flex items-center border-b border-mercury px-4 py-3 gap-4 text-sm font-semibold tracking-wide"
    >
      <ArrowLeft />
      Back to{" "}
      {parentPostId
        ? "Post"
        : articleTitle
        ? articleTitle
        : articleId
        ? "Content"
        : searchParams.get("from") === "channel" && channel
        ? channel.name
        : "Feed"}
    </Link>
  )
}

export const POST_QUERY_DOCUMENT = gql(`
  query Post($postId: ID!) {
    post(postId: $postId) {
      ...Post_Card
    }
  }
`)
