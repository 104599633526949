import { useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { CommunitySlug } from "~/__generated__/graphql"
import { commaSeparatedList } from "~/common/commaSeparatedList"
import { formatDate } from "~/common/formatDate"
import { eventsPath } from "~/common/paths"
import { useCommunityClassname } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { CALENDAR_EVENTS_QUERY_DOCUMENT } from "~/screens/EventsScreen"
import { buttonVariants } from "~/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"

const LIMIT_EVENTS = 2

type UpcomingEventsModuleProps = {
  title?: string
}

const UpcomingEventsSkeleton = () => (
  <div className="flex flex-col gap-2 py-4">
    <div className="w-[100px] h-3 bg-gray-100 animate-pulse"></div>
    <div className="w-[200px] h-4 bg-gray-100 animate-pulse"></div>
    <div className="flex gap-2 items-center">
      <div className="flex -space-x-3">
        <div className="w-5 h-5 bg-gray-100 animate-pulse rounded-full"></div>
      </div>
      <div className="w-[100px] h-3 bg-gray-100 animate-pulse"></div>
    </div>
  </div>
)

export const UpcomingEventsModule = ({ title }: UpcomingEventsModuleProps) => {
  const ccls = useCommunityClassname()
  const { data, loading } = useQuery(CALENDAR_EVENTS_QUERY_DOCUMENT, {
    variables: { first: LIMIT_EVENTS, upcoming: true },
  })
  const totalItems = useMemo(
    () => data?.calendarEvents?.totalCount ?? 0,
    [data]
  )
  const events = useMemo(
    () => data?.calendarEvents?.edges?.map((edge) => edge.node) ?? [],
    [data]
  )

  return !loading && events.length === 0 ? null : (
    <Card
      className={cn(
        ccls({
          [CommunitySlug.Gotomillions]: "bg-secondary-background",
          default: "",
        })
      )}
    >
      <CardHeader>
        <CardTitle>{title || "Upcoming Events"}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col divide-y">
        {loading &&
          Array.from({ length: LIMIT_EVENTS }).map((_, index) => (
            <UpcomingEventsSkeleton key={index} />
          ))}
        {!loading &&
          events.map((event) => (
            <div key={event.id} className="flex flex-col gap-2 py-4">
              <div className="text-2xs text-pretext-gray">
                {formatDate(event.startAt, "MMM d, yyyy 'at' h:mm a")}
              </div>
              <div className="text-sm">
                <a
                  href={event.url!}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  {event.name}
                </a>
              </div>
              {event.hosts!.length > 0 && (
                <div className="flex gap-2 items-center">
                  <div>
                    <div className="flex -space-x-3">
                      {event.hosts!.slice(0, 2).map((host, index) => (
                        <img
                          key={index}
                          src={host.avatarUrl}
                          alt={host.name}
                          className="w-5 h-5 rounded-full"
                        />
                      ))}
                    </div>
                  </div>
                  <div className="text-2xs text-pretext-gray whitespace-nowrap text-ellipsis overflow-hidden">
                    By{" "}
                    {commaSeparatedList(event.hosts!.map((host) => host.name))}
                  </div>
                </div>
              )}
              <div className="mt-2">
                <a
                  href={event.url!}
                  target="_blank"
                  rel="noreferrer"
                  className={cn(
                    buttonVariants({
                      theme: "current",
                      variant: "filled",
                      size: "sm",
                    }),
                    ccls({
                      [CommunitySlug.Gotomillions]:
                        "bg-primary [&>*]:text-white",
                      default: "text-primary",
                    }),
                    "w-auto"
                  )}
                >
                  <span>Register</span>
                </a>
              </div>
            </div>
          ))}
        {!loading && totalItems > LIMIT_EVENTS && (
          <div className="pt-4">
            <Link
              to={eventsPath.pattern}
              className={cn(
                "text-sm text-primary",
                ccls({
                  [CommunitySlug.Gotomillions]: "text-highlight",
                  default: "",
                })
              )}
            >
              See More Events
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
