import { gql } from "~/__generated__"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { Button } from "~/ui/button"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { useQuery } from "@apollo/client"
import { Error } from "~/ui/Error"
import { Skeleton } from "~/ui/skeleton"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { Card } from "~/ui/card"
import { LeadsSearch } from "~/admin/leads/LeadsSearch"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { useState, useMemo, useEffect, useRef } from "react"
import { InviteMemberModal } from "./InviteMemberModal"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import { generateInviteUrl } from "~/common/generateInviteUrl"
import copy from "copy-to-clipboard"
import toast from "react-hot-toast"
import { Badge } from "~/ui/badge"

const InviteeRow = ({ lead }: { lead: any }) => {
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  return (
    <ContextMenu>
      <ContextMenuContent>
        <ContextMenuItem
          onClick={() =>
            lead.email && copy(lead.email) && toast.success("Email copied")
          }
          className="cursor-pointer"
          disabled={!lead.email}
        >
          Copy Email Address
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            const url = generateInviteUrl(
              lead.invitationCode || "",
              lead.variationParam || ""
            )
            copy(url)
            toast.success("Invite URL copied")
          }}
          className="cursor-pointer"
          disabled={!lead.invitationCode}
        >
          Copy Invite URL
        </ContextMenuItem>
      </ContextMenuContent>
      <ContextMenuTrigger asChild>
        <TableRow ref={contextMenuTriggerRef} className="group">
          <TableCell>
            <div>{lead.name || "-"}</div>
            <CopyToClipboard
              className="text-2xs text-gray-500"
              text={lead.email}
            >
              <div className="truncate max-w-[140px]">{lead.email}</div>
            </CopyToClipboard>
          </TableCell>
          <TableCell>
            <div>
              {lead.referrer?.name || "-"}
              {lead.referrer?.name && (
                <div className="text-2xs text-gray-500">
                  {new Date(lead.createdAt).toLocaleDateString()}
                </div>
              )}
            </div>
          </TableCell>
          <TableCell>
            <div>
              <Badge
                variant={
                  lead.invitationCodeRedeemed ? "success" : "destructive"
                }
              >
                {lead.invitationCodeRedeemed ? "Yes" : "No"}
              </Badge>
              {lead.invitationCodeRedeemed && lead.user?.createdAt && (
                <div className="text-2xs text-gray-500">
                  {new Date(lead.user.createdAt).toLocaleDateString()}
                </div>
              )}
            </div>
          </TableCell>
          <TableCell>
            <CopyToClipboard
              text={generateInviteUrl(
                lead.invitationCode || "",
                lead.variationParam || ""
              )}
              className="text-2xs text-gray-500"
            >
              <div className="truncate max-w-[200px]">
                {generateInviteUrl(
                  lead.invitationCode || "",
                  lead.variationParam || ""
                )}
              </div>
            </CopyToClipboard>
          </TableCell>
          <TableCell>
            <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
              <DotsHorizontalIcon />
            </Button>
          </TableCell>
        </TableRow>
      </ContextMenuTrigger>
    </ContextMenu>
  )
}

const LEADS_QUERY = gql(`
  query MemberInvitations($first: Int, $after: String, $searchTerm: String, $sort: UserSortEnum, $source: String, $variationParam: String) {
    leads(first: $first, after: $after, searchTerm: $searchTerm, sort: $sort, source: $source, variationParam: $variationParam) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          email
          source
          variationParam
          invitationCode
          invitationCodeRedeemed
          createdAt
          user {
            createdAt
          }
          referrer {
            name
            email
          }
        }
      }
    }
  }
`)

const PAGE_SIZE = 10

export const InvitationsTable = ({
  refetchState,
}: {
  refetchState: boolean
}) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get("q") || ""
  )
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400)

  const headers = [
    { label: "Name", isPinned: true },
    { label: "Invited By" },
    { label: "Activated" },
    { label: "Invite URL" },
    { label: "Actions" },
  ]

  const queryOptions = useMemo(() => {
    const options: any = { first: PAGE_SIZE, source: "admin_invited" }
    if (debouncedSearchTerm) options.searchTerm = debouncedSearchTerm
    return {
      variables: options,
    }
  }, [debouncedSearchTerm])

  const {
    data: currentData,
    previousData,
    loading,
    error,
    fetchMore,
    refetch,
  } = useQuery(LEADS_QUERY, {
    ...queryOptions,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  })

  const data = currentData || previousData

  const leads = useMemo(() => {
    return data?.leads.edges.map((e) => e.node) || []
  }, [data])

  const hasNextPage = !!data?.leads.pageInfo.hasNextPage
  const totalCount = data?.leads.totalCount || 0

  const memoizedSearchParams = useMemo(() => {
    const params = new URLSearchParams(searchParams.toString())
    if (debouncedSearchTerm !== params.get("q")) {
      if (debouncedSearchTerm === "") {
        params.delete("q")
      } else {
        params.set("q", debouncedSearchTerm)
      }
    }
    return params
  }, [debouncedSearchTerm, searchParams])

  useEffect(() => {
    setSearchParams(memoizedSearchParams)
  }, [memoizedSearchParams, setSearchParams])

  useEffect(() => {
    refetch()
  }, [refetchState])

  return (
    <>
      <div className="flex justify-between items-center gap-4 mb-4">
        <div className="flex-grow">
          <LeadsSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
      </div>

      <InviteMemberModal
        isOpen={isInviteModalOpen}
        setIsOpen={setIsInviteModalOpen}
        onSuccess={() => {
          refetch()
        }}
      />

      {error ? (
        <Error message="Error loading leads." />
      ) : (
        <>
          {leads.length > 0 && (
            <div className="text-sm text-gray-500 mb-4">
              {totalCount} invitees found
            </div>
          )}

          <Card>
            <Table className="rounded-tl-2xl">
              <TableHeader>
                <TableRow className="group rounded-tl-2xl">
                  {headers.map((header) => (
                    <TableHead
                      key={header.label}
                      className={
                        header.isPinned
                          ? "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent rounded-tl-2xl"
                          : ""
                      }
                    >
                      {header.label}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {leads.map((lead) => (
                  <InviteeRow key={lead.id} lead={lead} />
                ))}
                {loading &&
                  Array.from({ length: 10 }).map((_, index) => (
                    <TableRow key={index}>
                      {headers.map((header) => (
                        <TableCell key={header.label} className="h-[52px]">
                          <Skeleton className="w-full h-[16px]" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Card>
        </>
      )}

      <div className="p-16 w-full flex items-center justify-center">
        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: {
                after: data?.leads.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return {
                  leads: {
                    ...fetchMoreResult.leads,
                    edges: [
                      ...prev.leads.edges,
                      ...fetchMoreResult.leads.edges,
                    ],
                  },
                }
              },
            })
          }
          canLoadMore={!loading && hasNextPage}
          loadingText="Loading more leads..."
          loading={loading && leads.length > 0 && hasNextPage}
        />
      </div>
    </>
  )
}
