import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { useEffect, useRef } from "react"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { PostComposer } from "~/post-composer/PostComposer"
import { MessagesSectionInner } from "./MessagesSectionInner"
import { useCreateMessage } from "./useCreateMessage"
import { RefObject } from "react"
import { Error } from "~/ui/Error"
import { MessagesPaneHeader } from "./MessagesPaneHeader"
import { useSearchParams } from "react-router-dom"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/common/GlobalsProvider"
import SmsNotification from "../images/icons/sms-notification.svg?react"
import { Button } from "~/ui/button"
import { Lock, Menu } from "lucide-react"
import { useCommunity } from "~/community/useCommunity"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useMessagesContext } from "~/screens/MessagesScreen"

export const MessagesPane = ({
  roomId,
  dmOtherUserId,
  scrollviewRef,
}: {
  roomId?: string
  dmOtherUserId?: string
  scrollviewRef: RefObject<HTMLDivElement>
}) => {
  const { openSubscriptionWizard } = useSubscription()
  const roomResult = useQuery(ROOM_QUERY_DOCUMENT, {
    variables: {
      dmOtherUserId,
      roomId,
    },
  })
  const { setMobileConversationsOpen, initiateDirectMessage } =
    useMessagesContext()
  const createMessageBag = useCreateMessage({
    roomId: roomResult.data?.room.id,
    otherUserId: dmOtherUserId,
    onSuccess: () => {},
  })

  const { logEvent } = useLogEvent()
  const [searchParams, setSearchParams] = useSearchParams()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    if (searchParams.get("ref") === "introduction_mail") {
      logEvent(
        AhoyEventTypeEnum.IntroductionDmInitiated,
        { introduction_partner_id: dmOtherUserId },
        hasLoggedEvent
      )
      searchParams.set("ref", "")
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams, logEvent, dmOtherUserId])

  useEffect(() => {
    if (
      roomResult.error?.graphQLErrors.some(
        (e) => e.extensions?.rule === "initiate_dms?"
      )
    ) {
      openSubscriptionWizard("PricingTableStep", {
        source: "InitiateDirectMessage",
        requiredFeature: "canInitiateDms",
      })
    }
  }, [roomResult.error, openSubscriptionWizard])

  if (roomResult.loading) {
    return null
  }

  if (roomResult.error) {
    if (
      roomResult.error.graphQLErrors.some(
        (e) => e.extensions?.rule === "initiate_dms?"
      )
    ) {
      return (
        <NoMessages
          setMobileConversationsOpen={setMobileConversationsOpen}
          initiateDirectMessage={initiateDirectMessage}
        />
      )
    } else {
      return <Error message="Error loading room." />
    }
  }

  if (!roomResult.data) {
    return null
  }

  return (
    <>
      <MessagesPaneHeader
        roomResult={roomResult}
        setMobileConversationsOpen={setMobileConversationsOpen}
      />
      <div
        className="flex-1-1-auto h-[0px] overflow-y-scroll flex flex-col-reverse pb-4"
        ref={scrollviewRef}
      >
        <MessagesSectionInner roomData={roomResult.data} />
      </div>
      <div className="p-4 border-t border-mercury">
        <PostComposer
          onSave={createMessageBag.createReply}
          isSaving={createMessageBag.replayIsSaving}
          buttonCopy="Send"
          mentionsDisabled
          actionsDivider
        />
      </div>
    </>
  )
}

const NoMessages = ({
  setMobileConversationsOpen,
  initiateDirectMessage,
}: {
  setMobileConversationsOpen: (open: boolean) => void
  initiateDirectMessage: () => void
}) => {
  const community = useCommunity()
  const currentUser = useCurrentUser()

  return (
    <div className="flex flex-col items-center pt-12 relative">
      <div
        onClick={() => setMobileConversationsOpen(true)}
        className="mr-4 xl:hidden cursor-pointer top-6 left-6 absolute"
      >
        <Menu />
      </div>

      <div className="text-primary rounded-full border-2 border-primary flex items-center justify-center p-4 mb-4">
        <SmsNotification />
      </div>
      <div className="text-lg font-semibold mb-2">Your Messages</div>
      <div className="text-sm mb-6 tracking-wide">
        Start a private conversation with a {community.name} member!
      </div>
      <Button
        onClick={initiateDirectMessage}
        type="button"
        className="flex gap-2 items-center"
      >
        Compose Message
        {currentUser.permissions.canInitiateDms ? null : (
          <Lock className="w-4 h-4" />
        )}
      </Button>
    </div>
  )
}

const ROOM_QUERY_DOCUMENT = gql(`
  query RoomQuery($roomId: ID, $dmOtherUserId: ID) {
    room(roomId: $roomId, dmOtherUserId: $dmOtherUserId) {
      id
      hasUnreadPosts
      name
      defaultName
      currentUserCanUpdate {
        value
      }
      users {
        ...User_Avatar
        name
      }
      dmOtherUser {
        ...User_Avatar
        name
      }
    }
  }
`)
