import { Status } from "~/benchmarks/Status"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { Filters } from "~/benchmarks/Filters"
import { CardFeed } from "~/common/CardFeed"
import { ChartFeed } from "~/benchmarks/Charts"
import { BenchmarksQuery } from "~/__generated__/graphql"

export const SmallScreenCharts = ({
  manualData = false,
  data,
}: {
  manualData: boolean
  data: BenchmarksQuery
}) => {
  const currentUser = useCurrentUser()

  return (
    <div className="container mx-auto">
      <CardFeed>
        <div className="flex flex-col p-4 w-3/4">
          <h2 className="mb-2 font-semibold text-primary text-lg tracking-wide">
            Details
          </h2>
          <Status user={currentUser} />
        </div>
        <Filters />
        <ChartFeed manualData={manualData} data={data} />
      </CardFeed>
    </div>
  )
}
