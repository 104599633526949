import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { UPDATE_ARTICLE_MUTATION } from "~/content/ArticleEditor"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { useCallback, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "~/ui/form"
import { UploadDropzone } from "~/ui/UploadDropzone"
import { z } from "zod"
import { Article_CardFragment } from "~/__generated__/graphql"
import { Switch } from "~/ui/switch"
import { cn } from "~/lib/utils"
import { ArticleCard } from "~/ui/ArticleCard"

const formSchema = z.object({
  portraitImageId: z.string().nullable().optional(),
  landscapeImageId: z.string().nullable().optional(),
  usePortraitForBothOrientations: z.boolean().optional().default(true),
})

export type FormValues = z.infer<typeof formSchema>

export const ImageCoverModal = ({
  isOpen,
  setIsOpen,
  portraitImageUrl: initialPortraitImageUrl,
  portraitImageId: initialPortraitImageId,
  landscapeImageUrl: initialLandscapeImageUrl,
  landscapeImageId: initialLandscapeImageId,
  article,
  onSubmit,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  portraitImageUrl?: string | null
  portraitImageId?: string | null
  landscapeImageUrl?: string | null
  landscapeImageId?: string | null
  article: Article_CardFragment
  onSubmit?: () => void
}) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      portraitImageId: initialPortraitImageId,
      landscapeImageId: initialLandscapeImageId,
      usePortraitForBothOrientations: !initialLandscapeImageId,
    },
  })
  const [mode, setMode] = useState<"edit" | "preview">("edit")
  const [portraitImageUrl, setPortraitImageUrl] = useState<string | null>(
    initialPortraitImageUrl || null
  )
  const [landscapeImageUrl, setLandscapeImageUrl] = useState<string | null>(
    initialLandscapeImageUrl || null
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [runArticleUpdate, { loading }] = useSafeMutation(
    UPDATE_ARTICLE_MUTATION
  )

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      setIsSubmitting(true)
      const { errors } = await runArticleUpdate({
        variables: {
          input: {
            articleId: article.id,
            portraitImageId: values.portraitImageId,
            landscapeImageId: values.usePortraitForBothOrientations
              ? values.portraitImageId
              : values.landscapeImageId,
            removePortraitImage: !values.portraitImageId,
            removeLandscapeImage:
              !values.landscapeImageId &&
              !values.usePortraitForBothOrientations,
          },
        },
      })

      if (errors) {
        toast.error("Error updating image.")
      } else {
        setIsOpen(false)
        onSubmit?.()
      }
      setIsSubmitting(false)
    },
    [runArticleUpdate, article, onSubmit, setIsOpen]
  )

  const values = useWatch({ control: form.control })

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="sm:w-fit max-w-none w-full">
        <DialogHeader>
          <DialogTitle>Edit Article Cover Image</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="w-full md:min-w-[800px] flex flex-col gap-4">
              {mode === "edit" && (
                <>
                  <div
                    className={cn(
                      values.usePortraitForBothOrientations
                        ? "grid grid-cols-1 gap-4"
                        : "md:grid md:grid-cols-2 md:gap-4"
                    )}
                  >
                    <div className="flex flex-col gap-4">
                      <UploadDropzone
                        control={form.control}
                        name="portraitImageId"
                        initialUrl={initialPortraitImageUrl || undefined}
                        title={
                          <div className="whitespace-nowrap">
                            Portrait Orientation
                          </div>
                        }
                        body={<></>}
                        onChange={setPortraitImageUrl}
                        aspectRatio={0.713}
                      />
                    </div>

                    {!values.usePortraitForBothOrientations && (
                      <UploadDropzone
                        control={form.control}
                        name="landscapeImageId"
                        initialUrl={initialLandscapeImageUrl || undefined}
                        title={
                          <div className="whitespace-nowrap">
                            Landscape Orientation
                          </div>
                        }
                        body={<></>}
                        onChange={setLandscapeImageUrl}
                        aspectRatio={2.125}
                      />
                    )}

                    <FormField
                      control={form.control}
                      name="usePortraitForBothOrientations"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 col-span-2">
                          <div className="space-y-0.5">
                            <FormLabel className="text-base">
                              Use portrait image for both orientations?
                            </FormLabel>
                            <FormDescription>
                              If enabled, the portrait image will be used for
                              both portrait and landscape orientations. The
                              image will be cropped to fit the landscape
                              orientation.
                            </FormDescription>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </>
              )}

              {mode === "preview" && (
                <div className="flex flex-col md:flex-row gap-4 items-start justify-center">
                  <ArticleCard
                    variant="hero"
                    clickable={false}
                    article={{
                      ...article,
                      revision: {
                        ...article.revision!,
                        portraitImageUrl: portraitImageUrl,
                      },
                    }}
                  />
                  <ArticleCard
                    variant="default"
                    clickable={false}
                    article={{
                      ...article,
                      revision: {
                        ...article.revision!,
                        landscapeImageUrl: values.usePortraitForBothOrientations
                          ? portraitImageUrl
                          : landscapeImageUrl,
                      },
                    }}
                  />
                </div>
              )}

              <div className="flex gap-4 items-center justify-end">
                <div>
                  <Switch
                    variant="selector"
                    checked={mode === "preview"}
                    onCheckedChange={(checked) =>
                      setMode(checked ? "preview" : "edit")
                    }
                    options={["Edit", "Preview"]}
                  />
                </div>
                <Button
                  type="submit"
                  disabled={loading || isSubmitting || !form.formState.isDirty}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
