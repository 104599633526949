import { useCurrentUser } from "~/common/GlobalsProvider"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import MessageCompose from "~/images/icons/message-compose.svg?react"
import { MessageSectionHeader } from "./MessageSectionHeader"
import {
  CommunitySlug,
  Room_MessageListFragment,
} from "~/__generated__/graphql"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { postDateDisplay } from "~/feed/postDateDisplay"
import { cn } from "~/lib/utils"
import { Link, useParams } from "react-router-dom"
import { dmPath, roomPath } from "~/common/paths"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { ArrowLeft, Lock } from "lucide-react"
import { StyledDropdown, StyledDropdownItem } from "~/ui/StyledDropdown"
import { roomDisplayName } from "./utils"
import { useMessagesContext } from "~/screens/MessagesScreen"
import { SearchInput } from "~/ui/SearchInput"
import { Translation } from "~/common/Translation"
import { Muted } from "~/ui/typography"
import { useCommunityClassname } from "~/community/useCommunity"

export const RoomsPane = () => {
  const ccls = useCommunityClassname()
  const currentUser = useCurrentUser()
  const {
    searchQuery,
    setSearchQuery,
    roomsResult,
    orderedRooms,
    setMobileConversationsOpen,
    initiateDirectMessage,
    initiateNewRoom,
  } = useMessagesContext()
  const totalCount = roomsResult?.data?.myRooms.totalCount

  return (
    <div className="flex-1-1-auto h-[0px] overflow-y-scroll relative">
      <MessageSectionHeader className="sticky top-0 z-30">
        <AvatarWithFallback
          user={currentUser}
          size="header"
          className="mr-4 hidden xl:block"
        />
        <div
          className="xl:hidden mr-4 cursor-pointer"
          onClick={() => setMobileConversationsOpen(false)}
        >
          <ArrowLeft />
        </div>
        <div className="flex-1 text-xs font-semibold">{currentUser.name}</div>

        <StyledDropdown trigger={<MessageCompose />}>
          <StyledDropdownItem
            title="Send a DM"
            onClick={initiateDirectMessage}
            icon={
              currentUser.permissions.canInitiateDms ? undefined : (
                <Lock className="w-4 h-4" />
              )
            }
          />
          <StyledDropdownItem
            title="Create a group chat"
            onClick={initiateNewRoom}
            icon={
              currentUser.permissions.canInitiateDms ? undefined : (
                <Lock className="w-4 h-4" />
              )
            }
          />
        </StyledDropdown>
      </MessageSectionHeader>

      <div className="flex flex-col gap-2 p-4">
        <SearchInput
          searchTerm={searchQuery}
          setSearchTerm={setSearchQuery}
          className={cn(
            ccls({
              [CommunitySlug.Gotomillions]: "border-primary",
              default: "",
            })
          )}
        />
        {searchQuery && totalCount !== undefined && (
          <div className="text-2xs">
            <Muted>
              <Translation
                ns="messages"
                i18nKey={totalCount > 0 ? "matchedCount" : "matchedCount_zero"}
                values={{ count: totalCount }}
              />
            </Muted>
          </div>
        )}
      </div>

      <div>
        {roomsResult && (
          <>
            {roomsResult.error ? (
              <Error message="Error loading conversations." />
            ) : roomsResult.loading && orderedRooms?.length === 0 ? (
              <LoadingIndicator />
            ) : roomsResult.data ? (
              <div className="flex flex-col">
                {orderedRooms && orderedRooms.length > 0 && (
                  <>
                    {orderedRooms.map((room) => (
                      <RoomEntry
                        room={room}
                        key={room.id}
                        setMobileConversationsOpen={setMobileConversationsOpen}
                      />
                    ))}
                    <InfiniteLoadMore
                      onEndReached={() =>
                        roomsResult.fetchMore({
                          variables: {
                            roomsCursor:
                              roomsResult.data?.myRooms.pageInfo.endCursor,
                          },
                        })
                      }
                      canLoadMore={
                        !roomsResult.loading &&
                        roomsResult.data.myRooms.pageInfo.hasNextPage
                      }
                      loadingText="Loading posts..."
                      loading={roomsResult.loading && orderedRooms.length > 0}
                    />
                  </>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

const RoomEntry = ({
  room,
  setMobileConversationsOpen,
}: {
  room: Room_MessageListFragment

  setMobileConversationsOpen: (open: boolean) => void
}) => {
  const currentUser = useCurrentUser()
  const otherUser = room.dmOtherUser
  const latestPost = room.latestPost

  const { otherUserId, roomId } = useParams()
  const isSelected =
    (otherUserId && otherUserId === otherUser?.id) ||
    (roomId && room.id === roomId)

  const path = otherUser?.id
    ? dmPath({ otherUserId: otherUser.id })
    : roomPath({ roomId: room.id })

  const ccls = useCommunityClassname()

  return (
    <Link
      to={path}
      onClick={() => {
        setMobileConversationsOpen(false)
      }}
      className={cn("flex items-center gap-2 tracking-wide rounded-lg p-4", {
        "bg-black/5": isSelected,
        "bg-card-highlight-background": room.hasUnreadPosts,
      })}
    >
      <AvatarWithFallback
        size="header"
        user={otherUser || undefined}
        textOverride={otherUser ? undefined : room.memberCount.toString()}
        className="self-start"
      />
      <div className="flex flex-col gap-1 pr-2">
        <div className="font-medium text-xs">{roomDisplayName(room)}</div>
        {latestPost && (
          <>
            <div className="text-2xs">
              <span className="whitespace-nowrap">
                {latestPost.user.id === currentUser.id
                  ? "You replied"
                  : "Sent a message"}
              </span>{" "}
              &middot;{" "}
              <span className="whitespace-nowrap">
                {postDateDisplay(latestPost.createdAt)}
              </span>
            </div>
            <Muted className="line-clamp-2">{latestPost.content}</Muted>
          </>
        )}
      </div>

      <div>
        <div
          className={cn(
            "h-[12px] w-[12px] rounded-full",
            room.hasUnreadPosts ? "visible" : "invisible",
            ccls({
              [CommunitySlug.Fintechtakes]: "bg-primary",
              default: "bg-highlight",
            })
          )}
        />
      </div>
    </Link>
  )
}
