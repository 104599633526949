import { BookMarked } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { useTags } from "~/common/queries"
import { useTranslation } from "~/common/Translation"
import { useCommunity } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { Card } from "~/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"

export const AdminLibraryScreen = () => {
  const community = useCommunity()
  const tags = useTags()
  const { t: translateContentType } = useTranslation("contentTypes")

  return (
    <>
      <AdminHeader title="Library" Icon={BookMarked} />
      <div className="grid sm:grid-cols-2 gap-4">
        <div>
          <Card>
            <Table className={cn("rounded-tl-2xl")}>
              <TableHeader>
                <TableRow className="group rounded-tl-2xl">
                  <TableHead>Content Types</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {community.contentTypes.map((contentType) => (
                  <TableRow key={contentType}>
                    <TableCell>{translateContentType(contentType)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
        <div>
          <Card>
            <Table className={cn("rounded-tl-2xl")}>
              <TableHeader>
                <TableRow className="group rounded-tl-2xl">
                  <TableHead>Topics</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {tags?.map((tag) => (
                  <TableRow key={tag.id}>
                    <TableCell>{tag.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
      </div>
    </>
  )
}
