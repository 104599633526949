import { useEffect, useMemo, useState } from "react"
import { useCommunity } from "~/community/useCommunity"
import { CelebrationTypeEnum } from "~/__generated__/graphql"
import { useCreateCelebration } from "./useCreateCelebration"
import { Button } from "~/ui/button"
import { CelebrationForm } from "./CelebrationForm"
import { displayErrors } from "~/common/validations"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const AddCelebration = () => {
  const community = useCommunity()
  const currentUser = useCurrentUser()
  const [date, setDate] = useState<Date>()
  const { create, loading } = useCreateCelebration()

  const [celebrationType, setCelebrationType] = useState(
    community.celebrationTypes[0]
  )
  const [customCelebrationType, setCustomCelebrationType] = useState("")

  const currentCelebrations = useMemo(
    () => currentUser?.celebrations ?? [],
    [currentUser]
  )

  useEffect(() => {
    if (celebrationType !== CelebrationTypeEnum.Other) {
      setCustomCelebrationType("")
    }
  }, [celebrationType])

  const enabledCelebrationTypes = useMemo(() => {
    const enabled = community.celebrationTypes.filter(
      (type) =>
        !currentCelebrations.some(
          (celebration) => celebration.celebrationType === type
        )
    )

    // If enabled does not contain celebrationType, change celebrationType to enabled[0]
    if (
      enabled.length &&
      !enabled.some((type) => type === celebrationType) &&
      celebrationType !== CelebrationTypeEnum.Other
    ) {
      setCelebrationType(enabled[0])
    }

    return enabled
  }, [community, currentCelebrations, celebrationType])

  const onAdd = async () => {
    const { errors } = await create({
      date: date?.toISOString() ?? "",
      celebrationType: celebrationType,
      customCelebrationType: customCelebrationType,
    })

    if (errors) {
      displayErrors(errors)
    } else {
      setDate(undefined)
      setCelebrationType(enabledCelebrationTypes[0])
    }
  }

  return enabledCelebrationTypes.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="flex flex-row space-x-4 items-center">
        <CelebrationForm
          celebrationType={celebrationType}
          customCelebrationType={customCelebrationType}
          date={date}
          isDisabled={loading}
          availableTypes={enabledCelebrationTypes}
          onChangeCelebrationType={setCelebrationType}
          onChangeCustomCelebrationType={setCustomCelebrationType}
          onChangeDate={setDate}
        />
        <div>
          <Button type="submit" onClick={onAdd} disabled={loading || !date}>
            Add
          </Button>
        </div>
      </div>
    </>
  )
}
