import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/ui/dialog"
import { useEffect, useMemo } from "react"
import { Translation } from "~/common/Translation"
import {
  AhoyEventTypeEnum,
  CommunitySlug,
  ScreenEnum,
} from "~/__generated__/graphql"
import { camelCase } from "change-case"
import ReactPlayer from "react-player"
import { useScreenVariant } from "~/screens/ScreenVariantsProvider"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { DialogProps } from "@radix-ui/react-dialog"
import { Card, CardContent } from "~/ui/card"
import { IconButton } from "~/ui/IconButton"
import { Bookmark } from "lucide-react"
import { POST_QUERY_DOCUMENT } from "~/screens/PostScreen"
import { useLazyQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { cn } from "~/lib/utils"
import { useBookmarks } from "~/post-composer/useBookmarks"
import { Button } from "~/ui/button"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const AddToHomescreenModal = ({
  onOpenChange,
  ...dialogProps
}: DialogProps) => {
  const { logEvent } = useLogEvent()
  const community = useCommunity()
  const screenVariant = useScreenVariant(ScreenEnum.AddToHomescreenModal)
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const currentUser = useCurrentUser()

  const { videoUrl, postId } = useMemo(() => {
    return {
      videoUrl:
        screenVariant?.overrides?.options?.videoUrl ||
        community.addToHomescreenVideoUrl,
      postId:
        screenVariant?.overrides?.options?.postId ||
        community.addToHomescreenPostId,
    }
  }, [community, screenVariant])

  const ccls = useCommunityClassname()

  const [fetchPost, { data: postData, loading: postLoading }] =
    useLazyQuery(POST_QUERY_DOCUMENT)
  const post = useMemo(() => postData?.post, [postData])

  useEffect(() => {
    if (postId && !postData && dialogProps.open) {
      fetchPost({ variables: { postId } })
    }
  }, [fetchPost, postId, postData, dialogProps.open])

  const { toggleBookmark } = useBookmarks({ post: post })

  const close = async () => {
    if (!currentUser.onboardingActions?.downloadOurApp) {
      logEvent(AhoyEventTypeEnum.NewUserChecklistItemCompleted, {
        step_name: "Download Our App",
      })
      await runUserUpdate({
        variables: {
          input: {
            acknowledgedPwa: true,
          },
        },
      })
    }
    onOpenChange?.(false)
  }

  return (
    <Dialog {...dialogProps} onOpenChange={close}>
      <DialogContent className="p-0">
        <div className="max-h-screen overflow-y-auto p-4 pb-16 sm:pb-4">
          <DialogHeader>
            <DialogTitle>
              <Translation
                ns={camelCase(ScreenEnum.AddToHomescreenModal)}
                i18nKey="title"
              />
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-6 mt-4">
            <div className="grid md:grid-cols-12 gap-6">
              <div className="w-32 mx-auto sm:w-full md:col-span-5 rounded-2xl overflow-hidden">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  loop
                  controls={false}
                  playing={true}
                  url={videoUrl}
                />
              </div>
              <div
                className={cn(
                  "md:col-span-7 flex flex-col bg-white rounded-2xl border p-6",
                  ccls({
                    [CommunitySlug.Gotomillions]: "border-primary",
                    default: "",
                  })
                )}
              >
                <Translation
                  ns={camelCase(ScreenEnum.AddToHomescreenModal)}
                  i18nKey="body"
                />
              </div>
            </div>

            {postLoading && <LoadingIndicatorCentered />}

            {post && (
              <Card
                className={cn(
                  ccls({
                    [CommunitySlug.Gotomillions]: "border-primary",
                    default: "",
                  })
                )}
              >
                <CardContent className="pt-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-xs">
                      <Translation
                        ns={camelCase(ScreenEnum.AddToHomescreenModal)}
                        i18nKey="bookmark_instructions"
                      />
                    </div>
                    <div className="flex flex-col items-center gap-0">
                      <div>
                        <IconButton onClick={() => toggleBookmark()}>
                          <Bookmark
                            className={cn(
                              "w-6 h-6",
                              post?.currentUserBookmark
                                ? "fill-primary"
                                : "text-placeholder"
                            )}
                          />
                        </IconButton>
                      </div>
                      <div className="text-2xs">Bookmark</div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            <div className="flex justify-center">
              <Button onClick={() => close()}>
                <Translation
                  ns={camelCase(ScreenEnum.AddToHomescreenModal)}
                  i18nKey="close"
                />
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
