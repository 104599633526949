import { useEffect, useState } from "react"
import {
  Control,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from "react-hook-form"
import Dropzone from "react-dropzone"
import { useDirectImageUpload } from "~/common/directImageUpload"
import { Button } from "~/ui/button"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { Card, CardContent } from "./card"
import { IconButton } from "./IconButton"
import { Upload, X } from "lucide-react"
import { H6, Muted } from "./typography"
import { FormField } from "./form"

export const UploadDropzone = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  initialUrl,
  title,
  body,
  onChange,
  aspectRatio = 1,
}: {
  control: Control<TFieldValues> | undefined
  name: TName
  initialUrl?: string
  title: JSX.Element
  body: JSX.Element
  aspectRatio?: number
  onChange?: (url: string | null) => void
}) => {
  const { directImageUpload } = useDirectImageUpload()
  const [uploading, setUploading] = useState<boolean>(false)
  const [url, setUrl] = useState<string | null>(initialUrl || null)

  const removeImage = (field: ControllerRenderProps<TFieldValues, TName>) => {
    field.onChange(null)
    setUrl(null)
  }

  useEffect(() => {
    onChange?.(url)
  }, [url])

  // calculate dimensions of image AS A PERCENTAGE based on aspectRatio
  const width = aspectRatio > 1 ? 100 : 100 * aspectRatio
  const height = aspectRatio < 1 ? 100 : 100 / aspectRatio

  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => (
        <Dropzone
          noClick
          onDrop={async (acceptedFiles) => {
            setUploading(true)
            const blob = await directImageUpload(acceptedFiles[0])
            field.onChange(blob.signedId)
            setUrl(URL.createObjectURL(acceptedFiles[0]))
            setUploading(false)
          }}
        >
          {({ getRootProps, getInputProps, open }) => (
            <Card>
              <CardContent className="p-4">
                <div
                  className="flex flex-row items-start gap-4"
                  {...getRootProps()}
                >
                  <div className="w-48 h-48 flex flex-col justify-center items-center gap-2 bg-gray-200 relative">
                    {uploading ? (
                      <LoadingIndicator />
                    ) : url ? (
                      <>
                        <IconButton
                          onClick={() => removeImage(field)}
                          className="absolute top-1 right-1"
                          size="inline"
                        >
                          <X className="w-3 h-3" />
                        </IconButton>
                        <img
                          src={url}
                          alt=""
                          className="object-cover"
                          style={{
                            width: `${width}%`,
                            height: `${height}%`,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <div>
                          <IconButton variant="filled" onClick={open}>
                            <Upload className="w-4 h-4" />
                          </IconButton>
                        </div>
                        <div className="text-2xs">Drag & Drop</div>
                      </>
                    )}
                  </div>
                  <div className="flex-1 flex flex-col gap-4">
                    <H6>{title}</H6>
                    <div className="text-2xs">{body}</div>
                    <div>
                      <input {...getInputProps()} />
                      <Button onClick={open}>Browse Files</Button>
                    </div>
                    <div>
                      <Muted>
                        Supported formats: JPG, PNG, GIF - Maximum size: 25MB
                      </Muted>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </Dropzone>
      )}
    />
  )
}
