import { Dialog, DialogContent } from "~/ui/dialog"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { generateInviteUrl } from "~/common/generateInviteUrl"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { Input } from "~/ui/input"
import copy from "copy-to-clipboard"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Button } from "~/ui/button"
import { useEffect, useState, useMemo } from "react"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"

const formSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().regex(/^[^@\s]+@[^@\s]+$/),
  variationParam: z.string().optional(),
})

export type InviteMemberFormValues = z.infer<typeof formSchema>

export const InviteMemberModal = ({
  isOpen,
  setIsOpen,
  onSuccess,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  onSuccess?: () => void
}) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const { data: variantsData } = useQuery(SCREEN_VARIANTS_QUERY)

  const activeVariants = useMemo(() => {
    return variantsData?.screenVariants.nodes.filter((v) => v.active) || []
  }, [variantsData])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      variationParam: "",
    },
  })

  const [runLeadCreate, leadCreateResult] = useSafeMutation(
    LEAD_INVITE_MEMBER_MUTATION
  )

  const onSubmit = async (values: InviteMemberFormValues) => {
    const { errors } = await runLeadCreate({
      variables: {
        input: {
          email: values.email,
          name:
            values.firstName && values.lastName
              ? `${values.firstName} ${values.lastName}`.trim()
              : values.firstName || values.lastName || undefined,
          variationParam:
            values.variationParam === "__default" ? "" : values.variationParam,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success("Member invitation successfully created")
      onSuccess?.()
      form.reset()
      setShowSuccess(true)
    }
  }

  useEffect(() => {
    if (isOpen === false) {
      form.reset()
      setShowSuccess(false)
    }
  }, [isOpen, form])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0" noCloseButton={true}>
        {showSuccess ? (
          <>
            <div className="mb-2 text-3xl">Success</div>
            <div className="mb-2 tracking-wide text-xs">
              User has been pre-approved and will be deemed fit. Please share
              this unique join link with the pre-approved member.
            </div>
            <div
              className="truncate p-4 bg-gray-100 rounded-md cursor-pointer mb-6"
              onClick={() => {
                const url = generateInviteUrl(
                  leadCreateResult.data?.leadInviteMember?.lead
                    ?.invitationCode || "",
                  leadCreateResult.data?.leadInviteMember?.lead
                    ?.variationParam || ""
                )
                copy(url)
                toast.success("Invite URL copied")
              }}
            >
              {generateInviteUrl(
                leadCreateResult.data?.leadInviteMember?.lead?.invitationCode ||
                  "",
                leadCreateResult.data?.leadInviteMember?.lead?.variationParam ||
                  ""
              )}
            </div>
            <div className="flex justify-center">
              <Button
                type="button"
                className="px-10"
                onClick={() => setIsOpen(false)}
              >
                Got It
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-2 text-3xl">Add Pre-Approved Member</div>
            <div className="mb-6 tracking-wide text-xs">
              A pre-approved member will be deemed fit, regardless of their
              application inputs
            </div>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input
                          className="bg-white"
                          placeholder="John"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input
                          className="bg-white"
                          placeholder="Doe"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel required>Email Address</FormLabel>
                      <FormControl>
                        <Input
                          className="bg-white"
                          type="email"
                          placeholder="sample@gmail.com"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="variationParam"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="tracking-wide">
                        Screen Variant
                      </FormLabel>
                      <FormControl>
                        <Select
                          value={field.value}
                          onValueChange={field.onChange}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select a screen variant" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="__default">
                              Default (No Variant)
                            </SelectItem>
                            {activeVariants.map((variant) => (
                              <SelectItem
                                key={variant.id}
                                value={variant.variantIdentifier || "__default"}
                              >
                                {variant.screen} -{" "}
                                {variant.variantIdentifier || "(default)"}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="mt-10 flex justify-end items center gap-4">
                  <Button
                    type="button"
                    className="border-none"
                    size="lg"
                    onClick={() => setIsOpen(false)}
                    variant="outline"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="xl"
                    disabled={leadCreateResult.loading}
                    isLoading={leadCreateResult.loading}
                  >
                    Invite Member
                  </Button>
                </div>
              </form>
            </Form>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

const SCREEN_VARIANTS_QUERY = gql(`
  query ScreenVariantsForInvite {
    screenVariants {
      nodes {
        id
        screen
        variantIdentifier
        active
      }
    }
  }
`)

const LEAD_INVITE_MEMBER_MUTATION = gql(`
  mutation LeadInviteMember($input: LeadInviteMemberInput!) {
    leadInviteMember(input: $input) {
      lead {
        id
        email
        invitationCode
        variationParam
      }
    }
  }
`)
