import { useEffect } from "react"
import { CommunitySlug, MarkReadEnum } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { MARK_READ_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { ProEventsBanner } from "~/pro-events/ProEventsBanner"
import { useConfig } from "~/common/ConfigProvider"
import { Card, CardContent } from "~/ui/card"
import { H4, Muted } from "~/ui/typography"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"

export const EventsScreen = () => {
  const { featureFlags } = useConfig()
  const community = useCommunity()
  const ccls = useCommunityClassname()

  const [runMarkRead] = useSafeMutation(MARK_READ_MUTATION)

  const { data } = useQuery(CALENDAR_EVENTS_QUERY_DOCUMENT, {
    variables: { first: 10, internalOnly: true },
    skip: !featureFlags?.internalCalendarEvents,
  })

  useEffect(() => {
    runMarkRead({
      variables: {
        input: {
          target: MarkReadEnum.Events,
        },
      },
    })
  }, [runMarkRead])

  const CALENDAR_VARIANTS: Record<CommunitySlug, "light" | "dark"> = {
    [CommunitySlug.Safespace]: "light",
    [CommunitySlug.Fintechtakes]: "light",
    [CommunitySlug.Boardroom]: "dark",
    [CommunitySlug.Marketingland]: "dark",
    [CommunitySlug.Gotomillions]: "dark",
  }

  if (featureFlags?.internalCalendarEvents) {
    return data?.calendarEvents?.edges?.map((edge) => {
      const event = edge.node
      return (
        <Card key={event.id} className="mb-4">
          <CardContent>
            <H4>{event.name}</H4>
            <Muted>Description: {event.description}</Muted>
            <p>
              Hosts:{" "}
              {event.calendarEventHosts
                .map((host) => host.user.name)
                .join(", ")}
            </p>
            <p>
              Guests:{" "}
              {event.calendarEventGuests
                .map((guest) => guest.user.name)
                .join(", ")}
            </p>
            <p>{event.startAt}</p>

            <pre>{JSON.stringify(event, null, 2)}</pre>
          </CardContent>
        </Card>
      )
    })
  }

  return (
    <div className="flex flex-col w-full">
      <ProEventsBanner />
      <a
        className="py-4"
        href={community.lumaEventsUrl!}
        target="_blank"
        rel="noreferrer"
      >
        <h1 className="text-xl tracking-wide text-foreground">
          See All Events
        </h1>
      </a>
      <div>
        <iframe
          title="Embedded Events"
          src={
            community.lumaCalendarUrl +
            `?lt=${CALENDAR_VARIANTS[community.slug]}`
          }
          className={cn(
            "rounded-lg w-full h-screen border mb-10",
            ccls({
              [CommunitySlug.Boardroom]: "border-gray-500",
              default: "border-mercury",
            })
          )}
          allowFullScreen={true}
          aria-hidden={false}
          tabIndex={0}
        ></iframe>
      </div>
    </div>
  )
}

gql(`
  fragment CalendarEvent_Legacy on CalendarEvent {
    id
    startAt
    name
    url
    hosts {
      name
      avatarUrl
    }
  }
`)

gql(`
  fragment CalendarEvent_Display on CalendarEvent {
    accessLevel
    description
    duration
    endAt
    eventType
    featured
    geoLatitude
    geoLongitude
    id
    locationName
    locationUrl
    name
    sponsored
    startAt
    timezone

    imageId
    imageUrl

    calendarEventHosts {
      id
      role
      status
      confirmedAt
      tentativeAt
      canceledAt
      user {
        ...User_Avatar
      }
    }

    calendarEventGuests {
      id
      role
      status
      confirmedAt
      tentativeAt
      canceledAt
      user {
        ...User_Avatar
      }
    }
  }
`)

export const CALENDAR_EVENTS_QUERY_DOCUMENT = gql(`
  query CalendarEvents($first: Int, $after: String, $upcoming: Boolean, $internalOnly: Boolean) {
    calendarEvents(first: $first, after: $after, upcoming: $upcoming, internalOnly: $internalOnly) {
      totalCount
      edges {
        node {
          ...CalendarEvent_Legacy
          ...CalendarEvent_Display
        }
      }
    }
  }
`)
