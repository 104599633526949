import {
  ArticleStateEnum,
  ArticleVisibilityEnum,
} from "~/__generated__/graphql"

export const ARTICLE_VISIBILITY_LABELS: Record<ArticleVisibilityEnum, string> =
  {
    LIVE: "Live",
    HIDDEN: "Hidden",
  }

export const ARTICLE_STATE_LABELS: Record<ArticleStateEnum, string> = {
  DRAFT: "Draft",
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  EDITS_REQUESTED: "Edits Requested",
  HAS_PENDING_EDITS: "Pending edits",
  ARCHIVED: "Archived",
}
