import { useCallback, useMemo, useState } from "react"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ArticleDetailsFormValues } from "./ArticleDetailsModal"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import {
  ArticleContentTypeEnum,
  Article_EditorContentFragment,
  AhoyEventTypeEnum,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"

export type ArticleDetailsModalHelpers = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  onSubmit: (values: ArticleDetailsFormValues) => void
  isLoading: boolean
  initialFormValues: ArticleDetailsFormValues | null
}

export const useArticleDetailsModal = ({
  onSuccess,
  existingArticle,
}: {
  onSuccess?: (articleId: string) => void
  existingArticle?: Article_EditorContentFragment
} = {}): ArticleDetailsModalHelpers => {
  const { logEvent } = useLogEvent()
  const [isOpen, setIsOpen] = useState(false)

  const [runCreateArticle, createArticleResult] = useSafeMutation(
    CREATE_ARTICLE_MUTATION
  )

  const createArticle = useCallback(
    async (values: ArticleDetailsFormValues) => {
      const { data, errors } = await runCreateArticle({
        variables: {
          input: {
            ...values,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
        console.log(errors)
      } else {
        toast.success("Created content")
        setIsOpen(false)
        invariant(data?.articleCreate.article.id)

        logEvent(AhoyEventTypeEnum.ContentSubmitted, {
          content_id: data.articleCreate.article.id,
        })
        onSuccess?.(data.articleCreate.article.id)
      }
    },
    [runCreateArticle, onSuccess, logEvent]
  )

  const [runUpdateArticle, updateArticleResult] = useSafeMutation(
    UPDATE_ARTICLE_METADATA_MUTATION
  )

  const updateArticle = useCallback(
    async (values: ArticleDetailsFormValues) => {
      invariant(existingArticle?.id)

      const { data, errors } = await runUpdateArticle({
        variables: {
          input: {
            articleId: existingArticle.id,
            ...values,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
        console.log(errors)
      } else {
        toast.success("Details saved")
        setIsOpen(false)
        invariant(data?.articleUpdate.article.id)
        onSuccess?.(data.articleUpdate.article.id)
      }
    },
    [onSuccess, existingArticle?.id, runUpdateArticle]
  )

  const onSubmit = useCallback(
    async (values: ArticleDetailsFormValues) => {
      if (existingArticle?.id) {
        updateArticle(values)
      } else {
        createArticle(values)
      }
    },
    [createArticle, updateArticle, existingArticle?.id]
  )

  const initialFormValues = useMemo(
    () =>
      existingArticle
        ? {
            contentType:
              existingArticle.revision.contentType ||
              ArticleContentTypeEnum.Course,
            tagId: existingArticle.revision.tag.id,
            title: existingArticle.revision.title || "",
            description: existingArticle.revision.description || "",
          }
        : null,
    [existingArticle]
  )

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      onSubmit,
      isLoading: createArticleResult.loading || updateArticleResult.loading,
      initialFormValues,
    }),
    [
      isOpen,
      setIsOpen,
      onSubmit,
      createArticleResult.loading,
      updateArticleResult.loading,
      initialFormValues,
    ]
  )

  return value
}

export const CREATE_ARTICLE_MUTATION = gql(`
  mutation CreateArticle($input: ArticleCreateInput!) {
    articleCreate(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)

const UPDATE_ARTICLE_METADATA_MUTATION = gql(`
  mutation UpdateArticleMetadata($input: ArticleUpdateInput!) {
    articleUpdate(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)
