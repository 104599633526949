import * as React from "react"
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"

import { cn } from "~/lib/utils"

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    orientation?: "vertical" | "horizontal"
  }
>(({ className, children, orientation, ...props }, ref) => {
  const viewportRef = React.useRef<HTMLDivElement>(null)

  // Determine if the viewport has been scrolled
  const [canScroll, setCanScroll] = React.useState(false)
  const [isScrolled, setIsScrolled] = React.useState(false)
  const [isScrolledToEnd, setIsScrolledToEnd] = React.useState(false)

  React.useEffect(() => {
    const viewport = viewportRef.current

    const handleScroll = () => {
      if (viewport) {
        setCanScroll(
          orientation === "vertical"
            ? viewport.scrollHeight > viewport.clientHeight
            : viewport.scrollWidth > viewport.clientWidth
        )
        setIsScrolled(viewport.scrollTop > 0 || viewport.scrollLeft > 0)
        setIsScrolledToEnd(
          orientation === "vertical"
            ? viewport.scrollTop + viewport.clientHeight >=
                viewport.scrollHeight
            : viewport.scrollLeft + viewport.clientWidth >= viewport.scrollWidth
        )
      }
    }

    if (viewport) {
      viewport.addEventListener("scroll", handleScroll)
      handleScroll()

      return () => {
        viewport.removeEventListener("scroll", handleScroll)
      }
    }
  }, [viewportRef.current, orientation])

  return (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn(
        "relative overflow-hidden",
        isScrolled &&
          "[&_.scroll-fade-left]:opacity-100 [&_.scroll-fade-top]:opacity-100",
        !isScrolled &&
          "[&_.scroll-fade-left]:opacity-0 [&_.scroll-fade-top]:opacity-0",
        isScrolledToEnd &&
          "[&_.scroll-fade-right]:opacity-0 [&_.scroll-fade-bottom]:opacity-0",
        !isScrolledToEnd &&
          "[&_.scroll-fade-right]:opacity-100 [&_.scroll-fade-bottom]:opacity-100",
        canScroll && "pb-8",
        !canScroll && "[&_.scroll-bar]:hidden",
        className
      )}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        className="h-full w-full rounded-[inherit]"
        ref={viewportRef}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  )
})
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      "scroll-bar flex touch-none select-none transition-colors rounded-full",
      orientation === "vertical" &&
        "h-full w-2.5 border-l border-l-transparent p-[1px]",
      orientation === "horizontal" &&
        "h-2.5 flex-col border-t border-t-transparent p-[1px] bg-white",
      className
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-placeholder" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
))
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

const ScrollFade = ({
  direction,
  className,
}: {
  direction: "top" | "bottom" | "left" | "right"
  className?: string
}) => {
  return (
    <div
      className={cn(
        "scroll-fade absolute z-10 from-current to-transparent pointer-events-none",
        "transition-opacity duration-400 ease-in-out",
        direction === "top" &&
          "scroll-fade-top bg-gradient-to-b top-0 left-0 right-0",
        direction === "bottom" &&
          "scroll-fade-bottom bg-gradient-to-t bottom-0 left-0 right-0",
        direction === "left" &&
          "scroll-fade-left bg-gradient-to-r bl-current top-0 left-0 bottom-0 w-8 h-full rounded-r",
        direction === "right" &&
          "scroll-fade-right bg-gradient-to-l br-current top-0 right-0 bottom-0 w-8 h-full rounded-l",
        className
      )}
    />
  )
}

export { ScrollArea, ScrollBar, ScrollFade }
