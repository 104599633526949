import { ApolloError, FetchResult } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { camelCase, capitalCase } from "change-case"
import { Fragment, useEffect, useMemo, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import {
  ScreenEnum,
  ScreenVariantCreateInput,
  ScreenVariantCreateMutation,
  ScreenVariantUpdateMutation,
} from "~/__generated__/graphql"
import { AdminActionToolbar } from "~/admin/AdminActionToolbar"
import { applicationLandingPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import { SelectField } from "~/components/forms/SelectField"
import { TextareaField } from "~/components/forms/TextareaField"
import { TextField } from "~/components/forms/TextField"
import { Button } from "~/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "~/ui/form"
import { Input } from "~/ui/input"
import { Label } from "~/ui/label"
import { Switch } from "~/ui/switch"

const formSchema = z.object({
  screen: z.string().transform((v) => v as ScreenEnum),
  variantIdentifier: z
    .string()
    .refine((v) => v.length <= 50, "Must be 50 characters or less")
    .refine(
      (v) => /^[a-zA-Z0-9_-]*$/.test(v),
      "Must only contain letters, numbers, underscores, and hyphens"
    ),
  active: z.boolean(),
  overrides: z.object({
    translations: z.record(z.string(), z.string().optional()),
    options: z.record(z.string(), z.any()),
  }),
})

export type ScreenVariantFormValues = z.infer<typeof formSchema>

interface ScreenVariantFormProps {
  initialValues?: ScreenVariantFormValues
  onSubmit: (
    data: ScreenVariantCreateInput
  ) => Promise<
    | FetchResult<ScreenVariantCreateMutation>
    | FetchResult<ScreenVariantUpdateMutation>
  >
}

export const ScreenVariantForm = ({
  initialValues,
  onSubmit,
}: ScreenVariantFormProps) => {
  const form = useForm<ScreenVariantFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues || {
      screen: ScreenEnum.ApplicationLandingScreen,
      variantIdentifier: "",
      active: true,
      overrides: {
        translations: {},
        options: {},
      },
    },
  })
  const [isSaving, setIsSaving] = useState(false)
  const { t: screens } = useTranslation("screens")

  const handleSubmit = async (data: ScreenVariantFormValues) => {
    setIsSaving(true)
    try {
      const { errors } = await onSubmit({
        ...data,
      })

      if (errors && (errors as unknown as ApolloError).graphQLErrors) {
        displayErrors(errors, form.setError)
      }
    } catch (e) {
      console.error(e)
      toast.error("Failed to save screenVariant")
    }
    setIsSaving(false)
  }

  // Get the translation namespace for the screen using the screen as the namespace
  const { i18n } = useTranslation()
  const [defaultTranslationsForScreen, setDefaultTranslationsForScreen] =
    useState<Record<string, string>>({})

  const values = useWatch({ control: form.control })

  useEffect(() => {
    if (!values.screen) return
    if (i18n.store.data["en-US"][camelCase(values.screen)]) {
      setDefaultTranslationsForScreen(
        i18n.store.data["en-US"][camelCase(values.screen)] as Record<
          string,
          string
        >
      )
    }
  }, [i18n, values.screen])

  const url = useMemo(() => {
    const screen = values.screen
    const variantIdentifier = values.variantIdentifier

    const baseUrl = window.location.origin
    let url = ""
    if (screen === ScreenEnum.ApplicationLandingScreen) {
      url = applicationLandingPath.pattern
    }

    if (!url) return ""

    if (!variantIdentifier) {
      return `${baseUrl}${url}`
    }

    return `${baseUrl}${url}?v=${variantIdentifier}`
  }, [values])

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="grid md:grid-cols-2 gap-4">
              <SelectField
                control={form.control}
                name="screen"
                label="Screen"
                required={true}
                selectEntries={Object.values(ScreenEnum).map((option) => ({
                  label: screens(option),
                  value: option,
                }))}
              />

              <TextField
                control={form.control}
                name="variantIdentifier"
                label="Custom Variant Identifier"
                placeholder="(default)"
              />

              {url && (
                <div className="flex flex-col gap-2">
                  <Label>URL</Label>
                  <Input
                    disabled
                    value={url}
                    endAdornment={
                      <>
                        <CopyToClipboard text={url} variant="adornment" />
                      </>
                    }
                  />
                </div>
              )}
            </div>
            <Card>
              <CardHeader>
                <CardTitle>Copy Overrides</CardTitle>
              </CardHeader>
              <CardContent className="p-4">
                <div className="flex flex-col gap-4">
                  {defaultTranslationsForScreen &&
                    Object.keys(defaultTranslationsForScreen).map((key) => (
                      <Fragment key={key}>
                        {defaultTranslationsForScreen[key].length > 100 ? (
                          <TextareaField
                            control={form.control}
                            label={capitalCase(key)}
                            name={`overrides.translations.${key}`}
                            defaultValue={defaultTranslationsForScreen[key]}
                            required={false}
                          />
                        ) : (
                          <TextField
                            control={form.control}
                            label={capitalCase(key)}
                            name={`overrides.translations.${key}`}
                            defaultValue={defaultTranslationsForScreen[key]}
                            required={false}
                          />
                        )}
                      </Fragment>
                    ))}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Page Options</CardTitle>
              </CardHeader>
              <CardContent className="p-4">
                <div className="grid grid-cols-2 gap-4">
                  {form.watch("screen") ===
                    ScreenEnum.ApplicationLandingScreen && (
                    <FormField
                      control={form.control}
                      name="overrides.options.showBanner"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                          <div className="space-y-0.5">
                            <FormLabel className="text-base">
                              Display Banner?
                            </FormLabel>
                            <FormDescription>
                              If enabled, display the call to action banner on
                              the screen.
                            </FormDescription>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  )}

                  {form.watch("screen") === ScreenEnum.AddToHomescreenModal && (
                    <>
                      <TextField
                        control={form.control}
                        name="overrides.options.postId"
                        label="Post ID"
                      />

                      <TextField
                        control={form.control}
                        name="overrides.options.videoUrl"
                        label="Video URL"
                      />
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>

          <AdminActionToolbar>
            <Switch
              variant="selector"
              size="lg"
              options={["Inactive", "Active"]}
              checked={form.watch("active")}
              onCheckedChange={(value) =>
                form.setValue("active", !!value, { shouldDirty: true })
              }
            />
            <Button
              type="submit"
              isLoading={form.formState.isSubmitting}
              disabled={isSaving || !form.formState.isDirty}
            >
              Save Screen Variant
            </Button>
          </AdminActionToolbar>
        </form>
      </Form>
    </>
  )
}
