import CloseCircle from "../images/icons/close-circle.svg?react"

const matchCountText = (totalCount: number) => {
  if (totalCount === 0) {
    return "No Matches"
  } else if (totalCount === 1) {
    return "1 Match"
  } else {
    return `${totalCount} Matches`
  }
}

export const SearchFeedback = ({
  totalCount,
  onClearSearch,
}: {
  totalCount: number
  onClearSearch: () => void
}) => {
  return (
    <div className="text-xs tracking-wide px-4">
      <div className="flex flex-col md:flex-row md:items-center gap-2">
        <div>
          <span className="font-semibold">{matchCountText(totalCount)}</span>
          <span> for your search criteria</span>
        </div>
        <div
          className="text-highlight flex flex-row items-center"
          role="button"
          onClick={onClearSearch}
        >
          <span>Clear Search</span>
          <CloseCircle className="ml-1" />
        </div>
      </div>
    </div>
  )
}
