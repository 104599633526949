import { ReactNode, useEffect } from "react"
import { cn } from "~/lib/utils"
import CheckmarkIcon from "../images/icons/checkmark.svg?react"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { iHateItHereBranding } from "~/images/__generated__/i-hate-it-here-branding"
import { CommunityLogo } from "~/community/CommunityLogo"

const OnboardingStep = ({
  title,
  completed,
  current,
}: {
  title: string
  completed: boolean
  current: boolean
}) => {
  return (
    <div className="flex mb-2 lg:mb-0 items-center mr-9 last:mr-0 font-[14px] whitespace-nowrap font-medium">
      {completed ? (
        <div className="bg-highlight rounded-full w-[24px] h-[24px] flex items-center justify-center">
          <CheckmarkIcon />
        </div>
      ) : (
        <div className="bg-white border-[1px] border-borderColor rounded-[50%] w-[24px] h-[24px]"></div>
      )}
      <div className={cn("ml-3", { "opacity-70": !current && !completed })}>
        {title}
      </div>
    </div>
  )
}

const OnboardingSteps = ({
  className,
  currentStep,
}: {
  className: string
  currentStep: number
}) => {
  return (
    <div
      className={cn(
        "mb-5 lg:absolute lg:flex lg:items-center lg:mb-0 lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2",
        className
      )}
    >
      <OnboardingStep
        title="All About You"
        current={currentStep === 1}
        completed={currentStep > 1}
      />
      <OnboardingStep
        title="Interests & Expertise"
        current={currentStep === 2}
        completed={currentStep > 2}
      />
      <OnboardingStep
        title="Opt-In"
        current={currentStep === 3}
        completed={currentStep > 3}
      />
    </div>
  )
}

export const OnboardingLayout = ({
  step,
  children,
  fullWidth = false,
  hideCommunityFooter = false,
  containerInnerClass,
}: {
  step: number
  children: ReactNode
  fullWidth?: boolean
  hideCommunityFooter?: boolean
  containerInnerClass?: string
}) => {
  const community = useCommunity()
  const ccls = useCommunityClassname()

  useEffect(() => {
    document.body.classList.add("onboarding")
    document.body.classList.add(`onboarding-${community.slug.toLowerCase()}`)
    return () => {
      document.body.classList.remove("onboarding")
      document.body.classList.remove(
        `onboarding-${community.slug.toLowerCase()}`
      )
    }
  }, [community.slug])

  const containerClass = "overflow-auto px-4 pt-10 flex-grow"

  const headerClass = cn(
    "p-4 max-md:pt-9 md:h-[73px] border-b-[1px] px-4 relative flex items-center w-full",
    {
      "justify-center": step <= 0,
      "justify-center md:justify-start": step > 0,
    },
    ccls({
      [CommunitySlug.Marketingland]: "border-primary",
      default: "border-borderColor",
    })
  )
  const footerClass = "flex flex-col items-center py-8 md:pt-16 md:pb-6"

  return (
    <div className="min-h-screen text-foreground tracking-[0.5px] flex flex-col items-center">
      <div
        className={cn(
          headerClass,
          "bg-background",
          ccls({
            [CommunitySlug.Fintechtakes]: "bg-primary",
            default: "",
          })
        )}
      >
        <CommunityLogo
          className={cn(
            ccls({
              [CommunitySlug.Fintechtakes]: "max-h-[52px] text-white",
              default: "max-h-[41px]",
            })
          )}
        />
        {step > 0 && (
          <OnboardingSteps className="max-lg:hidden" currentStep={step} />
        )}
      </div>

      <div className={containerClass}>
        <div
          className={cn(
            "m-auto md:pb-20",
            {
              "sm:max-w-[660px]": !fullWidth,
            },
            containerInnerClass
          )}
        >
          {step > 0 && (
            <OnboardingSteps className="lg:hidden" currentStep={step} />
          )}
          {children}
        </div>
      </div>
      {!hideCommunityFooter && community.slug === CommunitySlug.Safespace && (
        <div className={footerClass}>
          <div className="text-[12px] mb-2 md:text-xs md:mb-4 font-medium">
            Brought to you by
          </div>
          <img
            {...iHateItHereBranding}
            className="w-[234px] h-auto md:w-[300px]"
            alt="I hate it here logo"
          />
        </div>
      )}
    </div>
  )
}
