import { useCommunityClassname } from "~/community/useCommunity"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { CommunitySlug, TierLevelEnum } from "~/__generated__/graphql"
import Medal from "~/images/icons/medal.svg?react"
import { cn } from "~/lib/utils"
import { CommunityLogo } from "~/community/CommunityLogo"

const TIERS: Record<TierLevelEnum, string> = {
  [TierLevelEnum.Free]: "Free Member",
  [TierLevelEnum.Plus]: "Plus Member",
  [TierLevelEnum.Pro]: "Pro Member",
}

const tierDisplay = (tier?: TierLevelEnum) => TIERS[tier || TierLevelEnum.Free]

type ActivationStatus = "Not Activated" | "Activating" | "Activated"

export const MembershipCard = ({
  containerClassName,
}: {
  containerClassName?: string
}) => {
  const currentUser = useCurrentUser()
  const name = currentUser.name || "New Member"

  const ccls = useCommunityClassname()

  let status: ActivationStatus = "Not Activated"
  if (currentUser.fit) {
    status = "Activated"
  } else if (currentUser.fitWaiting) {
    status = "Activating"
  }

  return (
    <div
      className={cn(
        "p-6 rounded-xl w-full md:w-[415px] aspect-video flex-shrink-0 flex flex-col justify-between",
        ccls({
          [CommunitySlug.Safespace]: "safespace-membership-card-bg text-white",
          [CommunitySlug.Boardroom]: "boardroom-membership-card-bg text-white",
          [CommunitySlug.Marketingland]:
            "marketingland-membership-card-bg text-white",
          [CommunitySlug.Gotomillions]:
            "gotomillions-membership-card-bg text-white",
          [CommunitySlug.Fintechtakes]:
            "fintechtakes-membership-card-bg text-black",
        }),
        containerClassName
      )}
    >
      <div className="flex items-center justify-between">
        <CommunityLogo className="max-h-6 w-auto" />

        <div
          className={cn(
            "px-3 py-2 text-black text-2xs rounded tracking-wide text-white uppercase",
            ccls({
              [CommunitySlug.Safespace]: "bg-subtlePink text-black",
              default: "bg-highlight",
            }),
            status === "Activating" && "bg-[#FFD829] text-black",
            status === "Activated" && "bg-seaGreen text-black"
          )}
        >
          {status}
        </div>
      </div>
      <div className="text-2xl">5892 7629 1003 2241</div>
      <div className="flex items-center justify-between">
        <div className="text-lg">{name}</div>
        <div className="flex items-center gap-1 text-2xs mt-1">
          <Medal
            className={cn(
              "mt-[2px]",
              ccls({
                [CommunitySlug.Safespace]: "text-primary",
                default: "text-highlight",
              })
            )}
          />
          {tierDisplay(currentUser.tier?.level)}
        </div>
      </div>
    </div>
  )
}
