import { DialogProps } from "@radix-ui/react-dialog"
import { useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Dialog, DialogContent } from "~/ui/dialog"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Input } from "~/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { Textarea } from "~/ui/textarea"
import { Button } from "~/ui/button"
import { useTags } from "~/common/queries"
import {
  ArticleContentTypeEnum,
  ArticleStateEnum,
  ArticleVisibilityEnum,
} from "~/__generated__/graphql"
import {
  SavedOrUnsavedLesson,
  SavedOrUnsavedSection,
  UnsavedLesson,
  generateTempId,
  sectionId as getSectionId,
} from "~/types"

const createLessonModalFormSchema = z.object({
  tagId: z.string({
    required_error: "Select a topic",
  }),
  title: z.string().min(1, {
    message: "Title is required",
  }),
  description: z.string().min(1, {
    message: "Description is required",
  }),
})
export type CreateLessonModalFormValues = z.infer<
  typeof createLessonModalFormSchema
>

export const useCreateLesson = () => {
  const [runCreateLesson, createLessonResult] = useSafeMutation(
    CREATE_LESSON_MUTATION
  )

  const createLesson = useCallback(
    async (lesson: UnsavedLesson) => {
      const { data, errors } = await runCreateLesson({
        variables: {
          input: {
            sectionId: lesson.sectionId,
            title: lesson.article.revision.title,
            description: lesson.article.revision.description || "",
            tagId: lesson.article.revision.tag.id,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
      } else {
        toast.success("Lesson created")
      }

      return data?.lessonCreate.lesson
    },
    [runCreateLesson]
  )

  return { createLesson, createLessonResult }
}

export type CreateLessonModalProps = {
  onCreateLesson?: (lesson: SavedOrUnsavedLesson) => void
  loading: boolean
  section: SavedOrUnsavedSection
} & DialogProps

export const CreateLessonModal = ({
  onCreateLesson,
  loading,
  section,
  ...dialogProps
}: CreateLessonModalProps) => {
  const tags = useTags()
  const form = useForm<z.infer<typeof createLessonModalFormSchema>>({
    resolver: zodResolver(createLessonModalFormSchema),
    defaultValues: {
      title: "",
      description: "",
      tagId: undefined,
    },
  })
  const sectionId = getSectionId(section)

  const onSubmit = useCallback(
    async (values: CreateLessonModalFormValues) => {
      const newLesson: UnsavedLesson = {
        tempId: generateTempId("lesson"),
        position: 0,
        sectionId,
        hidden: section.hidden,
        article: {
          tempId: generateTempId("article"),
          collaborators: [],
          currentUserCanApprove: { value: false },
          currentUserCanChangeVisibility: { value: false },
          currentUserCanMarkAsFeatured: { value: false },
          currentUserCanRequestEdits: { value: false },
          currentUserCanSubmitForApproval: { value: false },
          currentUserCanViewRevisionLogs: { value: false },
          currentUserCanToggleHidden: { value: false },
          currentUserCanMarkAsPremium: { value: false },
          currentUserCanChangeExternalShareSetting: { value: false },
          currentUserCanArchive: { value: false },
          featuredUntil: null,
          premium: false,
          allowExternalShare: false,
          isLockedBySomeoneElse: false,
          createdAt: new Date().toISOString(),
          state: ArticleStateEnum.Draft,
          visibility: ArticleVisibilityEnum.Hidden,
          revision: {
            tempId: generateTempId("revision"),
            contentType: ArticleContentTypeEnum.Lesson,
            contentVersion: "-1",
            description: values.description,
            tag: {
              id: values.tagId,
              name: tags?.find((tag) => tag.id === values.tagId)?.name || "",
            },
            title: values.title,
          },
        },
      }
      onCreateLesson?.(newLesson)
    },
    [onCreateLesson, sectionId, tags, section.hidden]
  )

  const cancel = useCallback(() => {
    if (dialogProps.onOpenChange) {
      dialogProps.onOpenChange(false)
    }
  }, [dialogProps])

  useEffect(() => {
    if (!dialogProps.open) {
      form.reset()
    }
  }, [dialogProps.open, form])

  return (
    <Dialog {...dialogProps}>
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center mb-2 font-serif text-3xl">
          Add New Lesson
        </div>
        <div className="text-center mb-6 text-2xs">
          Get started by telling us the type of content you would like to
          create.
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="tagId"
              render={({ field }) => (
                <FormItem className="w-1/2 mb-4">
                  <FormLabel required>Select Topic</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a topic..." />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {tags &&
                        tags.map((tag) => (
                          <SelectItem value={tag.id} key={tag.id}>
                            {tag.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel required>Title</FormLabel>
                  <FormControl>
                    <Input
                      className="bg-white"
                      placeholder="Title"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel required>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      className="bg-white"
                      placeholder="Enter a description of the lesson"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-between">
              <Button variant="ghost" onClick={cancel}>
                Cancel
              </Button>

              <Button type="submit" disabled={loading} isLoading={loading}>
                Continue
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const CREATE_LESSON_MUTATION = gql(`
  mutation CreateLesson($input: LessonCreateInput!) {
    lessonCreate(input: $input) {
      lesson {
        ...Lesson_EditorContent
      }
    }
  }
`)
