import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import { Button, buttonVariants } from "~/ui/button"
import { Check, ChevronsUpDown, Sparkle, X } from "lucide-react"
import { Command, CommandGroup, CommandInput, CommandItem } from "~/ui/command"
import { useFormContext } from "react-hook-form"
import { useCommunityClassname } from "~/community/useCommunity"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { useEffect, useMemo, useState } from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { OnboardingFieldLabel } from "~/ui/Onboarding"

const COMMON_PRONOUNS = ["She / Her", "He / Him", "They / Them"]

export const Pronouns = ({
  displayPronouns = false,
}: {
  displayPronouns?: boolean
}) => {
  const currentUser = useCurrentUser()
  const [enteredPronouns, setEnteredPronouns] = useState("")
  const [selectedPronouns, setSelectedPronouns] = useState(currentUser.pronouns)

  const { setValue, register } = useFormContext()

  const ccls = useCommunityClassname()

  const displayedPronouns = useMemo(() => {
    return [
      selectedPronouns,
      currentUser.pronouns,
      ...COMMON_PRONOUNS,
      enteredPronouns,
    ]
      .filter((pronouns) => pronouns)
      .filter(
        (pronouns, index, self) => self.indexOf(pronouns) === index
      ) as string[]
  }, [enteredPronouns, selectedPronouns, currentUser.pronouns])

  const [pronounsPickerOpen, setPronounsPickerOpen] = useState(false)
  useEffect(() => {
    if (!pronounsPickerOpen) {
      setEnteredPronouns("")
    }
  }, [pronounsPickerOpen])
  const onSelectPronouns = (pronouns: string) => {
    setSelectedPronouns(pronouns)
    setValue("pronouns", pronouns, {
      shouldDirty: currentUser.pronouns !== pronouns,
    })
    setPronounsPickerOpen(false)
  }

  const clearPronouns = (
    e: Pick<Event, "preventDefault" | "stopPropagation">
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedPronouns("")
    setEnteredPronouns("")
    setValue("pronouns", "", { shouldDirty: !!currentUser.pronouns })
  }

  return (
    displayPronouns && (
      <label className="onboarding-field col-span-2">
        <OnboardingFieldLabel>Pronouns</OnboardingFieldLabel>
        <input
          type="hidden"
          {...register("pronouns")}
          value={selectedPronouns || ""}
        />
        <Popover open={pronounsPickerOpen} onOpenChange={setPronounsPickerOpen}>
          <PopoverTrigger
            asChild
            className={cn(
              ccls({
                [CommunitySlug.Gotomillions]: "border-primary",
                default: "",
              })
            )}
          >
            <Button
              theme="current"
              variant="input"
              role="combobox"
              className="text-md font-normal py-6"
            >
              <span className="overflow-hidden text-ellipsis whitespace-nowrap !inline-block">
                {selectedPronouns || "Select or enter your pronouns"}
              </span>
              <div className="flex items-right items-center justify-center">
                <div
                  className={cn(
                    buttonVariants({ variant: "ghost", size: "icon" }),
                    "ml-2 shrink-0",
                    selectedPronouns ? "opacity-100" : "opacity-0"
                  )}
                  onClick={clearPronouns}
                >
                  <X className="w-5 h-5" />
                </div>

                <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Command>
              <CommandInput
                icon={<></>}
                value={enteredPronouns}
                onValueChange={setEnteredPronouns}
                placeholder="Select or enter your pronouns"
              />
              <CommandGroup>
                {displayedPronouns.map((pronouns) => (
                  <CommandItem
                    key={pronouns}
                    onSelect={() => onSelectPronouns(pronouns)}
                    value={pronouns}
                  >
                    {!COMMON_PRONOUNS.includes(pronouns) &&
                    selectedPronouns !== pronouns ? (
                      <Sparkle className="w-5 h-5 mr-2" />
                    ) : (
                      <Check
                        className={cn(
                          "w-5 h-5 mr-2",
                          selectedPronouns === pronouns
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    )}
                    {pronouns}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </label>
    )
  )
}
