import { useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { gql } from "~/__generated__"
import { CommunitySlug } from "~/__generated__/graphql"
import { formatDate } from "~/common/formatDate"
import { articlePath } from "~/common/paths"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { HighlightContent } from "~/components/HighlightContent"
import { cn } from "~/lib/utils"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"

export const DeepDiveSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 ">
        <div className="h-6 bg-gray-200 animate-pulse"></div>
        <div className="h-6 bg-gray-200 animate-pulse w-[40px]"></div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="h-6 bg-gray-200 animate-pulse"></div>
        <div className="h-6 bg-gray-200 animate-pulse"></div>
        <div className="h-6 bg-gray-200 animate-pulse w-[80px]"></div>
      </div>
      <div className="h-6 bg-gray-200 animate-pulse w-[40px]"></div>
      <div className="h-6 bg-gray-200 animate-pulse w-[50px]"></div>
    </div>
  )
}

export const DeepDiveModule = () => {
  const { creator } = useCommunity()
  const { data, loading } = useQuery(DEEP_DIVE_QUERY_DOCUMENT, {
    variables: {
      collaborators: [creator.id],
    },
  })
  const article = useMemo(() => data?.articles.edges[0]?.node, [data])
  const ccls = useCommunityClassname()

  return !loading && !article ? null : (
    <Card>
      <CardHeader>
        <CardTitle>Latest Deep Dive</CardTitle>
      </CardHeader>
      <CardContent className="pt-4">
        {loading && <DeepDiveSkeleton />}
        {!loading && article && (
          <div className="flex flex-col gap-3">
            <div className="font-medium text-sm">
              <Link
                to={articlePath({ articleId: article.id })}
                className="hover:underline"
              >
                {article.approvedRevision?.title}
              </Link>
            </div>
            <div className="text-2xs text-pretext-light-gray">
              <HighlightContent
                content={article.approvedRevision?.description || ""}
                truncationLimit={150}
                lineLimit={3}
              />
            </div>
            <div className="text-2xs text-pretext-light-gray">
              {formatDate(article.createdAt, "MMM d, yyyy")}
            </div>
            <Link
              to={articlePath({ articleId: article.id })}
              className={cn(
                "text-sm tracking-wide font-semibold hover:underline text-primary",
                ccls({
                  [CommunitySlug.Gotomillions]: "text-highlight",
                  default: "",
                })
              )}
            >
              See More
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export const DEEP_DIVE_QUERY_DOCUMENT = gql(`
  query DeepDiveArticles($collaborators: [ID!], $sort: ArticleSortTypeEnum) {
    articles(collaborators: $collaborators, contentTypes: [DEEP_DIVE], sort: CREATED_AT_DESC, first: 1) {
      edges {
        node {
          ...DeepDiveArticle
        }
      }
    }
  }
`)

gql(`
  fragment DeepDiveArticle on Article {
    id
    createdAt
    markedLiveAt
    collaborators {
      id
      user {
        ...User_Avatar
      }
    }
    approvedRevision {
      id
      title
      description
    }
  }
`)
