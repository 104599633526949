import { cn } from "~/lib/utils"
import VideoAddIcon from "~/images/icons/video-add.svg?react"
import { Lock } from "lucide-react"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import {
  PRO_EVENTS_LIST_QUERY_DOCUMENT,
  PRO_EVENT_FIXED_TIMEZONE,
} from "~/pro-events/pro-events"
import { useQuery } from "@apollo/client"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { formatInTimeZone } from "date-fns-tz"
import { Button, LinkButton } from "~/ui/button"
import { useCallback, useMemo, useState } from "react"
import { Card, CardHeader } from "~/ui/card"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { UserName } from "~/directory/UserName"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const ProEventsBanner = () => {
  const currentUser = useCurrentUser()
  const canViewProEvents = currentUser.permissions.canViewProEvents
  const { openSubscriptionWizard } = useSubscription()
  const { logEvent } = useLogEvent()

  const { data, loading, error } = useQuery(PRO_EVENTS_LIST_QUERY_DOCUMENT, {
    variables: {
      limit: 10,
    },
  })

  const proEvents = useMemo(
    () => data?.proEvents?.edges.map((e) => e.node) || [],
    [data]
  )

  const [index, setIndex] = useState(0)

  const onButtonClicked = useCallback(() => {
    logEvent(AhoyEventTypeEnum.ProEventInterestClicked, {
      pro_event_id: proEvents[index].id,
    })

    if (!canViewProEvents) {
      openSubscriptionWizard("PricingTableStep", {
        source: "ProEventsBanner",
        requiredFeature: "canViewProEvents",
        contentId: proEvents[index].id,
      })
    }
  }, [canViewProEvents, index, logEvent, openSubscriptionWizard, proEvents])

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading pro events." />
  if (proEvents.length === 0) return null

  const proEvent = proEvents[index]

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold">Pro Events</h2>
          {!canViewProEvents && (
            <div className="text-xs flex items-center gap-2">
              <Lock className="w-4 h-4" />
              Pro Membership Required
            </div>
          )}
        </div>
      </CardHeader>
      <div className="flex flex-col p-6 gap-4">
        <div className="flex gap-6 items-stretch">
          <Card>
            <div className="flex flex-col items-start gap-4 p-6 w-full">
              <h3 className="text-xl font-medium">{proEvent.title}</h3>
              <div className="text-sm font-semibold flex items-center gap-2">
                <AvatarWithFallback
                  user={proEvent.host}
                  size="md"
                  className=""
                />
                <UserName user={proEvent.host} />
              </div>
              <p className="text-xs text-black">{proEvent.description}</p>
              <div className="text-sm font-semibold flex items-center gap-2">
                <VideoAddIcon className="w-4 h-4" />
                {formatInTimeZone(
                  proEvent.date,
                  PRO_EVENT_FIXED_TIMEZONE,
                  "M/dd/yyyy zzz"
                )}
              </div>
              <div className="">
                {canViewProEvents && proEvent.ctaUrl && (
                  <LinkButton
                    to={proEvent.ctaUrl}
                    onClick={onButtonClicked}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {proEvent.ctaCopy}
                  </LinkButton>
                )}
                {!canViewProEvents && (
                  <Button
                    onClick={onButtonClicked}
                    type="button"
                    className="flex gap-2 items-center"
                  >
                    {proEvent.ctaCopy}
                    <Lock className="w-4 h-4" />
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </div>
        {proEvents.length > 1 && (
          <div className="flex items-center gap-2 justify-center">
            {proEvents.map((proEvent, idx) => (
              <button
                key={proEvent.id}
                onClick={() => setIndex(idx)}
                className="p-2"
              >
                <div
                  className={cn(
                    "rounded-full h-2 w-2",
                    idx === index ? "bg-dodger-blue" : "bg-dodger-blue/30"
                  )}
                ></div>
              </button>
            ))}
          </div>
        )}
      </div>
    </Card>
  )
}
