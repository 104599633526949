import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { RulesEngineEventTypeEnum } from "~/__generated__/graphql"
import { feedPath } from "~/common/paths"
import { Battery } from "~/components/Battery"
import { useRulesEngine } from "~/rules-engine/RulesEngineContext"
import { Card, CardContent } from "~/ui/card"
import { H1, H3, H6, Muted } from "~/ui/typography"
import Brain from "~/images/icons/brain.svg?react"
import { cn } from "~/lib/utils"
import { dialogContentVariants } from "~/ui/dialog"
import ReplyAnimation from "~/images/bot-battery-reply.gif"
import { Button } from "~/ui/button"
import { useCommunity } from "~/community/useCommunity"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"

const intentEvents = [
  "keydown",
  "click",
  "scroll",
  "wheel",
  "mousemove",
  "touchstart",
]

export const AskTheBotScreen = () => {
  const community = useCommunity()
  const [, setMostRecentActivity] = useState<number>(Date.now())
  const [mostRecentEventFiredAt, setMostRecentEventFiredAt] = useState<number>(
    Date.now()
  )
  const isFiringRef = useRef<boolean>(false)
  const { createRulesEngineEvent } = useRulesEngine()
  const currentUser = useCurrentUser()
  const { t } = useTranslation("bot")
  const { logEvent } = useLogEvent()
  const hasLoggedEvent = useRef(false)

  const onUserActivity = useCallback(() => {
    setMostRecentActivity((mostRecentActivity) => {
      if (
        mostRecentEventFiredAt < mostRecentActivity - 10000 &&
        !isFiringRef.current &&
        currentUser.botBatteryBalance > 0
      ) {
        isFiringRef.current = true
        createRulesEngineEvent(RulesEngineEventTypeEnum.UserIsActiveOnBot)
        setMostRecentEventFiredAt(Date.now())
      }
      return Date.now()
    })
  }, [
    setMostRecentActivity,
    createRulesEngineEvent,
    setMostRecentEventFiredAt,
    mostRecentEventFiredAt,
    currentUser.botBatteryBalance,
  ])

  useEffect(() => {
    isFiringRef.current = false
  }, [mostRecentEventFiredAt])

  useEffect(() => {
    intentEvents.forEach((event) => {
      window.addEventListener(event, onUserActivity)
    })

    return () => {
      intentEvents.forEach((event) => {
        window.removeEventListener(event, onUserActivity)
      })
    }
  }, [onUserActivity])

  const navigate = useNavigate()
  const hasNoBattery = currentUser.botBatteryBalance <= 0

  useEffect(() => {
    if (hasNoBattery) {
      logEvent(AhoyEventTypeEnum.LowBatteryCtaViewed, {}, hasLoggedEvent)
    }
  }, [hasNoBattery, logEvent])

  if (!community?.delphiEmbedUrl) return null

  const onCtaClick = () => {
    logEvent(AhoyEventTypeEnum.LowBatteryCtaClicked)
    navigate(feedPath.pattern)
  }

  return (
    <div className="flex flex-col gap-4">
      <Card>
        <CardContent className="p-0">
          <div className="flex items-center divide-x">
            <div className="flex items-center gap-4 flex-grow px-8">
              <div>
                <Brain className="w-10 h-10" />
              </div>
              <div className="flex flex-col gap-1">
                <H1 variant="h3" className="text-2xl">
                  {t("headerTitle")}
                </H1>
                <Muted>{t("headerSubtitle")}</Muted>
              </div>
            </div>
            <div className="flex items-center gap-4 p-8">
              <Battery
                level={currentUser.botBatteryBalance}
                className="w-8 h-8"
              />
              <div className="text-2xl">
                {Math.ceil(currentUser.botBatteryBalance)}%
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="rounded-2xl overflow-hidden h-[810px] relative">
        {hasNoBattery && (
          <>
            <div className="bg-black/70 absolute top-0 left-0 w-full h-full z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"></div>
            <div
              className={cn(
                dialogContentVariants({ variant: "card" }),
                "absolute max-w-lg text-center"
              )}
            >
              <H3 className="flex flex-row gap-3 items-center text-center mx-auto">
                {t("rechargeTitle")}
                <Battery level={0} className="w-8 h-8" />
              </H3>
              <div className="rounded-lg border border-mercury p-6 flex flex-col gap-2">
                <H6>{t("rechargeSubtitle")}</H6>
                <div>{t("rechargeMessage")}</div>
                <img
                  src={ReplyAnimation}
                  alt="Reply to recharge"
                  className="w-full"
                />
                <div>
                  <Button onClick={onCtaClick}>{t("rechargeCta")}</Button>
                </div>
              </div>
            </div>
          </>
        )}
        <iframe
          className={cn(hasNoBattery && "blur")}
          title={t("iframeTitle")}
          src={community.delphiEmbedUrl}
          width="100%"
          height="810px"
          allow="microphone"
        ></iframe>
      </div>
    </div>
  )
}
