import { gql } from "~/__generated__"
import { AllMembersTable } from "~/admin/users/AllMembersTable"
import { InvitationsTable } from "~/admin/users/InvitationsTable"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/ui/tabs"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { adminMembersPath } from "~/common/paths"
import { PastMembersTable } from "~/admin/users/PastMembersTable"
import { Users } from "lucide-react"
import { Button } from "~/ui/button"
import { InviteMemberModal } from "~/admin/users/InviteMemberModal"
import { AdminHeader } from "~/admin/AdminHeader"

export const AdminMembersScreen = () => {
  const hasAdminMemberInvites = true
  const { activeTab: activeTabFromParams } = useParams()
  const [activeTab, setActiveTab] = useState(
    activeTabFromParams || "applications"
  )
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (activeTab !== activeTabFromParams) {
      setActiveTab(activeTabFromParams || "applications")
    }
  }, [activeTab, activeTabFromParams, navigate])

  const onTabChange = (tab: string) => {
    setSearchParams({})
    setActiveTab(tab)
    navigate(adminMembersPath({ "activeTab?": tab }))
  }

  const title = useMemo(() => {
    switch (activeTab) {
      case "pre-approved":
        return "Pre-Approved"
      case "past":
        return "Past Members"
      case "activity":
        return "Activity"
      default:
        return "Members"
    }
  }, [activeTab])

  return (
    <>
      <AdminHeader title={title} Icon={Users}>
        {hasAdminMemberInvites && activeTab === "pre-approved" && (
          <Button size="xl" onClick={() => setIsInviteModalOpen(true)}>
            Add Pre-Approved User
          </Button>
        )}
      </AdminHeader>

      <InviteMemberModal
        isOpen={isInviteModalOpen}
        setIsOpen={setIsInviteModalOpen}
      />
      <Tabs value={activeTab} onValueChange={onTabChange}>
        <TabsList className="justify-start mb-4 text-foreground">
          <TabsTrigger value="all">All Members</TabsTrigger>
          {hasAdminMemberInvites && (
            <TabsTrigger value="pre-approved">Pre-Approved</TabsTrigger>
          )}
          <TabsTrigger value="past">Past Members</TabsTrigger>
        </TabsList>
        <TabsContent value="all">
          <AllMembersTable />
        </TabsContent>
        <TabsContent value="pre-approved">
          <InvitationsTable refetchState={isInviteModalOpen} />
        </TabsContent>
        <TabsContent value="past">
          <PastMembersTable />
        </TabsContent>
      </Tabs>
    </>
  )
}

gql(`
  fragment User_Admin on User {
    ...User_Card
    email
    activeStripeSubscription {
      id
      status
      plan {
        interval
        intervalCount
      }
    }
    active
    hidden
    createdAt
    fitApplicationSubmittedAt
    reversedFitDecisionAt
    fit
    onboardedAt

    fitProfile {
      id
      yearsOfExperience
      industry
      linkedinProfileUrl
      companySize
      vertical
      jobFunction
    }

    tier {
      id
      name
      level
    }

    previousTier {
      id
      name
      level
    }

    upcomingTier {
      id
      name
      level
    }
  }
`)

export const USERS_QUERY_DOCUMENT = gql(`
  query AdminUsers(
    $first: Int,
    $after: String,
    $sort: UserSortEnum,
    $searchTerm: String,
    $unreviewedOnly: Boolean,
    $unfitOnly: Boolean,
    $fitOnly: Boolean,
    $placeId: ID,
    $expertiseIds: [ID!],
    $interestIds: [ID!],
    $subscriptionStatus: StripeSubscriptionStatusEnum,
    $onboardedAfter: ISO8601DateTime,
    $tierId: ID,
    $companySize: String,
    $applicationState: String,
    $activeOnly: Boolean,
    $inactiveOnly: Boolean,
    $roles: [UserRoleEnum!]
    $onboardingStates: [OnboardingStateEnum!]
    $industries: [IndustryEnum!]
    $verticals: [VerticalEnum!]
    $jobFunctions: [JobFunctionEnum!]
    $yearsOfExperience: [String!]
    $rulesEngineConditions: JSON
    $minimumTierLevel: TierLevelEnum
    $maximumTierLevel: TierLevelEnum
  ) {
    users(
      first: $first,
      after: $after,
      sort: $sort,
      query: $searchTerm,
      unreviewedOnly: $unreviewedOnly,
      unfitOnly: $unfitOnly,
      fitOnly: $fitOnly,
      placeId: $placeId,
      expertise: $expertiseIds,
      interests: $interestIds,
      subscriptionStatus: $subscriptionStatus,
      onboardedAfter: $onboardedAfter,
      tierId: $tierId,
      companySize: $companySize,
      applicationState: $applicationState,
      activeOnly: $activeOnly,
      inactiveOnly: $inactiveOnly,
      roles: $roles,
      onboardingStates: $onboardingStates,
      industries: $industries,
      verticals: $verticals,
      jobFunctions: $jobFunctions,
      yearsOfExperience: $yearsOfExperience,
      rulesEngineConditions: $rulesEngineConditions,
      minimumTierLevel: $minimumTierLevel,
      maximumTierLevel: $maximumTierLevel
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...User_Admin
        }
      }
    }
  }
`)

export const USER_ADMIN_UPDATE_MUTATION = gql(`
  mutation UserAdminUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...User_Admin
      }
    }
  }
`)
