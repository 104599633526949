import { useState, Dispatch, SetStateAction } from "react"
import { cn } from "~/lib/utils"
import { useFormContext, Controller } from "react-hook-form"
import { useCurrentUser } from "~/common/GlobalsProvider"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { SingleValue } from "react-select"
import { FieldPointer, OnboardingFieldLabel } from "~/ui/Onboarding"
import { useTranslation } from "react-i18next"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { useConfig } from "~/common/ConfigProvider"
import { Alert } from "~/ui/alert"
import { CircleAlert } from "lucide-react"

type PlaceValueType = {
  label: string
  value: google.maps.places.AutocompletePrediction | string
}

export const OnboardingDetailsFields = ({
  setCardLocation,
}: {
  setCardLocation?: Dispatch<SetStateAction<string | null>>
}) => {
  const ccls = useCommunityClassname()
  const {
    control,
    clearErrors,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const currentUser = useCurrentUser()
  const { mapsApiKey } = useConfig()
  const { t } = useTranslation("onboarding")

  const community = useCommunity()

  // Jumping through some real hoops to avoid uncontrolled form errors
  // for no explicable reason
  const [placeValue, setPlaceValue] = useState<PlaceValueType>({
    value: currentUser.place?.id || "",
    label: currentUser.place?.full || "",
  })

  const onChangePlace = (option: SingleValue<PlaceValueType>) => {
    if (option) {
      if (typeof option.value == "string") {
        setValue("placeId", option.value, { shouldDirty: true })
        setPlaceValue(option)
      } else {
        setValue("placeId", option.value.place_id, { shouldDirty: true })
        setPlaceValue({ value: option.value.place_id, label: option.label })
        if (setCardLocation) {
          if (option.label.endsWith(", USA")) {
            setCardLocation(option.label.slice(0, -5))
          } else {
            setCardLocation(option.label.split(",").slice(-1)[0].trim())
          }
        }
      }
      clearErrors("placeId")
    } else {
      setValue("placeId", null, { shouldDirty: true })
      if (setCardLocation) {
        setCardLocation(null)
      }
      setPlaceValue({ value: "", label: "" })
    }
  }

  return (
    <div className="grid gap-x-5 gap-y-4 grid-cols-2">
      {!!Object.keys(errors).length && (
        <Alert variant="destructive" className="col-span-2">
          <CircleAlert className="w-4 h-4" />
          <div>
            Please correct the following:
            <div className="font-normal text-xs">
              {Object.values(errors)
                .map((fieldError) => fieldError?.message)
                .join(", ")}
              .
            </div>
          </div>
        </Alert>
      )}
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          First Name
          <span className="text-highlight">*</span>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn(
            "onboarding-field__input text-dark-gray",
            {
              "has-error": !!errors.firstName,
            },
            ccls({
              [CommunitySlug.Gotomillions]: "border-primary",
              default: "",
            })
          )}
          {...register("firstName", {
            required: "First Name is required",
          })}
        />
      </label>
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          Last Name
          <span className="text-highlight">*</span>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn(
            "onboarding-field__input text-dark-gray",
            {
              "has-error": !!errors.lastName,
            },
            ccls({
              [CommunitySlug.Gotomillions]: "border-primary",
              default: "",
            })
          )}
          {...register("lastName", {
            required: "Last Name is required",
          })}
        />
      </label>
      <label className="onboarding-field col-span-2">
        <OnboardingFieldLabel className="flex justify-between items-end">
          <div className="whitespace-nowrap mr-8">Email Address</div>
          <div className="text-[12px] font-normal text-right">
            *You can contact us if you'd like a different email associated with
            your membership
          </div>
        </OnboardingFieldLabel>
        <input
          className="onboarding-field__input text-dark-gray"
          disabled={true}
          value={currentUser.email || ""}
        />
      </label>
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          <FieldPointer show={!watch("companyName")}>
            Company
            <span className="text-highlight">*</span>
          </FieldPointer>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn("onboarding-field__input text-dark-gray", {
            "has-error": !!errors.companyName,
            "border-highlight bg-card-highlight-background":
              !watch("companyName"),
            "border-primary":
              watch("companyName") &&
              community.slug === CommunitySlug.Gotomillions,
          })}
          {...register("companyName", {
            required: "Company is required",
          })}
        />
      </label>
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          <FieldPointer show={!watch("jobTitle")}>
            Title
            <span className="text-highlight">*</span>
          </FieldPointer>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn("onboarding-field__input text-dark-gray", {
            "has-error": !!errors.jobTitle,
            "border-highlight bg-card-highlight-background": !watch("jobTitle"),
            "border-primary":
              watch("jobTitle") &&
              community.slug === CommunitySlug.Gotomillions,
          })}
          {...register("jobTitle", {
            required: "Title is required",
          })}
        />
      </label>
      <label className="onboarding-field col-span-2">
        <OnboardingFieldLabel>
          <FieldPointer show={!watch("placeId")}>
            {t("homeBaseLabel")}
            <span className="text-highlight">*</span>
          </FieldPointer>
        </OnboardingFieldLabel>
        <Controller
          name="placeId"
          rules={{
            required: "Homebase is required",
          }}
          control={control}
          render={({ field, fieldState }) => (
            <GooglePlacesAutocomplete
              apiKey={mapsApiKey}
              autocompletionRequest={{
                types: ["(cities)"],
              }}
              selectProps={{
                ...field,
                value: placeValue,
                onChange: onChangePlace,
                isClearable: !!placeValue.value,
                classNames: {
                  container: () => "text-dark-gray",
                  control: () =>
                    cn("onboarding-select__control text-dark-gray", {
                      "has-error": !!fieldState.error,
                      "!border-highlight !bg-card-highlight-background":
                        !watch("placeId"),
                      "!border-primary":
                        watch("placeId") &&
                        community.slug === CommunitySlug.Gotomillions,
                    }),
                },
                components: {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                },
                placeholder: "",
              }}
            />
          )}
        />
      </label>
    </div>
  )
}
