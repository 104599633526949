import { Room, User } from "~/__generated__/graphql"
import { UserName } from "~/directory/UserName"

interface RoomNameInterface extends Pick<Room, "name" | "defaultName"> {
  dmOtherUser?: Pick<
    User,
    "id" | "firstName" | "lastName" | "name" | "admin" | "coach"
  > | null
}

export const roomDisplayName = (room: RoomNameInterface) => {
  if (room.dmOtherUser) {
    return <UserName user={room.dmOtherUser} />
  } else if (room.name && room.name.length > 0) {
    return room.name
  } else {
    return room.defaultName
  }
}
