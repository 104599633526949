import { useQuery } from "@apollo/client"
import { BeakerIcon } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Badge } from "~/ui/badge"
import { adminExperimentPath } from "~/common/paths"
import { LinkButton } from "~/ui/button"
import { Card } from "~/ui/card"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { gql } from "~/__generated__"

export const AdminExperimentsScreen = () => {
  const { data, loading, error } = useQuery(EXPERIMENTS_LIST_QUERY)

  const experiments = data?.experiments || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading experiments." />

  return (
    <>
      <AdminHeader title="Experiments" Icon={BeakerIcon} />

      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Variants</TableHead>
              <TableHead>Total Users</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {experiments.map((experiment) => (
              <TableRow key={experiment.name}>
                <TableCell>{experiment.name}</TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-1">
                    {experiment.variants.map((variant, index) => (
                      <Badge
                        key={variant}
                        variant="secondary"
                        className="text-3xs"
                      >
                        {variant} {experiment.weights[index]}%
                      </Badge>
                    ))}
                  </div>
                </TableCell>
                <TableCell>{experiment.totalUsers}</TableCell>
                <TableCell>
                  <div className="flex gap-2 justify-end">
                    <LinkButton
                      variant="filled"
                      size="sm"
                      to={adminExperimentPath({
                        experimentName: experiment.name,
                      })}
                    >
                      View Users
                    </LinkButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

const EXPERIMENTS_LIST_QUERY = gql(`
  query Experiments {
    experiments {
      name
      variants,
      weights,
      totalUsers
    }
  }
`)
