import { useQuery } from "@apollo/client"
import { ChevronLeft, ChevronRight, Hash, Lock } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  ChannelCreateInput,
  ChannelVisibilityEnum,
} from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { ChannelForm } from "~/admin/ChannelForm"
import { useChannels } from "~/common/ChannelsProvider"
import { adminChannelsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { UserName } from "~/directory/UserName"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { IconButton } from "~/ui/IconButton"
import { H6 } from "~/ui/typography"

export const AdminChannelEditScreen = () => {
  const { channelId } = useParams()
  const { channels } = useChannels()
  const [runUpdateChannel] = useSafeMutation(UPDATE_CHANNEL_MUTATION)
  const navigate = useNavigate()
  const [usersAfter, setUsersAfter] = useState<string | undefined | null>(null)
  const [usersBefore, setUsersBefore] = useState<string | undefined | null>(
    null
  )
  const { data } = useQuery(CHANNEL_QUERY_DOCUMENT, {
    variables: { channelId: channelId!, usersAfter, usersBefore },
  })
  const users = useMemo(
    () => data?.channel.activeUsers.edges.map((e) => e.node),
    [data]
  )
  const hasNextPage = useMemo(
    () => data?.channel.activeUsers.pageInfo.hasNextPage,
    [data]
  )
  const hasPreviousPage = useMemo(
    () => data?.channel.activeUsers.pageInfo.hasPreviousPage,
    [data]
  )
  const startCursor = useMemo(
    () => data?.channel.activeUsers.pageInfo.startCursor,
    [data]
  )
  const endCursor = useMemo(
    () => data?.channel.activeUsers.pageInfo.endCursor,
    [data]
  )

  const channel = useMemo(() => {
    return channels.find((channel) => channel.id === channelId)
  }, [channels, channelId])

  const handleSubmit = async (data: ChannelCreateInput) => {
    const response = await runUpdateChannel({
      variables: {
        input: {
          channelId: channelId!,
          removeImage: !data.imageId,
          ...data,
        },
      },
    })

    const { data: responseData, errors } = response

    if (errors) {
      displayErrors(errors)
    } else if (responseData?.channelUpdate?.channel) {
      toast.success("Channel updated.")
      navigate(adminChannelsPath.pattern)
    }

    return response
  }

  useEffect(() => {
    if (!channel) {
      navigate(adminChannelsPath.pattern)
    }
  }, [channel, navigate])

  const initialValues = useMemo(() => {
    if (!channel) return null

    return {
      id: channel.id,
      slug: channel.slug,
      name: channel.name,
      active: channel.active,
      imageId: channel.imageId || undefined,
      imageUrl: channel.imageUrl || undefined,
      description: channel.description || "",
      modalHeader: channel.modalHeader || "",
      modalSubheader: channel.modalSubheader || "",
      groupId: channel.groups?.[0]?.id || undefined,
    }
  }, [channel])

  const nextPage = () => {
    setUsersBefore(null)
    setUsersAfter(endCursor)
  }

  const prevPage = () => {
    setUsersAfter(null)
    setUsersBefore(startCursor)
  }

  if (!channel) return null

  return (
    <>
      <AdminHeader
        title={
          channel.visibility === ChannelVisibilityEnum.Public
            ? "Edit Public Channel"
            : "Edit Private Channel"
        }
        Icon={channel.visibility === ChannelVisibilityEnum.Public ? Hash : Lock}
      />

      <div className="flex gap-8">
        <div className="flex-1">
          <ChannelForm
            onSubmit={handleSubmit}
            initialValues={initialValues!}
            visibility={channel.visibility}
          />
        </div>
        {channel.visibility === ChannelVisibilityEnum.Private && (
          <div className="w-96 flex flex-col divide-y gap-2">
            <div className="flex items-center justify-between">
              <H6 className="text-2xs">Channel Members</H6>
              <div className="text-2xs">
                {channel.activeUsersCount} of {channel.totalUsersCount}
              </div>
            </div>
            <div className="flex flex-col gap-2 py-2">
              {users?.map((user) => (
                <div key={user.id} className="flex items-center gap-2">
                  <AvatarWithFallback user={user} />
                  <div>
                    <UserName user={user} />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end">
              <IconButton onClick={prevPage} disabled={!hasPreviousPage}>
                <ChevronLeft className="w-4 h-4" />
              </IconButton>
              <IconButton onClick={nextPage} disabled={!hasNextPage}>
                <ChevronRight className="w-4 h-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const CHANNEL_QUERY_DOCUMENT = gql(`
  query Channel($channelId: ID!, $usersAfter: String, $usersBefore: String) {
    channel(channelId: $channelId) {
      ...Channel_Display

      activeUsers(first: 10, after: $usersAfter, before: $usersBefore) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...User_Admin
          }
        }
      }
    }
  }
`)

export const UPDATE_CHANNEL_MUTATION = gql(`
  mutation ChannelUpdate($input: ChannelUpdateInput!) {
    channelUpdate(input: $input) {
      channel {
        ...Channel_Display
      }
    }
  }
`)
