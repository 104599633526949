import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { feedPath } from "~/common/paths"

export const RequireUserInactive = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser.active && currentUser.fit !== false) {
      navigate(feedPath.pattern)
    }
  }, [navigate, currentUser])

  if (currentUser.active && currentUser.fit !== false) {
    return null
  }

  return <Outlet />
}
