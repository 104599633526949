import { Dialog, DialogContent } from "~/ui/dialog"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Input } from "~/ui/input"
import { Button } from "~/ui/button"
import { Textarea } from "~/ui/textarea"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import {
  ArticleContentTypeEnum,
  AhoyEventTypeEnum,
  CommunitySlug,
} from "~/__generated__/graphql"
import { ArticleDetailsModalHelpers } from "./useArticleDetailsModal"
import { useEffect } from "react"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { useTranslation } from "~/common/Translation"

const articleFormSchema = z.object({
  contentType: z.nativeEnum(ArticleContentTypeEnum, {
    required_error: "Select a type",
  }),
  tagId: z.string({
    required_error: "Select a topic",
  }),
  title: z.string().min(1, {
    message: "Title is required",
  }),
  description: z.string().min(1, {
    message: "Description is required",
  }),
})

export type ArticleDetailsFormValues = z.infer<typeof articleFormSchema>

export const ArticleDetailsModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  isLoading,
  initialFormValues,
}: ArticleDetailsModalHelpers) => {
  const { logEvent } = useLogEvent()
  const { data } = useQuery(ARTICLE_MODAL_QUERY_DOCUMENT)
  const isEditing = !!initialFormValues
  const community = useCommunity()
  const ccls = useCommunityClassname()
  const { t: contentTypes } = useTranslation("contentTypes")

  const form = useForm<z.infer<typeof articleFormSchema>>({
    resolver: zodResolver(articleFormSchema),
    defaultValues: initialFormValues || {
      title: "",
      description: "",
      tagId: undefined,
      contentType: undefined,
    },
  })

  useEffect(() => {
    if (isOpen === true && !isEditing) {
      logEvent(AhoyEventTypeEnum.ContentUploadInitiated)
    }

    if (isOpen === false && !isEditing) {
      form.reset()
    }
  }, [isOpen, form, isEditing, logEvent])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center mb-2 font-serif text-3xl">
          {isEditing ? "Edit Content Details" : "Start Creating!"}
        </div>
        <div className="text-center mb-6 text-2xs">
          {isEditing
            ? "Edit the details of your content."
            : "Get started by selecting a topic for your content."}
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex mb-4 gap-4">
              <FormField
                control={form.control}
                name="tagId"
                render={({ field }) => (
                  <FormItem className="w-1/2">
                    <FormLabel required>Select Topic</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger
                          className={cn(
                            ccls({
                              [CommunitySlug.Gotomillions]: "border-primary",
                              default: "",
                            })
                          )}
                        >
                          <SelectValue placeholder="Select a topic..." />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {data?.tags.nodes.map((tag) => (
                          <SelectItem value={tag.id} key={tag.id}>
                            {tag.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="contentType"
                render={({ field }) => (
                  <FormItem className="w-1/2">
                    <FormLabel required>Select Type</FormLabel>
                    {!!initialFormValues &&
                    (initialFormValues.contentType ===
                      ArticleContentTypeEnum.Lesson ||
                      initialFormValues.contentType ===
                        ArticleContentTypeEnum.Course) ? (
                      <Input disabled value={contentTypes(field.value)} />
                    ) : (
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            className={cn(
                              ccls({
                                [CommunitySlug.Gotomillions]: "border-primary",
                                default: "",
                              })
                            )}
                          >
                            <SelectValue placeholder="Select a type..." />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {community.contentTypes.map((type) => (
                            <SelectItem value={type} key={type}>
                              {contentTypes(type)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel required>Title</FormLabel>
                  <FormControl>
                    <Input
                      className={cn(
                        "bg-white",
                        ccls({
                          [CommunitySlug.Gotomillions]: "border-primary",
                          default: "",
                        })
                      )}
                      placeholder="Content Title..."
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel required>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      className={cn(
                        "bg-white",
                        ccls({
                          [CommunitySlug.Gotomillions]: "border-primary",
                          default: "",
                        })
                      )}
                      placeholder="Enter a description of the content"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="h-[1px] bg-gray-300 my-8" />

            <div className="flex justify-end">
              <Button
                type="submit"
                className="px-10"
                disabled={isLoading}
                isLoading={isLoading}
              >
                {isEditing ? "Submit" : "Continue"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export const ARTICLE_MODAL_QUERY_DOCUMENT = gql(`
  query ArticleModal {
    tags {
      nodes {
        id
        name
      }
    }
  }
`)
