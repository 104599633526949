import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { cva } from "class-variance-authority"
import { CheckCircleIcon, GripVertical } from "lucide-react"
import { Button } from "~/ui/button"
import {
  DotsHorizontalIcon,
  ExclamationTriangleIcon,
  EyeNoneIcon,
} from "@radix-ui/react-icons"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import {
  SavedOrUnsavedLesson,
  articleCollaboratorId,
  isSavedLesson,
  getArticleId,
  SavedOrUnsavedArticle,
  isSavedArticle,
  lessonId as getLessonId,
  generateTempId,
  withoutId,
  sectionId,
} from "~/types"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { useCallback, useMemo, useRef, useState } from "react"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { editArticlePath } from "~/common/paths"
import toast from "react-hot-toast"
import { cn } from "~/lib/utils"
import { ArticleCollaboratorsModal } from "~/articles/ArticleCollaboratorsModal"
import { useConfirm } from "~/ui/Confirm"
import { useCourseEditorContext } from "./CourseEditor"
import { useNavigate } from "react-router-dom"

interface LessonProps {
  lesson: SavedOrUnsavedLesson
  isOverlay?: boolean
  onUpdateLesson: (lesson: SavedOrUnsavedLesson) => void
}

export type LessonType = "Lesson"

export interface LessonDragData {
  type: LessonType
  lesson: SavedOrUnsavedLesson
}

const lessonHasChanges = (
  lesson: SavedOrUnsavedLesson,
  initialLesson: SavedOrUnsavedLesson | null
) => {
  return (
    !isSavedLesson(lesson) ||
    lesson.sectionId !== initialLesson?.sectionId ||
    lesson.hidden !== initialLesson?.hidden ||
    lesson.article.collaborators
      ?.map((c) => articleCollaboratorId(c))
      .join(",") !==
      initialLesson?.article.collaborators
        ?.map((c) => articleCollaboratorId(c))
        .join(",")
  )
}

export function Lesson({ lesson, isOverlay, onUpdateLesson }: LessonProps) {
  const contextMenuTriggerRef = useRef<HTMLDivElement>(null)
  const { course, setSections } = useCourseEditorContext()
  const initialLesson = useMemo(() => {
    return (
      course.sections
        .flatMap((s) => s.lessons)
        .find((l) => getLessonId(l) === getLessonId(lesson)) || null
    )
  }, [course, lesson])
  const [collaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false)
  const lessonId = useMemo(
    () => (isSavedLesson(lesson) ? lesson.id : lesson.tempId),
    [lesson]
  )
  const navigate = useNavigate()
  const showConfirm = useConfirm()

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: lessonId,
    data: {
      type: "Lesson",
      lesson,
    } satisfies LessonDragData,
    attributes: {
      roleDescription: "Lesson",
    },
  })

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  }

  const variants = cva(
    "rounded-full hover:bg-pretext-light-gray/10 p-2 flex gap-2 items-center",
    {
      variants: {
        dragging: {
          over: "ring-2 opacity-30",
          overlay: "ring-2 ring-primary",
        },
        visibility: {
          visible: "",
          hidden: "border-dashed border-2 border-gray-300",
        },
      },
    }
  )

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const editLesson = () => {
    if (!isSavedLesson(lesson)) {
      toast.error("Cannot edit unsaved lesson")
      return
    }

    navigate(editArticlePath({ articleId: getArticleId(lesson.article) }))
  }

  const toggleHidden = useCallback(() => {
    showConfirm({
      title: lesson.hidden ? "Un-hide Lesson" : "Hide Lesson",
      body: `Are you sure you want to ${
        lesson.hidden ? "un-hide" : "hide"
      } this lesson?`,
      onConfirm: () => {
        onUpdateLesson({
          ...lesson,
          hidden: !lesson.hidden,
        } as SavedOrUnsavedLesson)
      },
    })
  }, [lesson, onUpdateLesson, showConfirm])

  const duplicate = useCallback(() => {
    showConfirm({
      title: "Duplicate Lesson",
      body: "Are you sure you want to duplicate this lesson?",
      onConfirm: () => {
        setSections((sections) => {
          const duplicatedLesson = {
            ...withoutId(lesson),
            tempId: generateTempId("lesson"),
            article: {
              ...withoutId(lesson.article),
              tempId: generateTempId("article"),
              revision: {
                ...withoutId(lesson.article.revision),
                title: `${lesson.article.revision.title} (Copy)`,
                tempId: generateTempId("revision"),
              },
              collaborators:
                lesson.article.collaborators?.map((collaborator) => ({
                  ...withoutId(collaborator),
                  tempId: generateTempId("collaborator"),
                })) || [],
            },
          }

          return sections.map((section) => {
            if (sectionId(section) !== lesson.sectionId) {
              return section
            }

            return {
              ...section,
              lessons: [...section.lessons, duplicatedLesson],
            }
          })
        })
      },
    })
  }, [lesson, setSections, showConfirm])

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        className={variants({
          dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
          visibility: lesson.hidden ? "hidden" : "visible",
        })}
      >
        <div>
          <Button
            variant={"ghost"}
            {...attributes}
            {...listeners}
            className="cursor-grab"
          >
            <span className="sr-only">Move lesson</span>
            <GripVertical className="w-[12px] h-[12px]" />
          </Button>
        </div>

        <ContextMenu>
          <ContextMenuContent>
            <ContextMenuItem
              onClick={editLesson}
              className={cn(
                isSavedLesson(lesson)
                  ? "cursor-pointer"
                  : "cursor-not-allowed text-gray-400 hover:text-gray-400 hover:bg-transparent focus:text-gray-400 focus:bg-transparent"
              )}
            >
              {isSavedLesson(lesson) ? (
                "Edit Lesson"
              ) : (
                <>
                  <SimpleTooltip content="Lesson is not saved">
                    <div>Edit Lesson</div>
                  </SimpleTooltip>
                </>
              )}
            </ContextMenuItem>
            <ContextMenuItem
              className="cursor-pointer"
              onClick={() => setCollaboratorsModalOpen(true)}
            >
              Add Collaborator(s)
            </ContextMenuItem>
            <ContextMenuItem className="cursor-pointer" onClick={toggleHidden}>
              {lesson.hidden ? "Un-hide" : "Hide"} Lesson
            </ContextMenuItem>
            <ContextMenuItem className="cursor-pointer" onClick={duplicate}>
              Duplicate Lesson
            </ContextMenuItem>
          </ContextMenuContent>
          <ContextMenuTrigger asChild>
            <div
              className="flex-grow flex gap-2 items-baseline"
              ref={contextMenuTriggerRef}
            >
              <div>{lesson.article.revision.title}</div>
              {lesson.hidden && (
                <SimpleTooltip content="This section is hidden">
                  <EyeNoneIcon className="w-[12px] h-[12px]" />
                </SimpleTooltip>
              )}
              <div>
                {lessonHasChanges(lesson, initialLesson) ? (
                  <SimpleTooltip content="Unsaved changes">
                    <ExclamationTriangleIcon className="w-[12px] h-[12px] text-warning" />
                  </SimpleTooltip>
                ) : (
                  <SimpleTooltip content="All changes saved">
                    <CheckCircleIcon className="w-[12px] h-[12px] text-success" />
                  </SimpleTooltip>
                )}
              </div>
            </div>
          </ContextMenuTrigger>
        </ContextMenu>

        <div className="flex">
          {lesson.article?.collaborators?.map((c) => (
            <AvatarWithFallback key={articleCollaboratorId(c)} user={c.user} />
          ))}
        </div>

        <div>
          <Button variant="ghost" onClick={triggerContextMenu}>
            <DotsHorizontalIcon className="w-[12px] h-[12px]" />
          </Button>
        </div>
      </div>

      {collaboratorsModalOpen && (
        <ArticleCollaboratorsModal
          setIsOpen={setCollaboratorsModalOpen}
          articleId={
            isSavedArticle(lesson.article) ? lesson.article.id : undefined
          }
          collaborators={lesson.article.collaborators}
          onSubmit={(collaborators) => {
            onUpdateLesson({
              ...lesson,
              article: {
                ...lesson.article,
                collaborators,
              } as SavedOrUnsavedArticle,
            } as SavedOrUnsavedLesson)
            setCollaboratorsModalOpen(false)
          }}
        />
      )}
    </>
  )
}
