import { useQuery } from "@apollo/client"
import { MessageSquareCode } from "lucide-react"
import { useCallback, useEffect, useMemo } from "react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  Automation_AdminFragment,
  AutomationCategoryEnum,
  AutomationCreateInput,
  RulesEngineEffectTypeEnum,
} from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { AutoMessageForm } from "~/admin/AutoMessageForm"
import { adminAutoMessagesPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

const defaultTierSelectEntry = { label: "All Tiers", value: "all" }

export const AdminAutoMessagesEditScreen = () => {
  const navigate = useNavigate()
  const { automationId } = useParams()

  const [runUpdateAutomation] = useSafeMutation(AUTOMATION_UPDATE_MUTATION)
  const { data, loading } = useQuery(AUTOMATION_QUERY_DOCUMENT, {
    variables: { id: automationId! },
  })

  const delay = useMemo(() => {
    if (!data?.automation) return { count: 0, unit: "minute" }

    if (data.automation.triggerDelaySeconds % 86400 === 0) {
      return { count: data.automation.triggerDelaySeconds / 86400, unit: "day" }
    }

    if (data.automation.triggerDelaySeconds % 3600 === 0) {
      return { count: data.automation.triggerDelaySeconds / 3600, unit: "hour" }
    }

    return { count: data.automation.triggerDelaySeconds / 60, unit: "minute" }
  }, [data])

  const tierLevel = useMemo(() => {
    if (!data?.automation) return defaultTierSelectEntry.value

    return (
      data.automation.conditions.all.find(
        (c: any) => c.fact === "user.tier.level"
      )?.value || defaultTierSelectEntry.value
    )
  }, [data])

  const postParams = useMemo(() => {
    if (!data?.automation) return {}

    return (
      data.automation.effects.find(
        (e: any) => e.type === RulesEngineEffectTypeEnum.SendDirectMessage
      )?.params.post_params || {}
    )
  }, [data])

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ effects, category, ...input }: AutomationCreateInput) => {
      if (!data?.automation) return
      const effect = data?.automation.effects.find(
        (e: any) => e.type === RulesEngineEffectTypeEnum.SendDirectMessage
      )
      if (!effect) return

      const { errors } = await runUpdateAutomation({
        variables: {
          automationUpdateInput: {
            id: automationId!,
            ...input,
          },
          rulesEngineEffectInput: {
            id: effect.id,
            ...effects![0],
          },
        },
      })

      if (errors) {
        displayErrors(errors)
      } else {
        toast.success("Updated auto message")
        navigate(adminAutoMessagesPath({ "activeTab?": "" }))
      }
    },
    [automationId, runUpdateAutomation, data, navigate]
  )

  useEffect(() => {
    if (
      data?.automation &&
      data.automation.category !== AutomationCategoryEnum.AutoMessage
    ) {
      navigate(adminAutoMessagesPath({ "activeTab?": "" }))
    }
  })

  if (loading || !data?.automation) return null

  return (
    <>
      <AdminHeader
        title="Auto Messages"
        subtitle={`Edit: ${data.automation.name}`}
        Icon={MessageSquareCode}
      />
      <AutoMessageForm
        defaultValues={{
          name: data.automation.name,
          description: data.automation.description || "",
          active: data.automation.active,
          userId:
            data.automation.effects.find(
              (e: Automation_AdminFragment["effects"][0]) =>
                e.type === RulesEngineEffectTypeEnum.SendDirectMessage
            )?.params.from_user_id || "",
          tierLevel,
          triggerEventName: data.automation.triggerEventName,
          delayCount: delay.count,
          delayUnit: delay.unit,
          postParams,
        }}
        onSubmit={onSubmit}
      />
    </>
  )
}

const AUTOMATION_UPDATE_MUTATION = gql(`
  mutation AutomationUpdate($automationUpdateInput: AutomationUpdateInput!, $rulesEngineEffectInput: RulesEngineEffectUpdateInput!) {
    rulesEngineEffectUpdate(input: $rulesEngineEffectInput) {
      rulesEngineEffect {
        ...RulesEngineEffect_Admin
      }
    }

    automationUpdate(input: $automationUpdateInput) {
      automation {
        ...Automation_Admin
      }
    }
  }
`)

const AUTOMATION_QUERY_DOCUMENT = gql(`
  query Automation($id: ID!) {
    automation(id: $id) {
      ...Automation_Admin
    }
  }
`)
