import * as React from "react"
import { RouterProvider } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { router } from "./router"
import colors from "tailwindcss/colors"
import { Worker } from "@react-pdf-viewer/core"

import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import "react-toggle/style.css"

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import { getMetaVar, getMetaVars } from "./common/getMetaVar"
import { FlashMessageToasts } from "./ui/FlashMessageToasts"
import { relayStylePagination } from "@apollo/client/utilities"
import { KeyArgsFunction } from "@apollo/client/cache/inmemory/policies"
import { CURRENT_USER_QUERY_DOCUMENT } from "./auth/currentUserQuery"
import { COMMUNITY_QUERY_DOCUMENT } from "./community/communityQuery"
import invariant from "tiny-invariant"
import {
  CommunityQuery,
  User_CurrentUserFragment,
} from "./__generated__/graphql"
import { initReactI18next } from "react-i18next"
import i18n, { languageResources } from "./common/i18n"
import { Base64 } from "js-base64"
import { Config, ConfigProvider } from "./common/ConfigProvider"
import { GlobalsProvider } from "./common/GlobalsProvider"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

const csrfToken = getMetaVar("csrf-token")
invariant(csrfToken)

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken!,
  },
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const excludePaginationArgs: KeyArgsFunction = (args) => {
  if (!args) return false
  return Object.keys(args).filter((k) => !["first", "after"].includes(k))
}

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          posts: relayStylePagination(excludePaginationArgs),
          users: relayStylePagination(excludePaginationArgs),
          articles: relayStylePagination(excludePaginationArgs),
          notifications: relayStylePagination(excludePaginationArgs),
          bookmarks: relayStylePagination(excludePaginationArgs),
          introductionCycles: relayStylePagination(excludePaginationArgs),
          myRooms: relayStylePagination(excludePaginationArgs),
          events: relayStylePagination(excludePaginationArgs),
          places: relayStylePagination(excludePaginationArgs),
          proEvents: relayStylePagination(excludePaginationArgs),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

const metaConfig = getMetaVars([
  "community",
  "current-user-cache",
  "direct-uploads-url",
  "domain",
  "environment",
  "feature-flags",
  "flash-messages",
  "hris-cost-per-employee-article-id",
  "hris-retention-article-id",
  "hris-tenure-article-id",
  "hris-turnover-article-id",
  "link-metadata-path",
  "maps-api-key",
  "qa-tools-enabled",
  "stripe-publishable-key",
  "track-impressions",
  "unfit_refund_window_in_days",
  "visually-show-impressions",
])

const base64CurrentUserData = metaConfig["current-user-cache"]
invariant(base64CurrentUserData)
const currentUserData = JSON.parse(Base64.decode(base64CurrentUserData))
invariant(currentUserData.data)

apolloClient.writeQuery({
  query: CURRENT_USER_QUERY_DOCUMENT,
  data: currentUserData.data,
  variables: { id: currentUserData.data.user?.id },
})

const communityMetaContent = metaConfig["community"]
const communityData = JSON.parse(communityMetaContent) as {
  data: CommunityQuery
}
invariant(communityData.data.community)
apolloClient.writeQuery({
  query: COMMUNITY_QUERY_DOCUMENT,
  data: communityData.data,
  variables: { id: communityData.data.community!.id },
})

const environment = metaConfig["environment"]

console.log({ metaConfig })

const config: Config = {
  currentUserCache: currentUserData.data.user as User_CurrentUserFragment,
  csrfToken: csrfToken,
  directUploadsUrl: metaConfig["direct-uploads-url"],
  domain: metaConfig["domain"],
  environment: metaConfig["environment"],
  featureFlags: JSON.parse(Base64.decode(metaConfig["feature-flags"])),
  flashMessages: JSON.parse(Base64.decode(metaConfig["flash-messages"])),
  hrisCostPerEmployeeArticleId: metaConfig["hris-cost-per-employee-article-id"],
  hrisRetentionArticleId: metaConfig["hris-retention-article-id"],
  hrisTenureArticleId: metaConfig["hris-tenure-article-id"],
  hrisTurnoverArticleId: metaConfig["hris-turnover-article-id"],
  linkMetadataPath: metaConfig["link-metadata-path"],
  mapsApiKey: metaConfig["maps-api-key"],
  qaToolsEnabled: metaConfig["qa-tools-enabled"] === "true",
  stripePublishableKey: metaConfig["stripe-publishable-key"],
  trackImpressions: metaConfig["track-impressions"] === "true",
  unfitRefundWindowInDays: parseInt(metaConfig["unfit_refund_window_in_days"]),
  visuallyShowImpressions: metaConfig["visually-show-impressions"] === "true",
}

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  debug: environment === "development",
  lng: "en-US",
  resources: {
    "en-US": languageResources[communityData.data.community!.slug],
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ["br", "strong", "em", "i", "p"], // allow <br/> and simple html elements in translations
  },
})

export const App = () => {
  return (
    <React.StrictMode>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <ApolloProvider client={apolloClient}>
          <ConfigProvider config={config}>
            <GlobalsProvider communityId={communityData.data.community!.id}>
              <Toaster
                toastOptions={{
                  duration: 4000,
                  style: {
                    maxWidth: "100%",
                    textAlign: "left",
                  },
                  success: {
                    style: {
                      color: colors.white,
                      backgroundColor: colors.green[700],
                    },
                  },
                  error: {
                    style: {
                      color: colors.white,
                      backgroundColor: colors.red[700],
                    },
                  },
                }}
              />
              <FlashMessageToasts />
              <RouterProvider router={router} />
            </GlobalsProvider>
          </ConfigProvider>
        </ApolloProvider>
      </Worker>
    </React.StrictMode>
  )
}
