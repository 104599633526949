import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/common/GlobalsProvider"
import {
  applicationLandingPath,
  applicationFitWaitingScreenPath,
} from "~/common/paths"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"

export const RequireUserOnboarding = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser(false)

  useEffect(() => {
    if (currentUser) {
      navigate(applicationFitWaitingScreenPath.pattern)
    } else {
      navigate(applicationLandingPath.pattern)
    }
  }, [currentUser, navigate])

  if (!currentUser || currentUser.onboarded) {
    return null
  }

  return (
    <UserDialogContextProvider>
      <Outlet />
    </UserDialogContextProvider>
  )
}
