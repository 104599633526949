import { DotsVerticalIcon } from "@radix-ui/react-icons"
import { formatDate } from "date-fns"
import { ChevronDown, Hash, Lock, MessagesSquare } from "lucide-react"
import { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { useDebounce } from "use-debounce"
import { Channel, ChannelVisibilityEnum } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/ui/button"
import { useConfirm } from "~/ui/Confirm"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { SearchInput } from "~/ui/SearchInput"
import { ChannelsAndGroupsTabs } from "./AdminGroupsScreen"
import { useChannels } from "~/common/ChannelsProvider"
import { AdminTable, AdminTableHeader } from "~/admin/users/UsersTable"
import { TableCell, TableRow } from "~/ui/table"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { Switch } from "~/ui/switch"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import { Muted } from "~/ui/typography"
import { useNavigate } from "react-router-dom"
import { adminChannelEditPath, adminChannelNewPath } from "~/common/paths"
import { UPDATE_CHANNEL_MUTATION } from "./AdminChannelEditScreen"

const HEADERS: AdminTableHeader[] = [
  { label: "Visibility" },
  { label: "Channel Name" },
  { label: "Description" },
  { label: "Members" },
  { label: "Type" },
  { label: "Date Created" },
  { label: "Actions" },
]

export const AdminChannelsScreen = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300)
  const navigate = useNavigate()

  const {
    filteredChannels: channels,
    loadingFilteredChannels: loading,
    setSearchTerm: setChannelSearchTerm,
  } = useChannels()
  useEffect(() => {
    setChannelSearchTerm(debouncedSearchTerm)
  }, [debouncedSearchTerm, setChannelSearchTerm])

  return (
    <>
      <AdminHeader title="Channels & Groups" Icon={MessagesSquare}>
        <Popover>
          <PopoverTrigger asChild>
            <Button>
              Create Channel <ChevronDown size={16} className="ml-2" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0">
            <div className="flex flex-col divide-y">
              <Button
                variant="ghost"
                chrome="none"
                className="p-6"
                onClick={() =>
                  navigate(adminChannelNewPath({ visibility: "public" }))
                }
              >
                <div className="flex flex-col gap-1 whitespace-normal text-left">
                  <div className="flex gap-2 items-center">
                    <Hash size={16} /> Public Channel
                  </div>
                  <Muted>
                    A public channel automatically includes all active community
                    members.
                  </Muted>
                </div>
              </Button>

              <Button
                variant="ghost"
                chrome="none"
                className="p-6"
                onClick={() =>
                  navigate(adminChannelNewPath({ visibility: "private" }))
                }
              >
                <div className="flex flex-col gap-1 whitespace-normal text-left">
                  <div className="flex gap-2 items-center">
                    <Lock size={16} /> Private Channel
                  </div>
                  <Muted>
                    A private channel restricts access based on group
                    membership.
                  </Muted>
                </div>
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </AdminHeader>

      <ChannelsAndGroupsTabs />

      <SearchInput
        className="my-4"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search channels"
      />

      <AdminTable items={channels || []} loading={loading} headers={HEADERS}>
        {(channel) => <ChannelRow key={channel.id} channel={channel} />}
      </AdminTable>
    </>
  )
}

const ChannelRow = ({
  channel,
}: {
  channel: Pick<
    Channel,
    | "id"
    | "name"
    | "description"
    | "activeUsersCount"
    | "totalUsersCount"
    | "active"
    | "createdAt"
    | "visibility"
  >
}) => {
  const [runUpdateChannel] = useSafeMutation(UPDATE_CHANNEL_MUTATION)
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)
  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }
  const showConfirm = useConfirm()

  const toggleActive = () => {
    showConfirm({
      title: channel.active ? "Deactivate Channel" : "Activate Channel",
      body: channel.active
        ? "Deactivating a channel will hide it from community members and make it read-only. Are you sure you want to deactivate this channel?"
        : "Activating a channel will make it visible to community members. Are you sure you want to activate this channel?",
      onConfirm: async () => {
        const { errors } = await runUpdateChannel({
          variables: {
            input: {
              channelId: channel.id!,
              active: !channel.active,
            },
          },
        })

        if (errors) {
          toast.error("Failed to update channel")
          return
        }
      },
    })
  }

  const navigate = useNavigate()
  const edit = () => {
    navigate(adminChannelEditPath({ channelId: channel.id }))
  }

  return (
    <ContextMenu>
      <ContextMenuContent>
        <ContextMenuItem onClick={edit} className="cursor-pointer">
          Edit Channel
        </ContextMenuItem>
        <ContextMenuItem onClick={toggleActive} className="cursor-pointer">
          {channel.active ? "Deactivate Channel" : "Activate Channel"}
        </ContextMenuItem>
      </ContextMenuContent>
      <ContextMenuTrigger asChild>
        <TableRow ref={contextMenuTriggerRef} className="group">
          <TableCell>
            <SimpleTooltip
              content={channel.active ? "Visible" : "Hidden"}
              delayDuration={500}
              cursor="pointer"
            >
              <div className="inline-block">
                <Switch
                  checked={channel.active}
                  onClick={toggleActive}
                  variant="selector"
                  options={["Inactive", "Active"]}
                />
              </div>
            </SimpleTooltip>
          </TableCell>
          <TableCell className="font-bold">{channel.name}</TableCell>
          <TableCell>
            {channel.description ? (
              <div className="truncate max-w-[300px]">
                {channel.description}
              </div>
            ) : (
              <Muted>N/A</Muted>
            )}
          </TableCell>
          <TableCell>
            {channel.activeUsersCount} of {channel.totalUsersCount}
          </TableCell>
          <TableCell>
            {channel.visibility === ChannelVisibilityEnum.Public
              ? "Public"
              : "Private"}
          </TableCell>
          <TableCell>
            {formatDate(new Date(channel.createdAt), "MM/dd/yyyy")}
          </TableCell>
          <TableCell className="text-right">
            <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
              <DotsVerticalIcon />
            </Button>
          </TableCell>
        </TableRow>
      </ContextMenuTrigger>
    </ContextMenu>
  )
}
