import { CommunitySlug, User_CardFragment } from "~/__generated__/graphql"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { Card, CardContent, CardFooter } from "~/ui/card"
import { Button } from "~/ui/button"
import { cn } from "~/lib/utils"
import { UserName } from "./UserName"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"

export type UserCardFormat = "card" | "list"

export const UserCard = ({
  user,
  format,
  onClick,
  highlight,
}: {
  user: User_CardFragment
  format: UserCardFormat
  onClick: () => void
  highlight?: boolean
}) => {
  const location = user.place?.full
  const ccls = useCommunityClassname()
  const community = useCommunity()

  return (
    <Card
      className={cn(
        "group cursor-pointer [box-shadow:none]",

        { "border-highlight bg-card-highlight-background": highlight },
        {
          "border-primary bg-white":
            !highlight && community.slug === CommunitySlug.Gotomillions,
        }
      )}
      onClick={onClick}
    >
      <CardContent
        className={cn(
          "flex items-center p-4",
          format === "card"
            ? "flex-col h-[196px] text-center pt-5 overflow-hidden"
            : "flex-row px-6 gap-5 justify-between"
        )}
      >
        <AvatarWithFallback
          user={user}
          size="onboarding"
          className={cn(format === "card" && "mb-1")}
        />
        <div className="flex-1">
          <div className={cn(format === "list" && "flex items-center gap-2")}>
            <div className="text-[13px] font-semibold">
              <UserName user={user} />
            </div>
            {user.pronouns && <div className="text-2xs">{user.pronouns}</div>}
          </div>
          {user.jobTitle && user.jobTitle.length > 0 && (
            <div className="mt-2 text-[12px]">{user.jobTitle}</div>
          )}
          {(user.companyName?.length || location?.length) && (
            <div
              className={cn(
                "mt-[2px] text-[#999999]",
                format === "card" ? "text-[10px]" : "text-[12px]"
              )}
            >
              {user.companyName}
              {user.companyName?.length && location?.length ? " - " : ""}
              {location}
            </div>
          )}
        </div>
        {format === "list" && (
          <Button
            variant="outline"
            className={cn(
              "border-borderColor rounded-lg group-hover:text-white group-hover:bg-primary group-hover:border-primary",
              ccls({
                [CommunitySlug.Gotomillions]: "border-highlight text-highlight",
                [CommunitySlug.Fintechtakes]: "border-link text-link",
                default: "",
              })
            )}
          >
            View
          </Button>
        )}
      </CardContent>
      {format === "card" && (
        <CardFooter
          className={cn(
            "bg-white text-primary group-hover:bg-primary group-hover:text-white border-[0px] border-t block py-2 text-center text-[12px]",
            ccls({
              [CommunitySlug.Gotomillions]: "border-primary",
              [CommunitySlug.Fintechtakes]: "text-link",
              default: "",
            })
          )}
        >
          View
        </CardFooter>
      )}
    </Card>
  )
}
