import { useMutation, useQuery } from "@apollo/client"
import { BeakerIcon } from "lucide-react"
import { useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { AdminHeader } from "~/admin/AdminHeader"
import { Badge } from "~/ui/badge"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { Card } from "~/ui/card"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { UserNameAndAvatar } from "~/directory/UserNameAndAvatar"
import { useConfig } from "~/common/ConfigProvider"

export const AdminExperimentScreen = () => {
  const { experimentName } = useParams() as { experimentName: string }
  const { qaToolsEnabled } = useConfig()

  const {
    data: currentData,
    previousData,
    loading,
    error,
    fetchMore,
  } = useQuery(USER_EXPERIMENTS_QUERY, {
    variables: {
      experimentName,
      first: 50,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
  })

  const data = currentData || previousData

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading experiment data." />

  const userExperiments = data?.userExperiments?.edges?.map((e) => e.node) || []
  const hasNextPage = data?.userExperiments?.pageInfo?.hasNextPage

  return (
    <>
      <AdminHeader
        title="Experiments"
        subtitle={`Experiment: ${experimentName}`}
        Icon={BeakerIcon}
      />

      <div className="space-y-6">
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Variant</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {userExperiments.map((userExperiment) => (
                <TableRow key={userExperiment.id}>
                  <TableCell>
                    {userExperiment.user.name ? (
                      <UserNameAndAvatar userId={userExperiment.user.id} />
                    ) : (
                      userExperiment.user.email
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Badge variant="secondary" className="text-3xs">
                        {userExperiment.variant}
                      </Badge>
                      {qaToolsEnabled && (
                        <VariantSelector
                          experimentName={experimentName}
                          userId={userExperiment.user.id}
                          currentVariant={userExperiment.variant}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>

        <div className="p-16 w-full flex items-center justify-center">
          <InfiniteLoadMore
            onEndReached={() =>
              fetchMore({
                variables: {
                  after: data?.userExperiments?.pageInfo?.endCursor,
                },
              })
            }
            canLoadMore={!loading && hasNextPage}
            loadingText="Loading more users..."
            loading={loading && userExperiments.length > 0 && hasNextPage}
          />
        </div>
      </div>
    </>
  )
}

const USER_EXPERIMENTS_QUERY = gql(`
  query UserExperiments($experimentName: String!, $first: Int, $after: String) {
    userExperiments(
      name: $experimentName,
      first: $first,
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          variant
          user {
            id
            email
            name
          }
        }
      }
    }
  }
`)

interface VariantSelectorProps {
  experimentName: string
  userId: string
  currentVariant: string
}

const VariantSelector = ({
  experimentName,
  userId,
  currentVariant,
}: VariantSelectorProps) => {
  const [assignVariant] = useMutation(ASSIGN_VARIANT_MUTATION, {
    refetchQueries: [USER_EXPERIMENTS_QUERY],
  })

  const handleVariantChange = async (newVariant: string) => {
    await assignVariant({
      variables: {
        input: {
          experiment: experimentName,
          variant: newVariant,
          userId: userId,
        },
      },
    })
  }

  return (
    <Select onValueChange={handleVariantChange} defaultValue={currentVariant}>
      <SelectTrigger className="w-[140px]">
        <SelectValue placeholder="Select variant" />
      </SelectTrigger>
      <SelectContent>
        {["refer_a_friend", "upload_content"].map((variant) => (
          <SelectItem key={variant} value={variant}>
            {variant}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const ASSIGN_VARIANT_MUTATION = gql(`
  mutation UserExperimentAssignVariant(
    $input: UserExperimentAssignVariantInput!
  ) {
    userExperimentAssignVariant(input: $input) {
      experiment {
        name
        variant
      }
      wasCreated
    }
  }
`)
