import {
  Announcements,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { SortableContext, arrayMove } from "@dnd-kit/sortable"
import { useCallback, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { coordinateGetter } from "./multipleContainersKeyboardPreset"
import { Active, DataRef, Over } from "@dnd-kit/core"

import { Section, SectionContainer, SectionDragData } from "./Section"
import { Lesson, LessonDragData } from "./Lesson"
import {
  isSavedSection,
  SavedOrUnsavedLesson,
  SavedOrUnsavedSection,
  isSavedLesson,
  lessonId,
} from "~/types"
import { useCourseEditorContext } from "./CourseEditor"

type DraggableData = SectionDragData | LessonDragData

export function hasDraggableData<T extends Active | Over>(
  entry: T | null | undefined
): entry is T & {
  data: DataRef<DraggableData>
} {
  if (!entry) {
    return false
  }

  const data = entry.data.current

  if (data?.type === "Section" || data?.type === "Lesson") {
    return true
  }

  return false
}

export const SectionsEditor = () => {
  const { sections, setSections } = useCourseEditorContext()
  const pickedUpLessonSectionId = useRef<UniqueIdentifier | null>(null)
  const sectionIds = useMemo(
    () =>
      sections.map((section) =>
        isSavedSection(section) ? section.id : section.tempId
      ),
    [sections]
  )

  const lessons = useMemo(() => {
    return sections.flatMap((section) => section.lessons)
  }, [sections])
  const setLessons = useCallback(
    (callback: (lessons: SavedOrUnsavedLesson[]) => SavedOrUnsavedLesson[]) => {
      const newLessons = callback(lessons)
      setSections((sections) => {
        return sections.map((section) => {
          return {
            ...section,
            lessons: newLessons.filter(
              (lesson) =>
                lesson.sectionId ===
                (isSavedSection(section) ? section.id : section.tempId)
            ),
          } as SavedOrUnsavedSection
        })
      })
    },
    [lessons, setSections]
  )

  const [activeSection, setActiveSection] =
    useState<SavedOrUnsavedSection | null>(null)
  const [activeLesson, setActiveLesson] = useState<SavedOrUnsavedLesson | null>(
    null
  )

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  )

  const getDraggingLessonData = (
    lessonId: UniqueIdentifier,
    sectionId: UniqueIdentifier
  ) => {
    const lessonsInSection = lessons.filter(
      (lesson) => lesson.sectionId === sectionId
    )
    const lessonPosition = lessonsInSection.findIndex(
      (lesson) =>
        lessonId === (isSavedLesson(lesson) ? lesson.id : lesson.tempId)
    )
    const section = sections.find(
      (section) =>
        sectionId === (isSavedSection(section) ? section.id : section.tempId)
    )
    return {
      lessonPosition,
      section,
      lessonsInSection,
    }
  }

  const announcements: Announcements = {
    onDragStart({ active }) {
      if (!hasDraggableData(active)) return
      if (active.data.current?.type === "Section") {
        const startSectionIdx = sectionIds.findIndex((id) => id === active.id)
        const startSection = sections[startSectionIdx]
        return `Picked up Section ${startSection?.title} at position: ${
          startSectionIdx + 1
        } of ${sectionIds.length}`
      } else if (active.data.current?.type === "Lesson") {
        pickedUpLessonSectionId.current = active.data.current.lesson.sectionId
        const { lessonsInSection, lessonPosition, section } =
          getDraggingLessonData(active.id, pickedUpLessonSectionId.current!)
        return `Picked up Lesson ${
          active.data.current.lesson.article.revision.title
        } at position: ${lessonPosition + 1} of ${
          lessonsInSection.length
        } in section ${section?.title}`
      }
    },
    onDragOver({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) return

      if (
        active.data.current?.type === "Section" &&
        over.data.current?.type === "Section"
      ) {
        const overSectionIdx = sectionIds.findIndex((id) => id === over.id)
        return `Section ${active.data.current.section.title} was moved over ${
          over.data.current.section.title
        } at position ${overSectionIdx + 1} of ${sectionIds.length}`
      } else if (
        active.data.current?.type === "Lesson" &&
        over.data.current?.type === "Lesson"
      ) {
        const { lessonsInSection, lessonPosition, section } =
          getDraggingLessonData(over.id, over.data.current.lesson.sectionId)
        if (
          over.data.current.lesson.sectionId !== pickedUpLessonSectionId.current
        ) {
          return `Lesson ${
            active.data.current.lesson.article.revision.title
          } was moved over section ${section?.title} in position ${
            lessonPosition + 1
          } of ${lessonsInSection.length}`
        }
        return `Lesson was moved over position ${lessonPosition + 1} of ${
          lessonsInSection.length
        } in section ${section?.title}`
      }
    },
    onDragEnd({ active, over }) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) {
        pickedUpLessonSectionId.current = null
        return
      }
      if (
        active.data.current?.type === "Section" &&
        over.data.current?.type === "Section"
      ) {
        const overSectionPosition = sectionIds.findIndex((id) => id === over.id)

        return `Section ${
          active.data.current.section.title
        } was dropped into position ${overSectionPosition + 1} of ${
          sectionIds.length
        }`
      } else if (
        active.data.current?.type === "Lesson" &&
        over.data.current?.type === "Lesson"
      ) {
        const { lessonsInSection, lessonPosition, section } =
          getDraggingLessonData(over.id, over.data.current.lesson.sectionId)
        if (
          over.data.current.lesson.sectionId !== pickedUpLessonSectionId.current
        ) {
          return `Lesson was dropped into section ${section?.title} in position ${
            lessonPosition + 1
          } of ${lessonsInSection.length}`
        }
        return `Lesson was dropped into position ${lessonPosition + 1} of ${
          lessonsInSection.length
        } in section ${section?.title}`
      }
      pickedUpLessonSectionId.current = null
    },
    onDragCancel({ active }) {
      pickedUpLessonSectionId.current = null
      if (!hasDraggableData(active)) return
      return `Dragging ${active.data.current?.type} cancelled.`
    },
  }

  function onDragStart(event: DragStartEvent) {
    if (!hasDraggableData(event.active)) return
    const data = event.active.data.current
    if (data?.type === "Section") {
      setActiveSection(data.section)
      return
    }

    if (data?.type === "Lesson") {
      setActiveLesson(data.lesson)
      return
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveSection(null)
    setActiveLesson(null)

    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (!hasDraggableData(active)) return

    const activeData = active.data.current

    if (activeId === overId) return

    const isActiveASection = activeData?.type === "Section"
    if (!isActiveASection) return

    const activeSectionIndex = sections.findIndex(
      (section) =>
        activeId === (isSavedSection(section) ? section.id : section.tempId)
    )

    const overSectionIndex = sections.findIndex(
      (section) =>
        overId === (isSavedSection(section) ? section.id : section.tempId)
    )

    const newSections = arrayMove(
      sections,
      activeSectionIndex,
      overSectionIndex
    )
    setSections(newSections)
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    if (!hasDraggableData(active) || !hasDraggableData(over)) return

    const activeData = active.data.current
    const overData = over.data.current

    const isActiveALesson = activeData?.type === "Lesson"
    const isOverALesson = overData?.type === "Lesson"

    if (!isActiveALesson) return

    // Im dropping a Lesson over another Lesson
    if (isActiveALesson && isOverALesson) {
      setLessons((lessons) => {
        const activeIndex = lessons.findIndex(
          (lesson) =>
            activeId === (isSavedLesson(lesson) ? lesson.id : lesson.tempId)
        )
        const overIndex = lessons.findIndex(
          (lesson) =>
            overId === (isSavedLesson(lesson) ? lesson.id : lesson.tempId)
        )
        const activeLesson = lessons[activeIndex]
        const overLesson = lessons[overIndex]
        if (
          activeLesson &&
          overLesson &&
          activeLesson.sectionId !== overLesson.sectionId
        ) {
          return arrayMove(lessons, activeIndex, overIndex - 1).map(
            (lesson) => {
              if (
                activeId === (isSavedLesson(lesson) ? lesson.id : lesson.tempId)
              ) {
                return { ...lesson, sectionId: overLesson.sectionId }
              }
              return lesson
            }
          )
        }
        return arrayMove(lessons, activeIndex, overIndex)
      })
    }

    const isOverASection = overData?.type === "Section"

    // Im dropping a Lesson over a section
    if (isActiveALesson && isOverASection) {
      setLessons((lessons) => {
        const activeIndex = lessons.findIndex(
          (lesson) =>
            activeId === (isSavedLesson(lesson) ? lesson.id : lesson.tempId)
        )
        const activeLesson = lessons[activeIndex]
        if (activeLesson) {
          const newLessons = arrayMove(lessons, activeIndex, activeIndex)
          return newLessons.map((lesson) => {
            if (
              activeId === (isSavedLesson(lesson) ? lesson.id : lesson.tempId)
            ) {
              return { ...lesson, sectionId: overId as string }
            }
            return lesson
          })
        }
        return lessons
      })
    }
  }

  const onCreateLesson = useCallback(
    (lesson: SavedOrUnsavedLesson) => {
      setLessons((lessons) => [...lessons, lesson])
    },
    [setLessons]
  )

  const onUpdateLesson = useCallback(
    (lesson: SavedOrUnsavedLesson) => {
      setLessons((lessons) => {
        const lessonIndex = lessons.findIndex(
          (l) => lessonId(l) === lessonId(lesson)
        )
        return [
          ...lessons.slice(0, lessonIndex),
          lesson,
          ...lessons.slice(lessonIndex + 1),
        ]
      })
    },
    [setLessons]
  )

  return (
    <DndContext
      accessibility={{
        announcements,
      }}
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <SectionContainer>
        <SortableContext items={sectionIds}>
          {sections.map((section) => (
            <Section
              key={isSavedSection(section) ? section.id : section.tempId}
              section={section}
              onCreateLesson={onCreateLesson}
              onUpdateLesson={onUpdateLesson}
            />
          ))}
        </SortableContext>
      </SectionContainer>

      {"document" in window &&
        createPortal(
          <DragOverlay>
            {activeSection && (
              <Section
                isOverlay
                section={activeSection}
                onCreateLesson={onCreateLesson}
                onUpdateLesson={onUpdateLesson}
              />
            )}
            {activeLesson && (
              <Lesson
                lesson={activeLesson}
                onUpdateLesson={onUpdateLesson}
                isOverlay
              />
            )}
          </DragOverlay>,
          document.body
        )}
    </DndContext>
  )
}
