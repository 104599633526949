import { Hash, Lock } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  ChannelCreateInput,
  ChannelVisibilityEnum,
} from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { ChannelForm } from "~/admin/ChannelForm"
import { useChannels } from "~/common/ChannelsProvider"
import { adminChannelsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

export const AdminChannelNewScreen = () => {
  const { visibility } = useParams()
  const [urlSearchParams] = useSearchParams()
  const [runCreateChannel] = useSafeMutation(CHANNEL_CREATE_MUTATION)
  const navigate = useNavigate()
  const { refetch } = useChannels()

  const handleSubmit = async (data: ChannelCreateInput) => {
    console.log("AdminChannelNewScreen", { data, visibility })
    const response = await runCreateChannel({
      variables: {
        input: {
          ...data,
          visibility:
            visibility === "public"
              ? ChannelVisibilityEnum.Public
              : ChannelVisibilityEnum.Private,
        },
      },
    })

    const { data: responseData, errors } = response

    if (errors) {
      displayErrors(errors)
    } else if (responseData?.channelCreate?.channel) {
      await refetch()
      toast.success("Channel created.")
      navigate(adminChannelsPath.pattern)
    }

    return response
  }

  return (
    <>
      <AdminHeader
        title={
          visibility === "public"
            ? "Create Public Channel"
            : "Create Private Channel"
        }
        Icon={visibility === "public" ? Hash : Lock}
      />

      <ChannelForm
        onSubmit={handleSubmit}
        visibility={
          visibility === "public"
            ? ChannelVisibilityEnum.Public
            : ChannelVisibilityEnum.Private
        }
        initialValues={{
          groupId: urlSearchParams.get("groupId") || undefined,
        }}
      />
    </>
  )
}

const CHANNEL_CREATE_MUTATION = gql(`
  mutation ChannelCreate($input: ChannelCreateInput!) {
    channelCreate(input: $input) {
      channel {
        ...Channel_Display
      }
    }
  }
`)
