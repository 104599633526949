import { useQuery } from "@apollo/client"
import { Check } from "lucide-react"
import { WaitingRoomHeader } from "../ABFitWaitingScreen"
import { Card, CardContent } from "~/ui/card"
import { FileUploader, ONBOARDING_CONTENT_UPLOAD_QUERY } from "./FileUploader"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { Translation } from "~/common/Translation"

export const Upload = () => {
  const ccls = useCommunityClassname()
  const { data } = useQuery(ONBOARDING_CONTENT_UPLOAD_QUERY)
  const existingUploads = data?.onboardingContentUploads?.nodes || []
  return (
    <>
      <div className="flex items-center flex-col">
        <WaitingRoomHeader />
        <div className="font-sans font-light tracking-wide mb-5 text-center max-w-2xl">
          <Translation ns="upload" i18nKey="description" />
        </div>
        <div className="space-y-8 w-full max-w-2xl">
          <Card variant="compact">
            <CardContent className="pt-6">
              <div className="space-y-4">
                <FileUploader />
              </div>
            </CardContent>
          </Card>

          {existingUploads?.length > 0 && (
            <Card variant="compact">
              <CardContent className="pt-6">
                <h3 className="font-medium mb-4">Uploaded Content</h3>
                <div className="divide-y">
                  {existingUploads.map((upload) => (
                    <div
                      key={upload.id}
                      className="py-3 flex items-center justify-between"
                    >
                      <div className="flex items-center gap-2">
                        <div
                          className={cn(
                            "rounded-full bg-highlight p-1",
                            ccls({
                              [CommunitySlug.Gotomillions]: "bg-success",
                              default: "",
                            })
                          )}
                        >
                          <Check className="h-3 w-3 text-white" />
                        </div>
                        <span>{upload.attachment.filename}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </>
  )
}
