import { Article_EditorContentFragment } from "~/__generated__/graphql"
import ArrowRight from "~/images/icons/arrow-right.svg?react"
import { ArticleDetailsModal } from "~/articles/ArticleDetailsModal"
import { useArticleDetailsModal } from "~/articles/useArticleDetailsModal"
import {
  ArticleCollaboratorsModal,
  useCreateCollaborator,
  useDestroyCollaborator,
} from "~/articles/ArticleCollaboratorsModal"
import { useState } from "react"
import { RevisionHistoryDrawer } from "~/articles/RevisionHistoryDrawer"
import { ExternalShareSettingsModal } from "~/articles/ExternalShareSettingModal"
import { ArticleCard } from "~/ui/ArticleCard"
import { ImageCoverModal } from "~/articles/ImageCoverModal"

export const EditorMetadataSection = ({
  article,
  revisionHistoryDefaultOpen = false,
}: {
  article: Article_EditorContentFragment
  revisionHistoryDefaultOpen?: boolean
}) => {
  const [collaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false)
  const articleDetailsModalHelpers = useArticleDetailsModal({
    existingArticle: article,
  })
  const [externalSharingModalOpen, setExternalSharingModalOpen] =
    useState(false)
  const [editImageCoverModalOpen, setEditImageCoverModalOpen] = useState(false)

  const { createCollaborator } = useCreateCollaborator()
  const { destroyCollaborator } = useDestroyCollaborator()

  return (
    <div className="flex border-b border-mercury">
      <div className="px-6 py-4 flex-1 items-start border-r border-mercury">
        <ArticleCard
          article={article}
          clickable={false}
          variant="hero"
          className="sm:w-full border-0 h-72 shadow-none [&>div]:p-0"
        />
      </div>

      <div className="px-6 py-4">
        <ContentModalLink
          text="Edit Content Details"
          onClick={() => articleDetailsModalHelpers.setIsOpen(true)}
        />
        <ContentModalLink
          text="Edit / Invite Collaborators"
          onClick={() => setCollaboratorsModalOpen(true)}
        />
        {article.currentUserCanChangeExternalShareSetting && (
          <ContentModalLink
            text={
              article.allowExternalShare
                ? "External Sharing ON"
                : "External Sharing OFF"
            }
            onClick={() => setExternalSharingModalOpen(true)}
          />
        )}
        <ContentModalLink
          text="Edit Image Cover"
          onClick={() => setEditImageCoverModalOpen(true)}
        />

        {article.currentUserCanViewRevisionLogs.value && (
          <RevisionHistoryDrawer
            articleId={article.id}
            defaultOpen={revisionHistoryDefaultOpen}
          >
            <ContentModalLink text="Revision History" />
          </RevisionHistoryDrawer>
        )}
      </div>

      <ArticleDetailsModal {...articleDetailsModalHelpers} />
      <ExternalShareSettingsModal
        isOpen={externalSharingModalOpen}
        setIsOpen={setExternalSharingModalOpen}
        articleId={article.id}
        allowExternalShare={article.allowExternalShare}
      />
      {collaboratorsModalOpen && (
        <ArticleCollaboratorsModal
          setIsOpen={setCollaboratorsModalOpen}
          articleId={article.id}
          collaborators={article.collaborators}
          onAddCollaborator={createCollaborator}
          onRemoveCollaborator={destroyCollaborator}
          onSubmit={() => setCollaboratorsModalOpen(false)}
          isEditing
        />
      )}
      {editImageCoverModalOpen && (
        <ImageCoverModal
          article={article}
          portraitImageId={article.revision.portraitImageId || undefined}
          landscapeImageId={article.revision.landscapeImageId || undefined}
          portraitImageUrl={article.revision.portraitImageUrl || undefined}
          landscapeImageUrl={article.revision.landscapeImageUrl || undefined}
          isOpen={editImageCoverModalOpen}
          setIsOpen={setEditImageCoverModalOpen}
        />
      )}
    </div>
  )
}

const ContentModalLink = ({
  text,
  onClick,
}: {
  onClick?: () => void
  text: string
}) => (
  <div
    className="mb-2 flex items-center text-xs font-medium cursor-pointer"
    onClick={onClick}
  >
    {text}
    <ArrowRight className="ml-2 text-blueCharcoal" />
  </div>
)
