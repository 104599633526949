import React, { useEffect, useState, useRef, useMemo } from "react"
import { useDebounce } from "use-debounce"
import { useQuery } from "@apollo/client"

import { gql } from "~/__generated__"
import { SearchFeedback } from "~/common/SearchFeedback"
import {
  ArticleSortTypeEnum,
  AhoyEventTypeEnum,
  ArticleContentTypeEnum,
  MarkReadEnum,
  CommunitySlug,
  ScreenEnum,
} from "~/__generated__/graphql"
import {
  DropdownMenuCheckboxes,
  DropdownOptionType,
} from "~/common/DropdownMenuCheckboxes"
import { ArticleCard } from "~/ui/ArticleCard"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { useLogEvent } from "~/analytics/EventsContext"
import { useSearchParams } from "react-router-dom"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { useReadIndicators } from "~/content/useReadIndicators"
import { SearchInput } from "~/ui/SearchInput"
import { cn } from "~/lib/utils"
import { Translation, useTranslation } from "~/common/Translation"
import { H7 } from "~/ui/typography"
import { camelCase } from "change-case"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { ScrollArea, ScrollBar, ScrollFade } from "~/ui/scroll-area"
import { Button, LinkButton } from "~/ui/button"
import { CreateContentButton } from "~/articles/CreateContentButton"
import { myContentPath } from "~/common/paths"
import {
  ArticleCategory,
  categoriesToContentTypes,
  ContentTypeIcon,
  useContentTypeOptions,
} from "~/ui/ContentType"
import { ArrowUpDown } from "lucide-react"
import { useTags } from "~/common/GlobalsProvider"

export type SortOptionType = {
  label: string
  value: ArticleSortTypeEnum
}

export type ContentOptionType = {
  label: string | React.ReactNode
  value: ArticleContentTypeEnum
}

export const librarySortOptions: SortOptionType[] = [
  {
    value: ArticleSortTypeEnum.Alpha,
    label: "Alphabetically",
  },
  {
    value: ArticleSortTypeEnum.CreatedAtDesc,
    label: "Newest to Oldest",
  },
]

export const LibraryScreen = () => {
  const { logEvent } = useLogEvent()
  const [searchParams, setSearchParams] = useSearchParams()
  const [query, setQuery] = useState<string>(searchParams.get("search") || "")
  const [debouncedQuery] = useDebounce(query, 200)
  const [topics, setTopics] = useState<DropdownOptionType[]>([])
  const [contentTypes, setContentTypes] = useState<ContentOptionType[]>([])
  const [sort, setSort] = useState<SortOptionType[]>([librarySortOptions[1]])
  const community = useCommunity()
  const { unviewedIds } = useReadIndicators({ target: MarkReadEnum.Library })
  const ccls = useCommunityClassname()
  const { t } = useTranslation("libraryScreen")

  const [selectedCategory, setSelectedCategory] =
    useState<ArticleCategory>("discover")

  useEffect(() => {
    if (debouncedQuery.length > 0) {
      if (debouncedQuery !== searchParams.get("search")) {
        setSearchParams({ search: debouncedQuery })
      }
    } else {
      searchParams.delete("search")
      setSearchParams(searchParams)
    }
  }, [debouncedQuery, searchParams, setSearchParams])

  useEffect(() => {
    setContentTypes(
      categoriesToContentTypes[selectedCategory]
        .filter((value) => community.contentTypes.includes(value))
        .map((value) => ({
          value,
          label: value,
        }))
    )
  }, [selectedCategory])

  const { data: categorizedData, loading: categorizedLoading } = useQuery(
    LIBRARY_QUERY_DOCUMENT,
    {
      variables: {
        query: debouncedQuery,
        tagIds: topics.length > 0 ? topics.map((t) => t.value) : undefined,
        contentTypes:
          contentTypes.length > 0
            ? contentTypes.map((t) => t.value)
            : community.contentTypes,
        sort: sort[0] ? sort[0].value : undefined,
        first: 9,
      },
      skip: selectedCategory !== "discover",
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      onCompleted: () => {
        if (debouncedQuery.length !== 0) {
          logEvent(AhoyEventTypeEnum.ContentSearched, {
            search_query: debouncedQuery,
          })
        }
      },
    }
  )

  const {
    data: currentData,
    previousData,
    loading,
    fetchMore,
  } = useQuery(LIBRARY_ARTICLES_QUERY_DOCUMENT, {
    variables: {
      query: debouncedQuery,
      tagIds: topics.length > 0 ? topics.map((t) => t.value) : undefined,
      contentTypes:
        contentTypes.length > 0
          ? contentTypes.map((t) => t.value)
          : community.contentTypes,
      sort: sort[0] ? sort[0].value : undefined,
      first: 9,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
    onCompleted: () => {
      if (debouncedQuery.length !== 0) {
        logEvent(AhoyEventTypeEnum.ContentSearched, {
          search_query: debouncedQuery,
        })
      }
    },
  })

  const data = currentData || previousData

  const tags = useTags()
  const topicOptions = useMemo(
    () =>
      tags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
    [tags]
  )

  const {
    featuredArticles,
    newlyAddedArticles,
    trendingArticles,
    communityArticles,
  } = useMemo(() => {
    if (!categorizedData) {
      return {
        featuredArticles: [],
        newlyAddedArticles: [],
        trendingArticles: [],
        communityArticles: [],
      }
    }
    return {
      featuredArticles: categorizedData.featuredArticles.edges.map(
        (e) => e.node
      ),
      newlyAddedArticles: categorizedData.newlyAddedArticles.edges.map(
        (e) => e.node
      ),
      trendingArticles: categorizedData.trendingArticles.edges.map(
        (e) => e.node
      ),
      communityArticles: categorizedData.communityArticles.edges.map(
        (e) => e.node
      ),
    }
  }, [categorizedData])

  const contentTypeOptions = useContentTypeOptions(selectedCategory, "w-4 h-4")

  const inputRef = useRef<HTMLInputElement | null>(null)

  const clearSearch = () => {
    setQuery("")
    setTopics([])
    setContentTypes([])
    setSort([])
  }

  const searchActive =
    debouncedQuery !== "" ||
    topics.length > 0 ||
    (contentTypes.length > 0 &&
      contentTypes.length !== contentTypeOptions.length)

  const isLoading = (loading || categorizedLoading) && !data && !categorizedData

  return (
    <>
      <div className="flex flex-col gap-8">
        {/* <H3
          className="mx-auto text-center"
        >
          <Translation
            ns={camelCase(ScreenEnum.LibraryScreen)}
            i18nKey="title"
          />
        </H3>
        <P className="md:max-w-2xl text-center mx-auto">
          <Translation
            ns={camelCase(ScreenEnum.LibraryScreen)}
            i18nKey="description"
          />
        </P> */}

        <div className="flex justify-end gap-1">
          <CreateContentButton />
          <LinkButton to={myContentPath.pattern} variant="link">
            My Content
          </LinkButton>
        </div>

        <div className="px-4 flex flex-wrap items-center gap-1">
          <Button
            pre={<ContentTypeIcon category="discover" className="w-4 h-4" />}
            variant={selectedCategory === "discover" ? "filled" : "unselected"}
            onClick={() => setSelectedCategory("discover")}
          >
            Discover
          </Button>
          <Button
            pre={<ContentTypeIcon category="newsletters" className="w-4 h-4" />}
            variant={
              selectedCategory === "newsletters" ? "filled" : "unselected"
            }
            onClick={() => setSelectedCategory("newsletters")}
          >
            Newsletters
          </Button>
          <Button
            pre={<ContentTypeIcon category="ondemand" className="w-4 h-4" />}
            variant={selectedCategory === "ondemand" ? "filled" : "unselected"}
            onClick={() => setSelectedCategory("ondemand")}
          >
            Replays
          </Button>
          <Button
            pre={<ContentTypeIcon category="resources" className="w-4 h-4" />}
            variant={selectedCategory === "resources" ? "filled" : "unselected"}
            onClick={() => setSelectedCategory("resources")}
          >
            Resources
          </Button>
          {/* <Button
            pre={<ContentTypeIcon category="podcasts" className="w-4 h-4" />}
            variant={selectedCategory === "podcasts" ? "filled" : "unselected"}
            onClick={() => setSelectedCategory("podcasts")}
          >
            Podcasts
          </Button> */}
        </div>

        <div className="px-4 flex flex-col gap-2">
          <div className="flex flex-row flex-wrap md:flex-nowrap md:space-x-2 items-center">
            <div className="w-full md:flex-1">
              <div className="relative text-sm" ref={inputRef}>
                <SearchInput
                  placeholder={t("searchInputPlaceholder")}
                  searchTerm={query}
                  setSearchTerm={setQuery}
                  className={cn(
                    "bg-card",
                    ccls({
                      [CommunitySlug.Gotomillions]:
                        "border-primary text-primary",
                      default: "",
                    })
                  )}
                />
              </div>
            </div>
            <div className="md:w-36 md:ml-auto w-full md:mt-0 mt-1">
              <DropdownMenuCheckboxes
                title={
                  contentTypes.length > 0 &&
                  contentTypes.length !== contentTypeOptions.length
                    ? `${contentTypes.length} Selected`
                    : "By Type"
                }
                optionTitle="FILTER BY TYPE"
                options={contentTypeOptions}
                selected={contentTypes}
                onChange={(data) => {
                  const contentTypeNames = (data || []).map((t) => t.label)

                  if (contentTypeNames.length !== 0) {
                    logEvent(AhoyEventTypeEnum.ContentTypeFilterApplied, {
                      content_types: contentTypeNames.join(", "),
                    })
                  }

                  setContentTypes(data)
                }}
              />
            </div>
            <div className="md:w-36 md:ml-auto w-full md:mt-0 mt-1">
              <DropdownMenuCheckboxes
                title={
                  topics.length > 0 ? `${topics.length} Selected` : "By Topic"
                }
                optionTitle="FILTER BY TOPIC"
                options={topicOptions}
                selected={topics}
                onChange={(data) => {
                  const topicNames = (data || []).map((t) => t.label)

                  if (topicNames.length !== 0) {
                    logEvent(AhoyEventTypeEnum.TopicFilterApplied, {
                      topics: topicNames.join(", "),
                    })
                  }

                  setTopics(data)
                }}
              />
            </div>
            <div className="md:ml-auto md:mt-0 mt-1">
              <DropdownMenuCheckboxes
                title={"Sort"}
                options={librarySortOptions}
                selected={sort}
                onChange={setSort}
                exclusive={true}
                trigger={
                  <Button
                    pre={<ArrowUpDown className="w-4 h-4" />}
                    variant="minimal"
                    theme="current"
                  >
                    Sort
                  </Button>
                }
              />
            </div>
          </div>
          {searchActive && (
            <SearchFeedback
              totalCount={data?.libraryArticles?.totalCount || 0}
              onClearSearch={() => {
                logEvent(AhoyEventTypeEnum.ContentSearchCleared, {
                  search_query: query,
                  topics: (topics || []).map((t) => t.label).join(", "),
                  types: (contentTypes || []).map((t) => t.value).join(", "),
                })
                clearSearch()
              }}
            />
          )}
        </div>

        {isLoading && <LoadingIndicatorCentered />}
        {!isLoading && (
          <>
            {featuredArticles && featuredArticles.length > 0 && (
              <div className="flex flex-col gap-4">
                <H7 className="px-4">
                  <Translation
                    ns={camelCase(ScreenEnum.LibraryScreen)}
                    i18nKey="featuredArticlesTitle"
                  />
                </H7>

                <ScrollArea type="always">
                  <div className="flex gap-4 px-4">
                    {featuredArticles.map((article) => {
                      return (
                        <ArticleCard
                          key={article.id}
                          variant={"hero"}
                          article={article}
                          state={
                            unviewedIds.includes(article.id)
                              ? "highlighted"
                              : "default"
                          }
                          className="w-screen-with-padding"
                          trackImpressions
                        />
                      )
                    })}
                  </div>
                  <ScrollBar orientation="horizontal" className="mx-4" />
                  <ScrollFade direction="left" className="text-black/20 h-80" />
                  <ScrollFade
                    direction="right"
                    className="text-black/20 h-80"
                  />
                </ScrollArea>
              </div>
            )}

            {newlyAddedArticles && newlyAddedArticles.length > 0 && (
              <div className="flex flex-col gap-4">
                <H7 className="px-4">
                  <Translation
                    ns={camelCase(ScreenEnum.LibraryScreen)}
                    i18nKey="newlyAddedArticlesTitle"
                  />
                </H7>

                <ScrollArea type="always">
                  <div className="flex gap-4 px-4">
                    {newlyAddedArticles.map((article) => {
                      return (
                        <ArticleCard
                          key={article.id}
                          article={article}
                          state={
                            unviewedIds.includes(article.id)
                              ? "highlighted"
                              : "default"
                          }
                          className="w-screen-with-padding"
                          trackImpressions
                        />
                      )
                    })}
                  </div>
                  <ScrollBar orientation="horizontal" className="mx-4" />
                  <ScrollFade
                    direction="left"
                    className="text-black/20 h-102"
                  />
                  <ScrollFade
                    direction="right"
                    className="text-black/20 h-102"
                  />
                </ScrollArea>
              </div>
            )}

            {trendingArticles && trendingArticles.length > 0 && (
              <div className="flex flex-col gap-4">
                <H7 className="px-4">
                  <Translation
                    ns={camelCase(ScreenEnum.LibraryScreen)}
                    i18nKey="trendingArticlesTitle"
                  />
                </H7>

                <ScrollArea type="always">
                  <div className="flex gap-4 px-4">
                    {trendingArticles.map((article) => {
                      return (
                        <ArticleCard
                          key={article.id}
                          article={article}
                          state={
                            unviewedIds.includes(article.id)
                              ? "highlighted"
                              : "default"
                          }
                          className="w-screen-with-padding"
                          trackImpressions
                        />
                      )
                    })}
                  </div>
                  <ScrollBar orientation="horizontal" className="mx-4" />
                  <ScrollFade
                    direction="left"
                    className="text-black/10 h-102"
                  />
                  <ScrollFade
                    direction="right"
                    className="text-black/10 h-102"
                  />
                </ScrollArea>
              </div>
            )}

            {communityArticles && communityArticles.length > 0 && (
              <div className="flex flex-col gap-4">
                <H7 className="px-4">
                  <Translation
                    ns={camelCase(ScreenEnum.LibraryScreen)}
                    i18nKey="communityArticlesTitle"
                  />
                </H7>

                <ScrollArea type="always">
                  <div className="flex gap-4 px-4">
                    {communityArticles.map((article) => {
                      return (
                        <ArticleCard
                          key={article.id}
                          article={article}
                          trackImpressions
                          className="w-screen-with-padding"
                          state={
                            unviewedIds.includes(article.id)
                              ? "highlighted"
                              : "default"
                          }
                        />
                      )
                    })}
                  </div>
                  <ScrollBar orientation="horizontal" className="mx-4" />
                  <ScrollFade
                    direction="left"
                    className="text-black/10 h-102"
                  />
                  <ScrollFade
                    direction="right"
                    className="text-black/10 h-102"
                  />
                </ScrollArea>
              </div>
            )}

            <div className="flex flex-col gap-4">
              <H7 className="px-4">
                <Translation
                  ns={camelCase(ScreenEnum.LibraryScreen)}
                  i18nKey="libraryArticlesTitle"
                />
              </H7>

              <div
                className="grid grid-cols-1 md:grid-cols-3 gap-4 px-4"
                style={{ gridAutoRows: "min-content" }}
              >
                {data && (
                  <>
                    {data.libraryArticles.edges
                      .map((e) => e.node)
                      .map((article) => {
                        return (
                          <ArticleCard
                            key={article.id}
                            article={article}
                            variant="defaultFullWidth"
                            state={
                              unviewedIds.includes(article.id)
                                ? "highlighted"
                                : "default"
                            }
                            trackImpressions
                            // query={query}
                          />
                        )
                      })}
                    <InfiniteLoadMore
                      onEndReached={() =>
                        fetchMore({
                          variables: {
                            after: data.libraryArticles.pageInfo.endCursor,
                          },
                        })
                      }
                      canLoadMore={data.libraryArticles.pageInfo.hasNextPage}
                      loadingText="Loading articles..."
                      loading={false}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export const LIBRARY_QUERY_DOCUMENT = gql(`
  query LibraryCategorizedArticles(
    $query: String,
    $collaborators: [ID!],
    $tagIds: [ID!],
    $contentTypes: [ArticleContentTypeEnum!],
    $sort: ArticleSortTypeEnum,
    $first: Int,
    $after: String
  ) {
    featuredArticles: articles(
      query: $query,
      revisionType: APPROVED,
      visibilities: [LIVE],
      states: [APPROVED HAS_PENDING_EDITS],
      collaborators: $collaborators,
      tagIds: $tagIds,
      contentTypes: $contentTypes,
      sort: CREATED_AT_DESC,
      first: 10,
      featured: true
    ) {
      edges {
        node {
          id
          ...Article_Card
        }
      }
    }

    newlyAddedArticles: articles(
      query: $query,
      revisionType: APPROVED,
      visibilities: [LIVE],
      states: [APPROVED HAS_PENDING_EDITS],
      collaborators: $collaborators,
      tagIds: $tagIds,
      contentTypes: $contentTypes,
      sort: CREATED_AT_DESC,
      first: 10
      newlyAdded: true
    ) {
      edges {
        node {
          id
          ...Article_Card
        }
      }
    }

    trendingArticles: articles(
      query: $query,
      revisionType: APPROVED,
      visibilities: [LIVE],
      states: [APPROVED HAS_PENDING_EDITS],
      collaborators: $collaborators,
      tagIds: $tagIds,
      contentTypes: $contentTypes,
      sort: TOP_VIEWED,
      first: 10
    ) {
      edges {
        node {
          id
          ...Article_Card
        }
      }
    }

    communityArticles: articles(
      query: $query,
      revisionType: APPROVED,
      visibilities: [LIVE],
      states: [APPROVED HAS_PENDING_EDITS],
      collaborators: $collaborators,
      tagIds: $tagIds,
      contentTypes: $contentTypes,
      communityAuthoredOnly: true,
      sort: CREATED_AT_DESC,
      first: 10
    ) {
      edges {
        node {
          id
          ...Article_Card
        }
      }
    }

    libraryArticles: articles(
      query: $query,
      revisionType: APPROVED,
      visibilities: [LIVE],
      states: [APPROVED HAS_PENDING_EDITS],
      collaborators: $collaborators,
      tagIds: $tagIds,
      contentTypes: $contentTypes,
      sort: $sort,
      first: $first,
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ...Article_Card
        }
      }
    }
  }
`)

const LIBRARY_ARTICLES_QUERY_DOCUMENT = gql(`
   query LibraryArticles(
    $query: String,
    $collaborators: [ID!],
    $tagIds: [ID!],
    $contentTypes: [ArticleContentTypeEnum!],
    $sort: ArticleSortTypeEnum,
    $first: Int,
    $after: String
  ) {
    libraryArticles: articles(
      query: $query,
      revisionType: APPROVED,
      visibilities: [LIVE],
      states: [APPROVED HAS_PENDING_EDITS],
      collaborators: $collaborators,
      tagIds: $tagIds,
      contentTypes: $contentTypes,
      sort: $sort,
      first: $first,
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ...Article_Card
        }
      }
    }
  }
`)
