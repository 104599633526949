import Delimiter from "@editorjs/delimiter"
import DelimiterIcon from "~/images/icons/delimiter.svg?raw"

export class WorkweekDelimiterTool extends Delimiter {
  static get toolbox() {
    return {
      icon: DelimiterIcon,
      title: "Divider",
    }
  }
}
