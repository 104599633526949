import { zodResolver } from "@hookform/resolvers/zod"
import { useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  AutomationCategoryEnum,
  AutomationCreateInput,
  RulesEngineEffectTypeEnum,
  RulesEngineEventTypeEnum,
  User_AvatarFragment,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { SelectField } from "~/components/forms/SelectField"
import { TextareaField } from "~/components/forms/TextareaField"
import { TextField } from "~/components/forms/TextField"
import { ToggleField } from "~/components/forms/ToggleField"
import { UserSelectField } from "~/components/forms/UserSelectField"
import { ComposerHandle, PostComposer } from "~/post-composer/PostComposer"
import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
  FormDescription,
} from "~/ui/form"
import { useTiers } from "~/tiers/TiersProvider"

const automationFormSchema = z.object({
  name: z.string().min(1, {
    message: "Name is required",
  }),
  description: z.string(),
  active: z.boolean(),
  userId: z.string(),
  tierLevel: z.string(),
  triggerEventName: z.string(),
  delayCount: z.coerce.number(),
  delayUnit: z.string(),
  postParams: z.any(),
})

export type AutomationFormValues = z.infer<typeof automationFormSchema>

const defaultTierSelectEntry = { label: "All Tiers", value: "all" }

const delayUnitSelectEntries = [
  { label: "Minute(s)", value: "minute" },
  { label: "Hour(s)", value: "hour" },
  { label: "Day(s)", value: "day" },
]

export const AutoMessageForm = ({
  onSubmit: _onSubmit,
  defaultValues,
}: {
  onSubmit: (input: AutomationCreateInput) => void
  defaultValues?: AutomationFormValues & { rulesEventEngineId?: string }
}) => {
  const currentUser = useCurrentUser()
  const { tiers } = useTiers()
  const [loading, setLoading] = useState(false)

  const form = useForm<AutomationFormValues>({
    resolver: zodResolver(automationFormSchema),
    defaultValues: {
      name: "",
      description: "",
      active: false,
      userId: currentUser.id,
      tierLevel: defaultTierSelectEntry.value,
      triggerEventName: RulesEngineEventTypeEnum.UserFinishedOnboarding,
      delayCount: 0,
      delayUnit: "day",
      postParams: {},
      ...(defaultValues || {}),
    },
  })

  const onSubmit = async ({
    delayCount,
    delayUnit,
    tierLevel,
    userId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    postParams,
    triggerEventName,
    ...values
  }: AutomationFormValues) => {
    setLoading(true)

    const conditions: any = {
      all: [{ fact: "user_run_count", operator: "equal", value: 0 }],
    }

    const triggerDelaySeconds = (() => {
      if (
        triggerEventName !== RulesEngineEventTypeEnum.UserFinishedOnboarding
      ) {
        return 0
      }

      switch (delayUnit) {
        case "minute":
          return delayCount * 60
        case "hour":
          return delayCount * 60 * 60
        case "day":
          return delayCount * 60 * 60 * 24
        default:
          return delayCount
      }
    })()

    if (tierLevel !== "all") {
      conditions.all.push({
        fact: "user.tier.level",
        operator: "equal",
        value: tierLevel,
      })
    }

    // Change camelcase keys from postComposerRef.current.getPostData() to snakecase
    const postParamsSnakecase = Object.fromEntries(
      Object.entries(postComposerRef.current?.getPostData() || {}).map(
        ([key, value]) => [key.replace(/([A-Z])/g, "_$1").toLowerCase(), value]
      )
    )

    _onSubmit({
      ...values,
      category: AutomationCategoryEnum.AutoMessage,
      triggerEventName: triggerEventName as RulesEngineEventTypeEnum,
      triggerDelaySeconds,
      conditions,
      effects: [
        {
          type: RulesEngineEffectTypeEnum.SendDirectMessage,
          params: {
            from_user_id: userId,
            post_params: postParamsSnakecase,
          },
        },
      ],
    })
  }

  const tierSelectEntries = useMemo(() => {
    return [
      defaultTierSelectEntry,
      ...tiers.map((tier) => ({
        label: tier.name,
        value: tier.level.toLowerCase(),
      })),
    ]
  }, [tiers])

  const triggerEventNameSelectEntries = useMemo(() => {
    return [
      {
        label: "After user onboards",
        value: RulesEngineEventTypeEnum.UserFinishedOnboarding,
      },
      {
        label: "On the user's birthday at 10 AM ET",
        value: RulesEngineEventTypeEnum.CelebrationBirthdayOccurred,
      },
      {
        label: "On the user's work anniversary at 11 AM ET",
        value: RulesEngineEventTypeEnum.CelebrationWorkAnniversaryOccurred,
      },
      {
        label: "On the user's toxic workplace breakup anniversary at 11 AM ET",
        value:
          RulesEngineEventTypeEnum.CelebrationToxicWorkplaceBreakupOccurred,
      },
    ]
  }, [])

  const postComposerRef = useRef<ComposerHandle>(null)
  const [selectedUser, setSelectedUser] = useState<User_AvatarFragment | null>(
    null
  )

  return (
    <Card>
      <CardContent className="pt-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col mb-4 gap-4">
              <TextField label="Name" control={form.control} name="name" />
              <TextareaField
                label="Description"
                control={form.control}
                name="description"
              />
              <UserSelectField
                label="Message Sender"
                control={form.control}
                name="userId"
                initialValue={defaultValues?.userId || currentUser.id}
                onUserSelected={setSelectedUser}
              />
              <FormField
                control={form.control}
                name="postParams"
                render={() => (
                  <FormItem>
                    <FormLabel required>Message Copy</FormLabel>
                    <FormDescription>
                      <Alert variant="info" className="text-2xs">
                        The following variables are available for use in your
                        message:
                        <ul className="list-disc pl-4">
                          <li>
                            <code>{"{{first_name}}"}</code> - User's name
                          </li>
                          <li>
                            <code>{"{{last_name}}"}</code> - User's last name
                          </li>
                          <li>
                            <code>{"{{email}}"}</code> - User's email
                          </li>
                          <li>
                            <code>{"{{tier}}"}</code> - User's tier name
                          </li>
                          <li>
                            <code>{"{{community_name}}"}</code> - Community name
                          </li>
                          <li>
                            <code>{"{{community_brand}}"}</code> - Community
                            brand
                          </li>
                        </ul>
                      </Alert>
                    </FormDescription>
                    <FormControl>
                      <PostComposer
                        post={defaultValues?.postParams}
                        isSaving={loading}
                        ref={postComposerRef}
                        author={selectedUser}
                        showSaveButton={false}
                        withDirtyTracking={false}
                        allowAttachments={false}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <SelectField
                label="Tiers"
                selectEntries={tierSelectEntries}
                control={form.control}
                name="tierLevel"
              />
              <div className="flex gap-4 items-center">
                {form.watch("triggerEventName") ===
                  RulesEngineEventTypeEnum.UserFinishedOnboarding && (
                  <>
                    <TextField
                      containerClassName="grow-0 shrink w-16 space-y-0"
                      label="Delay"
                      control={form.control}
                      name="delayCount"
                      type="number"
                    />
                    <SelectField
                      containerClassName="grow-0 shrink space-y-0"
                      label="Delay Unit"
                      labelClassName="invisible"
                      selectEntries={delayUnitSelectEntries}
                      control={form.control}
                      name="delayUnit"
                    />
                  </>
                )}
                <SelectField
                  containerClassName="flex-grow space-y-0"
                  label="Send Trigger"
                  selectEntries={triggerEventNameSelectEntries}
                  control={form.control}
                  name="triggerEventName"
                />
              </div>
              <div className="flex items-center justify-end gap-4">
                <Button type="submit" size="lg">
                  Save
                </Button>
                <ToggleField
                  label="Active"
                  control={form.control}
                  name="active"
                />
              </div>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
