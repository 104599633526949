import { useCallback, useState } from "react"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { useDropzone } from "react-dropzone"
import { Button } from "~/ui/button"
import { Upload } from "lucide-react"
import { useFileUpload } from "~/editor/useFileUpload"
import toast from "react-hot-toast"
import { AhoyEventTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"
import { Translation } from "~/common/Translation"
export const ONBOARDING_CONTENT_UPLOAD_QUERY = gql(`
  query OnboardingContentUploads {
    onboardingContentUploads {
      nodes {
        id
        attachment {
          filename
        }
      }
    }
  }
`)

export const FileUploader = () => {
  const { logEvent } = useLogEvent()
  const [currentFile, setCurrentFile] = useState<{
    name: string
    progress?: number
  } | null>(null)
  const { refetch } = useQuery(ONBOARDING_CONTENT_UPLOAD_QUERY)
  const { uploadAndAttachFile, uploadAndAttachImage } = useFileUpload()

  const onDrop = useCallback(
    async (files: File[]) => {
      if (files.length === 0) return

      const file = files[0] // Only take the first file
      setCurrentFile({ name: file.name })

      const isImage = file.type.startsWith("image/")

      const data = isImage
        ? await uploadAndAttachImage(file, true)
        : await uploadAndAttachFile(file, true)

      if (data) {
        logEvent(AhoyEventTypeEnum.ApplicationContentUploaded, {
          file_type: file.type,
        })
        toast.success("File uploaded successfully!")
        await refetch()
        setCurrentFile(null)
      } else {
        setCurrentFile(null)
      }
    },
    [uploadAndAttachImage, uploadAndAttachFile, refetch, logEvent]
  )

  const ccls = useCommunityClassname()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    maxSize: 52428800, // 50MB
    onDropRejected: () => {
      toast.error("File is too large. Maximum size is 50MB.")
    },
  })

  return (
    <div
      {...getRootProps()}
      className={cn(
        "border-2 border-dashed border-mercury bg-porcelain rounded-lg p-6 py-12 text-center",
        ccls({
          [CommunitySlug.Gotomillions]: "border-primary",
          default: "",
        })
      )}
    >
      <div className="space-y-4">
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="text-sm text-pretext-light-gray">Drop file here...</p>
        ) : (
          <>
            <div className="flex justify-center">
              <Button
                type="button"
                variant="ghost"
                className={cn(
                  "cursor-pointer bg-highlight hover:bg-highlight/80 rounded-full p-3",
                  ccls({
                    [CommunitySlug.Gotomillions]:
                      "bg-primary hover:bg-primary/80",
                    [CommunitySlug.Fintechtakes]:
                      "bg-primary hover:bg-primary/80",
                    default: "",
                  })
                )}
                onClick={(e) => e.preventDefault()}
              >
                <Upload className="h-6 w-6 text-white" />
              </Button>
            </div>
            <div className="space-y-2">
              <p className="text-lg">
                <Translation ns="fileUploader" i18nKey="title" />
              </p>
              <p className="text-xs">
                <Translation ns="fileUploader" i18nKey="subtitle" />
              </p>
            </div>
          </>
        )}
        {currentFile && (
          <div className="mt-4">
            <div className="flex items-center justify-center gap-2 text-sm">
              <span>{currentFile.name}</span>
              {currentFile.progress !== undefined && (
                <span>{Math.round(currentFile.progress)}%</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
