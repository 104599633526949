import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import {
  AhoyEventTypeEnum,
  ArticleApproveInput,
  ArticleVisibilityEnum,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import toast from "react-hot-toast"
import Bookmark from "../images/icons/bookmark.svg?react"
import { Eye } from "lucide-react"
import DollarSquare from "../images/icons/dollar-square.svg?react"
import { ReactNode, useEffect, useRef, useState } from "react"
import {
  ButtonGroupToggle,
  ButtonToggleGroupProps,
} from "~/ui/ButtonGroupToggle"
import { cn } from "~/lib/utils"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { ARTICLE_QUERY_DOCUMENT } from "~/screens/ArticleEditorScreen"
import { isArticleFeatured } from "~/content/useToggleFeaturedConfirm"
import { addWeeks } from "date-fns"

const PREMIUM_DEFAULT = false
const FEATURED_DEFAULT = false
const VISIBILITY_DEFAULT = ArticleVisibilityEnum.Hidden

export const ArticlePublishModal = ({
  isOpen,
  setIsOpen,
  articleId,
  onSuccess,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  articleId: string
  onSuccess?: () => void
}) => {
  const initialValuesSetRef = useRef(false)
  const [runApproveContent] = useSafeMutation(APPROVE_ARTICLE_MUTATION)
  const { logEvent } = useLogEvent()
  const { data } = useQuery(ARTICLE_QUERY_DOCUMENT, {
    variables: { articleId },
  })

  const [premium, setPremium] = useState(PREMIUM_DEFAULT)
  const [featured, setFeatured] = useState(FEATURED_DEFAULT)
  const [visibility, setVisibility] = useState(VISIBILITY_DEFAULT)

  const onOpenChange = (open: boolean) => {
    if (open === false) {
      const featured = data?.article
        ? isArticleFeatured(data.article.featuredUntil)
        : FEATURED_DEFAULT
      setFeatured(featured)
      setPremium(data?.article?.premium ?? PREMIUM_DEFAULT)
      setVisibility(data?.article?.visibility ?? VISIBILITY_DEFAULT)
    }
    setIsOpen(open)
  }

  const onSubmit = async () => {
    const input: ArticleApproveInput = {
      articleId,
      premium,
      visibility,
      featuredUntil: featured ? addWeeks(new Date(), 2) : new Date(),
    }
    const { errors } = await runApproveContent({ variables: { input } })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success("Content approved")
      logEvent(AhoyEventTypeEnum.ContentSubmissionApproved, {
        content_id: articleId,
      })
      onSuccess?.()
      onOpenChange(false)
    }
  }

  useEffect(() => {
    if (initialValuesSetRef.current || !data?.article) return
    initialValuesSetRef.current = true

    setPremium(data.article.premium)
    setFeatured(isArticleFeatured(data.article.featuredUntil))
    setVisibility(data.article.visibility)
  }, [data?.article])

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-xl gap-0" noCloseButton>
        <div className="text-center mb-6 font-serif text-3xl">
          Ready to Publish?
        </div>

        {data?.article ? (
          <>
            <div className="font-semibold mb-2">Publication Settings</div>

            <div className="border border-mercury rounded-lg">
              <SettingRow
                settingTitle="Premium Content"
                settingDescription="Require a Plus or Pro membership to access full content."
                icon={<DollarSquare />}
                leftButtonCopy="Free"
                rightButtonCopy={<DollarSquare className="h-[20px] w-[20px]" />}
                leftButtonOnClick={() => setPremium(false)}
                rightButtonOnClick={() => setPremium(true)}
                activeButton={premium ? "right" : "left"}
              />
              <SettingRow
                settingTitle="Featured"
                settingDescription="Show featured flag."
                icon={<Bookmark className="h-[25px] w-[25px]" />}
                leftButtonCopy="Off"
                rightButtonCopy={<Bookmark />}
                leftButtonOnClick={() => setFeatured(false)}
                rightButtonOnClick={() => setFeatured(true)}
                activeButton={featured ? "right" : "left"}
              />
              <SettingRow
                settingTitle="Visibility"
                settingDescription="Set content Live in the Library."
                icon={<Eye />}
                leftButtonCopy="Hide"
                rightButtonCopy="Live"
                leftButtonOnClick={() =>
                  setVisibility(ArticleVisibilityEnum.Hidden)
                }
                rightButtonOnClick={() =>
                  setVisibility(ArticleVisibilityEnum.Live)
                }
                activeButton={
                  visibility === ArticleVisibilityEnum.Hidden ? "left" : "right"
                }
              />
            </div>

            <div className="flex justify-between mt-10">
              <Button
                type="button"
                variant="link"
                onClick={() => onOpenChange(false)}
              >
                Close
              </Button>
              <Button type="button" className="px-10" onClick={onSubmit}>
                {visibility === ArticleVisibilityEnum.Live
                  ? "Confirm Publish Settings"
                  : "Save Publish Preferences"}
              </Button>
            </div>
          </>
        ) : (
          <LoadingIndicator />
        )}
      </DialogContent>
    </Dialog>
  )
}

const SettingRow = ({
  icon,
  settingTitle,
  settingDescription,
  bottomBorder = true,
  ...toggleProps
}: {
  icon: ReactNode
  settingTitle: string
  settingDescription: string
  bottomBorder?: boolean
} & ButtonToggleGroupProps) => {
  return (
    <div
      className={cn(
        "border-mercury flex items-center py-3 pl-4 pr-2 gap-4",
        bottomBorder && "border-b"
      )}
    >
      <div className="w-[40px] flex justify-center text-pretext-light-gray">
        {icon}
      </div>

      <div className="flex-1">
        <div className="text-sm font-semibold">{settingTitle}</div>
        <div className="text-2xs text-pretext-light-gray">
          {settingDescription}
        </div>
      </div>

      <ButtonGroupToggle {...toggleProps} />
    </div>
  )
}

const APPROVE_ARTICLE_MUTATION = gql(`
  mutation ArticleApprove($input: ArticleApproveInput!) {
    articleApprove(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)
