import { add, parseISO, startOfDay } from "date-fns"
import { forwardRef, useState } from "react"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { CalendarDateField } from "~/ui/CalendarDateField"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  AhoyEventTypeEnum,
  UserIntroductionsStateEnum,
} from "~/__generated__/graphql"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { Button } from "~/ui/button"
import { AlarmClock, HeartHandshake, Lock } from "lucide-react"
import { Switch } from "~/ui/switch"
import { FieldPointer } from "~/ui/Onboarding"

export const useIntroductionsSnoozedUntilMutation = () => {
  const currentUser = useCurrentUser()
  const [date, setDate] = useState<Date | null>(
    currentUser.introductionsSnoozedUntil
      ? parseISO(currentUser.introductionsSnoozedUntil)
      : null
  )

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const { logEvent } = useLogEvent()

  const updateIntroductionsSnoozedUntil = async (newDate: Date | null) => {
    const previousDate = date
    setDate(newDate)
    const introductionsSnoozedUntilVal = newDate ? startOfDay(newDate) : null

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          introductionsSnoozedUntil: introductionsSnoozedUntilVal,
          introductionsState: introductionsSnoozedUntilVal
            ? UserIntroductionsStateEnum.ActivelySnooze
            : UserIntroductionsStateEnum.Active,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      setDate(previousDate)
    } else {
      if (introductionsSnoozedUntilVal) {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "SNOOZED",
        })
      } else {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "ACTIVE",
        })
      }
    }
  }

  return { updateIntroductionsSnoozedUntil, date, setDate }
}

export const useIntroductionsStateMutation = () => {
  const currentUser = useCurrentUser()
  const [date, setDate] = useState<Date | null>(
    currentUser.introductionsSnoozedUntil
      ? parseISO(currentUser.introductionsSnoozedUntil)
      : null
  )

  const [introductionsState, setIntroductionsState] =
    useState<UserIntroductionsStateEnum>(currentUser.introductionsState)

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const { logEvent } = useLogEvent()

  const updateIntroductionsState = async (
    newState: UserIntroductionsStateEnum,
    newDate: Date | null
  ) => {
    const previousState = introductionsState
    const previousDate = date
    setIntroductionsState(newState)
    setDate(newDate)

    if (!currentUser.onboardingActions?.optIntoIntroductions) {
      logEvent(AhoyEventTypeEnum.NewUserChecklistItemCompleted, {
        step_name: "Opt Into Intros",
      })
    }

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          introductionsSnoozedUntil: newDate ? startOfDay(newDate) : null,
          introductionsState: newState,
          ...(!currentUser.onboardingActions?.optIntoIntroductions && {
            optedIntoIntros: true,
          }),
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      setIntroductionsState(previousState)
      setDate(previousDate)
    } else {
      if (newState === UserIntroductionsStateEnum.ActivelySnooze) {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "SNOOZED",
        })
      } else {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "ACTIVE",
        })
      }
    }
  }

  return {
    updateIntroductionsState,
    introductionsState,
    date,
  }
}

export const IntroductionsSettingsModule = forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      updateIntroductionsState,
      introductionsState,
      date: snoozeDate,
    } = useIntroductionsStateMutation()
    const { openSubscriptionWizard } = useSubscription()
    const currentUser = useCurrentUser()
    const canUseIntroductions = currentUser.permissions.canUseIntroductions

    return (
      <Card ref={ref}>
        <CardHeader>
          <CardTitle>Introductions Settings</CardTitle>
        </CardHeader>
        <CardContent className="pt-4">
          {canUseIntroductions ? (
            <>
              <>
                <div className="text-textLight font-semibold mb-2">
                  Receiving curated introductions are a Plus Membership benefit.
                </div>
                <div className="mb-4">
                  If you'd like to opt in, make sure your toggle is set to
                  "Participate." If you need to pause receiving introductions,
                  shift your toggle towards "Snooze." Your preference is saved
                  until you change it in the future.
                </div>
                <div className="relative">
                  <FieldPointer
                    show={
                      introductionsState ===
                      UserIntroductionsStateEnum.DefaultSnooze
                    }
                    className="before:top-1"
                  >
                    <Switch
                      className="border-input mb-4"
                      checked={
                        introductionsState === UserIntroductionsStateEnum.Active
                      }
                      onClick={() => {
                        if (
                          [
                            UserIntroductionsStateEnum.DefaultSnooze,
                            UserIntroductionsStateEnum.ActivelySnooze,
                          ].includes(introductionsState)
                        ) {
                          updateIntroductionsState(
                            UserIntroductionsStateEnum.Active,
                            null
                          )
                        } else {
                          updateIntroductionsState(
                            UserIntroductionsStateEnum.ActivelySnooze,
                            add(new Date(), { months: 1 })
                          )
                        }
                      }}
                      variant="selector"
                      size="xl"
                      options={[
                        <div className="flex items-center justify-center gap-1">
                          <AlarmClock className="w-4 h-4" />
                          Snooze
                        </div>,
                        <div className="flex items-center justify-center gap-1">
                          <HeartHandshake className="w-4 h-4" />
                          Participate
                        </div>,
                      ]}
                    />
                  </FieldPointer>
                </div>
              </>
              {snoozeDate && (
                <>
                  <div className="text-textLight font-semibold mb-2">
                    Snooze Until?
                  </div>
                  <CalendarDateField
                    date={snoozeDate}
                    onChangeDate={(date) => {
                      if (date) {
                        updateIntroductionsState(
                          UserIntroductionsStateEnum.ActivelySnooze,
                          date
                        )
                      }
                    }}
                    fromYear={new Date().getFullYear()}
                    toYear={new Date().getFullYear() + 2}
                    disabled={{
                      before: new Date(),
                      after: add(new Date(), { years: 2 }),
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <Button
              type="button"
              onClick={() =>
                openSubscriptionWizard("PricingTableStep", {
                  source: "IntroductionSettingsModule",
                  requiredFeature: "canUseIntroductions",
                })
              }
            >
              Enable Introductions <Lock className="w-4 h-4 ml-1" />
            </Button>
          )}
        </CardContent>
      </Card>
    )
  }
)
