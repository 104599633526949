import { zodResolver } from "@hookform/resolvers/zod"
import { camelCase } from "change-case"
import { useMemo } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import {
  ApplicationFieldEnum,
  FitProfileInput,
  IndustryEnum,
  JobFunctionEnum,
  User_CurrentUserFragment,
  VerticalEnum,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { useCommunity } from "~/community/useCommunity"
import { SearchableSelectField } from "~/components/forms/SearchableSelectField"
import { SelectField } from "~/components/forms/SelectField"
import { TextField } from "~/components/forms/TextField"
import { Button } from "~/ui/button"
import { Card } from "~/ui/card"

export const useMissingOnboardingExperienceFields = () => {
  const currentUser = useCurrentUser()
  const { applicationFields: fields } = useCommunity()

  const missingFields = useMemo(() => {
    if (!currentUser || !currentUser.fitProfile) {
      return fields
    }

    return fields.filter((field) => {
      const fitProfileField = camelCase(
        field
      ) as keyof typeof currentUser.fitProfile
      return !currentUser.fitProfile![fitProfileField]
    })
  }, [currentUser, fields])

  return missingFields
}

const experienceFormSchema = z
  .object({
    yearsOfExperience: z.string(),
    yearsOfExperienceRequired: z.boolean(),
    industry: z.string().optional(),
    industryRequired: z.boolean(),
    linkedinProfileUrl: z.string().optional(),
    linkedinProfileUrlRequired: z.boolean(),
    companySize: z.string().optional(),
    companySizeRequired: z.boolean(),
    vertical: z.string().optional(),
    verticalRequired: z.boolean(),
    jobFunction: z.string().optional(),
    jobFunctionRequired: z.boolean(),
  })
  .superRefine((data, ctx) => {
    const requirementKeys = Object.keys(data).filter(
      // @ts-expect-error zod types are not correct
      (k) => k.endsWith("Required") && data[k]
    )
    requirementKeys.forEach((requirementKey) => {
      const associatedPath = requirementKey.replace(/Required$/, "")
      // @ts-expect-error zod types are not correct
      const pathData = data[associatedPath]
      if (!pathData || pathData.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "This field is required",
          path: [associatedPath],
        })
      }
    })
  })
type ExperienceFormValues = z.infer<typeof experienceFormSchema>

interface OnboardingExperienceQuestionsProps {
  onSave: (formValues: ExperienceFormValues) => void
  footer?: (loading: boolean) => React.ReactNode
  withCard?: boolean
  fitProfileDefaults?: User_CurrentUserFragment["fitProfile"]
  onlyMissingFields?: boolean
}

export const OnboardingExperienceQuestions = ({
  onSave,
  footer,
  withCard = true,
  fitProfileDefaults,
  onlyMissingFields = false,
}: OnboardingExperienceQuestionsProps) => {
  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)
  const { applicationFields: fields } = useCommunity()
  const { t } = useTranslation("onboarding")
  const { t: industries } = useTranslation("industries")
  const { t: jobFunctions } = useTranslation("job_functions")
  const { t: verticals } = useTranslation("verticals")

  const defaultValues: ExperienceFormValues = {
    yearsOfExperience: fitProfileDefaults?.yearsOfExperience || "",
    yearsOfExperienceRequired: fields.includes(
      ApplicationFieldEnum.YearsOfExperience
    ),
    industry: fitProfileDefaults?.industry || "",
    industryRequired: fields.includes(ApplicationFieldEnum.Industry),
    linkedinProfileUrl: fitProfileDefaults?.linkedinProfileUrl || "",
    linkedinProfileUrlRequired: fields.includes(
      ApplicationFieldEnum.LinkedinProfileUrl
    ),
    companySize: fitProfileDefaults?.companySize || "",
    companySizeRequired: fields.includes(ApplicationFieldEnum.CompanySize),
    vertical: fitProfileDefaults?.vertical || "",
    verticalRequired: fields.includes(ApplicationFieldEnum.Vertical),
    jobFunction: fitProfileDefaults?.jobFunction || "",
    jobFunctionRequired: fields.includes(ApplicationFieldEnum.JobFunction),
  }
  const form = useForm<ExperienceFormValues>({
    defaultValues,
    resolver: zodResolver(experienceFormSchema),
  })

  const onSubmit: SubmitHandler<ExperienceFormValues> = async (formData) => {
    const fitProfile: FitProfileInput = {}
    if (fields.includes(ApplicationFieldEnum.YearsOfExperience))
      fitProfile.yearsOfExperience = formData.yearsOfExperience
    if (fields.includes(ApplicationFieldEnum.Industry))
      fitProfile.industry = formData.industry as IndustryEnum
    if (fields.includes(ApplicationFieldEnum.LinkedinProfileUrl))
      fitProfile.linkedinProfileUrl = formData.linkedinProfileUrl
    if (fields.includes(ApplicationFieldEnum.CompanySize))
      fitProfile.companySize = formData.companySize
    if (fields.includes(ApplicationFieldEnum.Vertical))
      fitProfile.vertical = formData.vertical as VerticalEnum
    if (fields.includes(ApplicationFieldEnum.JobFunction))
      fitProfile.jobFunction = formData.jobFunction as JobFunctionEnum

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          fitProfile,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      onSave(formData)
    }
  }

  const missingFields = useMissingOnboardingExperienceFields()

  const { displayedFields, hiddenFields } = useMemo(() => {
    return {
      displayedFields: onlyMissingFields ? missingFields : fields,
      hiddenFields: onlyMissingFields
        ? fields.filter((field) => !missingFields.includes(field))
        : [],
    }
  }, [fields, missingFields, onlyMissingFields])

  const Comp = withCard ? Card : "div"

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Comp className={withCard ? "p-6 pb-2" : ""}>
          {displayedFields.map((field) => {
            switch (field) {
              case ApplicationFieldEnum.YearsOfExperience:
                return (
                  <SelectField
                    key={field}
                    control={form.control}
                    name="yearsOfExperience"
                    containerClassName="mb-4"
                    label={t("experienceScreen.yearsOfExperience")}
                    placeholder="Select years of experience..."
                    required
                    selectEntries={YEARS_OF_EXPERIENCE_OPTIONS.map(
                      (option) => ({
                        label: option,
                        value: option,
                      })
                    )}
                  />
                )
              case ApplicationFieldEnum.CompanySize:
                return (
                  <SelectField
                    key={field}
                    control={form.control}
                    name="companySize"
                    containerClassName="mb-4"
                    label={t("experienceScreen.companySize")}
                    placeholder="Select company size..."
                    required
                    selectEntries={COMPANY_SIZE_OPTIONS.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                  />
                )
              case ApplicationFieldEnum.Industry:
                return (
                  <SearchableSelectField
                    key={field}
                    control={form.control}
                    name="industry"
                    containerClassName="mb-4"
                    label={t("experienceScreen.industry")}
                    placeholder="Select industry..."
                    required
                    selectEntries={Object.values(IndustryEnum).map(
                      (option) => ({
                        label: industries(option),
                        value: option,
                      })
                    )}
                  />
                )
              case ApplicationFieldEnum.JobFunction:
                return (
                  <SelectField
                    key={field}
                    control={form.control}
                    name="jobFunction"
                    containerClassName="mb-4"
                    label={t("experienceScreen.jobFunction")}
                    placeholder="Select job function..."
                    required
                    selectEntries={Object.values(JobFunctionEnum).map(
                      (option) => ({
                        label: jobFunctions(option),
                        value: option,
                      })
                    )}
                  />
                )
              case ApplicationFieldEnum.Vertical:
                return (
                  <SelectField
                    key={field}
                    control={form.control}
                    name="vertical"
                    containerClassName="mb-4"
                    label={t("experienceScreen.vertical")}
                    placeholder="Select industry vertical..."
                    required
                    selectEntries={Object.values(VerticalEnum).map(
                      (option) => ({
                        label: verticals(option),
                        value: option,
                      })
                    )}
                  />
                )
              case ApplicationFieldEnum.LinkedinProfileUrl:
                return (
                  <TextField
                    key={field}
                    control={form.control}
                    name="linkedinProfileUrl"
                    containerClassName="mb-4"
                    required
                    label={t("experienceScreen.linkedinProfileUrl")}
                  />
                )
              default:
                return null
            }
          })}

          {hiddenFields.map((field) => (
            <input
              type="hidden"
              key={field}
              name={camelCase(field)}
              value={
                defaultValues[
                  camelCase(field) as keyof ExperienceFormValues
                ] as string
              }
            />
          ))}
        </Comp>

        {footer ? (
          footer(loading)
        ) : (
          <div className="flex justify-center items-center pt-4">
            <Button type="submit" disabled={loading} size="lg">
              Submit
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

export const YEARS_OF_EXPERIENCE_OPTIONS = [
  "<1",
  "1 - 4",
  "5 - 10",
  "11 - 15",
  "15+",
]

export const COMPANY_SIZE_OPTIONS = [
  "1 - 10",
  "11 - 50",
  "51 - 250",
  "251 - 1k",
  "1k - 5k",
  "5k - 50k",
  "50k - 100k",
]
