import { User_AvatarFragment } from "~/__generated__/graphql"
import {
  AvatarPlaceholder,
  AvatarWithFallback,
  AvatarWithFallbackProps,
} from "./AvatarWithFallback"
import { useRef, useState, useCallback, useEffect } from "react"

interface AvatarStackProps extends Omit<AvatarWithFallbackProps, "user"> {
  users: User_AvatarFragment[]
  overlap?: number
  totalCount: number
}

export const AvatarStack = ({
  users,
  overlap = 8,
  ...avatarWithFallbackProps
}: AvatarStackProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const testAvatarRef = useRef<HTMLDivElement>(null)
  const [, setAvatarWidth] = useState(0)
  const [avatarHeight, setAvatarHeight] = useState(0)
  const [visibleUsers, setVisibleUsers] = useState<User_AvatarFragment[]>([])
  const [additionalUsersCount, setAdditionalUsersCount] = useState(0)

  const calculateVisibleUsers = useCallback(() => {
    if (!wrapperRef.current || !testAvatarRef.current) return

    const wrapperWidth = wrapperRef.current.clientWidth
    const avatarWidth = testAvatarRef.current.clientWidth
    const avatarHeight = testAvatarRef.current.clientHeight
    setAvatarWidth(avatarWidth)
    setAvatarHeight(avatarHeight)

    const maxVisibleUsers = Math.floor(wrapperWidth / (avatarWidth - overlap))

    // If the number of users would fit exactly, show all of them
    if (users.length <= maxVisibleUsers) {
      setVisibleUsers(users)
      setAdditionalUsersCount(0)
      return
    } else if (users.length > maxVisibleUsers) {
      setVisibleUsers(users.slice(0, maxVisibleUsers - 1))
      setAdditionalUsersCount(Math.max(users.length - maxVisibleUsers, 0))
    }
  }, [users, overlap])

  const [observer] = useState(() => {
    return new ResizeObserver(() => {
      calculateVisibleUsers()
    })
  })

  useEffect(() => {
    // the ref is not updated in the same react lifecycle event that the state changes and the ref cannot be observed
    setTimeout(() => {
      if (!testAvatarRef.current) return
      observer.observe(testAvatarRef.current)
    })

    return observer.disconnect()
  }, [observer])

  if (!users.length) return null

  return (
    <div
      ref={wrapperRef}
      className="flex flex-row"
      style={{ height: avatarHeight }}
    >
      <AvatarWithFallback
        ref={testAvatarRef}
        user={users[0]}
        {...avatarWithFallbackProps}
        className="absolute left-0 top-0 opacity-0"
      />
      {visibleUsers.map((user, index) => (
        <AvatarWithFallback
          key={user.id}
          user={user}
          {...avatarWithFallbackProps}
          className="relative"
          style={{ zIndex: index, marginLeft: -overlap }}
        />
      ))}
      {additionalUsersCount > 0 && (
        <AvatarPlaceholder
          className="relative"
          {...avatarWithFallbackProps}
          style={{ zIndex: visibleUsers.length, marginLeft: -overlap }}
        >
          +{additionalUsersCount}
        </AvatarPlaceholder>
      )}
    </div>
  )
}
