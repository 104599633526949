import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { applicationLandingPath, signInTokenPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/ui/button"
import { OnboardingFieldLabel, SignInContent } from "~/ui/Onboarding"
import { OnboardingLayout } from "../layouts/OnboardingLayout"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "~/ui/Link"
import { Alert } from "~/ui/alert"
import { CircleAlert } from "lucide-react"

const SEND_MAGIC_LINK_MUTATION = gql(`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input) {
      sessionId
    }
  }
`)

export const SignInScreen = () => {
  const [sendMagicLink, { loading }] = useSafeMutation(SEND_MAGIC_LINK_MUTATION)
  const [email, setEmail] = useState("")
  const [linkError, setLinkError] = useState("")
  const navigate = useNavigate()
  const { t } = useTranslation("signin")

  const onSubmit = async () => {
    const { data } = await sendMagicLink({
      variables: {
        input: {
          email,
        },
      },
    })

    if (data?.sendMagicLink.sessionId) {
      setLinkError("")
      navigate(signInTokenPath({ sessionId: data.sendMagicLink.sessionId }))
    } else if (data?.sendMagicLink) {
      setLinkError("Whoops, we didn't find that email")
    } else {
      setLinkError("Error Sending Magic Link")
    }
  }

  return (
    <OnboardingLayout step={0}>
      <SignInContent>
        <div className="sign-in-content__header">{t("signinTitle")}</div>
        <div className="mt-6">
          <Trans i18nKey="signinSubtitle" ns="signin" />
        </div>
        <div className="sign-in-content__form-container">
          {linkError !== "" && (
            <Alert variant="destructive" className="mb-4">
              <CircleAlert className="w-4 h-4" />
              <div>{linkError}</div>
            </Alert>
          )}
          <form>
            <label className="onboarding-field">
              <OnboardingFieldLabel className="text-dark-gray">
                Email
              </OnboardingFieldLabel>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="onboarding-field__input text-dark-gray"
                placeholder="Enter your email"
              />
            </label>
            <Button
              className="w-full mt-9"
              theme="onboarding"
              onClick={onSubmit}
              disabled={email.length === 0 || loading}
              type="submit"
            >
              Sign In
            </Button>
          </form>
          <div className="sign-in-callout">
            Not a member yet?{" "}
            <Link href={applicationLandingPath.pattern}>Sign Up</Link>
          </div>
        </div>
      </SignInContent>
    </OnboardingLayout>
  )
}
