import { ReactNode } from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"
import { cn } from "~/lib/utils"

export const MessageSectionHeader = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => {
  const ccls = useCommunityClassname()
  return (
    <div
      className={cn(
        "flex items-center px-4 h-[75px] border-b border-mercury tracking-wide",
        className,
        ccls({
          [CommunitySlug.Gotomillions]: "bg-card",
          default: "bg-white",
        })
      )}
    >
      {children}
    </div>
  )
}
