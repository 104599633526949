import { Link } from "react-router-dom"
import { BookmarkTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import { bookmarksPath, bookmarksTypedPath } from "~/common/paths"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"

type TabMap = { [key in BookmarkTypeEnum]: string }

const TAB_LIST: TabMap = {
  [BookmarkTypeEnum.All]: "All Bookmarks",
  [BookmarkTypeEnum.Posts]: "Posts",
  [BookmarkTypeEnum.Articles]: "Library Content",
}

export const BookmarkTabs = ({
  currentTab,
}: {
  currentTab: BookmarkTypeEnum
}) => {
  return (
    <div className="flex border-b border-mercury my-4 justify-center gap-8">
      {Object.entries(TAB_LIST).map(([bookmarkType, label]) => (
        <Tab
          key={bookmarkType}
          bookmarkType={bookmarkType as BookmarkTypeEnum}
          text={label}
          active={bookmarkType === currentTab}
        />
      ))}
    </div>
  )
}

const Tab = ({
  bookmarkType,
  text,
  active,
}: {
  bookmarkType: BookmarkTypeEnum
  text: string
  active: boolean
}) => {
  const ccls = useCommunityClassname()
  const to =
    bookmarkType === BookmarkTypeEnum.All
      ? bookmarksPath.pattern
      : bookmarksTypedPath({ bookmarkType })

  const activeClasses = ccls({
    [CommunitySlug.Boardroom]: "border-white text-white",
    [CommunitySlug.Gotomillions]: "border-white text-white",
    default: "border-primary text-primary",
  })

  return (
    <Link
      to={to}
      className={cn(
        "border-b-4 border-transparent font-semibold py-1",
        ccls({
          [CommunitySlug.Boardroom]: "text-white",
          [CommunitySlug.Gotomillions]: "text-white",
          default: "",
        }),
        {
          [activeClasses]: active,
          "font-bold": active,
        }
      )}
    >
      {text}
    </Link>
  )
}
