import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { ArticleEditor } from "~/content/ArticleEditor"
import { ArticleContentTypeEnum } from "~/__generated__/graphql"
import { CourseEditor } from "~/content/CourseEditor"

export const ArticleEditorScreen = () => {
  const { articleId } = useParams()
  invariant(articleId)

  const { data, loading, error } = useQuery(ARTICLE_QUERY_DOCUMENT, {
    variables: { articleId },
    fetchPolicy: "network-only",
  })

  if (error) return <Error message="Error loading article." />
  if (loading) return <LoadingIndicatorCentered />

  const article = data?.article
  invariant(article)

  if (
    article.revision.contentType === ArticleContentTypeEnum.Course &&
    article.draftCourse
  ) {
    return <CourseEditor article={article} />
  } else {
    return <ArticleEditor article={article} />
  }
}

export const ARTICLE_QUERY_DOCUMENT = gql(`
  query ArticleFullDocument($articleId: ID!) {
    article(articleId: $articleId) {
      ...Article_EditorContent
      ...Article_CourseEditorContent
      ...Article_ReviewActions
    }
  }
`)

gql(`
  fragment Article_CourseEditorContent on Article {
    id
    draftCourse {
      ...Course_EditorContent
    }
  }
`)

gql(`
  fragment Article_EditorContent on Article {
    id
    state
    visibility
    featuredUntil
    premium
    isLockedBySomeoneElse
    allowExternalShare
    markedLiveAt
    createdAt
    lockedByUser {
      ...User_Avatar
    }
    collaborators(includePending: true) {
      ...ArticleCollaborator_AddModal
    }
    draftCourse {
      id
      article {
        id
      }
    }
    draftLesson {
      id
      hidden

      section {
        id
        hidden

        course {
          id
          articleId
        }
      }
    }
    revision: draftRevision {
      id
      title
      description
      contentVersion
      landscapeImageUrl
      landscapeImageId
      portraitImageUrl
      portraitImageId
      tag {
        id
        name
      }
      contentType
      editorjsContent
    }
    approvedRevision {
      id
      title
    }
    currentUserCanViewRevisionLogs {
      value
    }
    currentUserCanApprove {
      value
    }
    currentUserCanSubmitForApproval {
      value
    }
    currentUserCanRequestEdits {
      value
    }
    currentUserCanChangeVisibility {
      value
    }
    currentUserCanMarkAsFeatured {
      value
    }
    currentUserCanToggleHidden {
      value
    }
    currentUserCanMarkAsPremium {
      value
    }
    currentUserCanChangeExternalShareSetting {
      value
    }
    currentUserCanArchive {
      value
    }
  }
`)
