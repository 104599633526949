/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { SelectionUtils } from "../SelectionUtils"

export class ColorTool {
  static get isInline() {
    return true
  }

  get state() {
    return this._state
  }

  static get sanitize() {
    return {
      font: {
        class: "ww-colored-font",
        style: true,
      },
      mark: {
        class: "ww-bg-color",
        style: true,
      },
    }
  }

  set state(state) {
    this._state = state

    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state)
  }

  constructor({ config, api }) {
    this.api = api
    this.config = config
    this.button = null
    this._state = false
    this.selection = new SelectionUtils()

    this.tag = "FONT"
    this.class = "ww-colored-font"
  }

  render() {
    this.button = document.createElement("button")
    this.button.type = "button"
    this.button.innerHTML =
      '<svg width="20" height="18"><path d="M10.458 12.04l2.919 1.686-.781 1.417-.984-.03-.974 1.687H8.674l1.49-2.583-.508-.775.802-1.401zm.546-.952l3.624-6.327a1.597 1.597 0 0 1 2.182-.59 1.632 1.632 0 0 1 .615 2.201l-3.519 6.391-2.902-1.675zm-7.73 3.467h3.465a1.123 1.123 0 1 1 0 2.247H3.273a1.123 1.123 0 1 1 0-2.247z"/></svg>'
    this.button.classList.add(this.api.styles.inlineToolButton)

    return this.button
  }

  surround(range) {
    if (this.state) {
      this.selection.restore()
      this.unwrap(range)
    } else {
      this.selection.save()
      this.wrap(range)
    }
  }

  wrap(range) {
    const selectedText = range.extractContents()
    const mark = document.createElement(this.tag)

    mark.classList.add(this.class)
    mark.appendChild(selectedText)
    range.insertNode(mark)

    this.api.selection.expandToTag(mark)

    this.mark = mark
  }

  unwrap(_range) {
    const mark = this.api.selection.findParentTag(this.tag, this.class)
    this.api.selection.expandToTag(mark)

    const sel = window.getSelection()
    const fullRange = sel.getRangeAt(0)

    const unwrappedContent = fullRange.extractContents()
    mark.remove()

    fullRange.insertNode(unwrappedContent)

    // Restore selection
    sel.removeAllRanges()
    sel.addRange(fullRange)

    this.mark = null
  }

  checkState() {
    const mark = this.api.selection.findParentTag(this.tag)

    this.state = !!mark

    if (this.state) {
      this.maybeRestoreMarkColor(mark)
      this.setMarkColor(mark)
      this.showActions(mark)
      this.selection.save()
    } else {
      this.hideActions()
      this.selectedColor = null
    }

    return this.state
  }

  renderActions() {
    this.actionsContainer = document.createElement("div")
    this.actionsContainer.classList.add("color-tool-actions")

    this.actionsContainer.innerHTML = `
      <div class="color-tool-header">
        Text Color:
      </div>
      <div class="color-tool-entries">
        ${this.config.colors
          .map(
            (c) =>
              `<div class="color-tool-entry" style="background-color: ${c};"></div>`
          )
          .join("")}
      </div>
    `

    this.actionsContainer.addEventListener("click", () => {
      this.selection.restore()
    })

    const colors = this.actionsContainer.querySelectorAll(".color-tool-entry")
    const mark = this.api.selection.findParentTag(this.tag)

    colors.forEach((colorElement) => {
      colorElement.addEventListener("click", (e) => {
        this.selectedColor = e.target.style.backgroundColor
        this.setMarkColor(mark)
      })
    })

    return this.actionsContainer
  }

  maybeRestoreMarkColor = (mark) => {
    if (mark?.style?.color) {
      this.selectedColor = mark.style.color
    }
  }

  setMarkColor = (mark) => {
    if (!this.selectedColor) {
      this.selectedColor = this.config.defaultColor
    }
    if (mark) {
      mark.style.color = this.selectedColor
    }
  }

  showActions(_mark) {
    this.actionsContainer.style.display = "block"
  }

  hideActions() {
    this.actionsContainer.style.display = "none"
  }
}
