import { useQuery } from "@apollo/client"
import { RefObject, useRef, useState, useEffect, useMemo } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  AhoyEventTypeEnum,
  ChannelVisibilityEnum,
  CommunitySlug,
  PostCreateInput,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { FeedSidebar } from "~/feed/FeedSidebar"
import { PostFeed } from "~/feed/PostFeed"
import { TagFilter } from "~/feed/TagFilter"
import { useTagFilter } from "~/feed/useTagFilter"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"
import { ComposerContextProvider } from "~/post-composer/ComposerContext"
import {
  ComposerHandle,
  ComposerOnSave,
  PostComposer,
} from "~/post-composer/PostComposer"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { channelPath } from "~/common/paths"
import { useDebounce } from "use-debounce"
import { SearchInput } from "~/ui/SearchInput"
import { useLogEvent } from "~/analytics/EventsContext"
import { NewPostsIndicator } from "~/feed/NewPostsIndicator"
import { PostInViewportProvider } from "~/feed/PostInViewportContext"
import { UnreadPostsIndicator } from "~/feed/UnreadPostsIndicator"
import { CTAChecklist } from "~/feed/CTAChecklist"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useChannels } from "~/common/ChannelsProvider"
import { Card, CardContent } from "~/ui/card"
import ChatLocked from "../images/icons/chat-locked.svg?react"
import { H6, P } from "~/ui/typography"
import { useCommunityClassname } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { useAuthenticatedLayout } from "~/layouts/AuthenticatedLayout"
import { useConfig } from "~/common/ConfigProvider"

export const FeedScreen = () => {
  const composerRef = useRef<ComposerHandle>(null)

  return (
    <ComposerContextProvider composerRef={composerRef}>
      <PageWithRightSidebar sidebar={<FeedSidebar />}>
        <FeedScreenInner composerRef={composerRef} />
      </PageWithRightSidebar>
    </ComposerContextProvider>
  )
}

const FeedScreenInner = ({
  composerRef,
}: {
  composerRef: RefObject<ComposerHandle>
}) => {
  const { channelSlug } = useParams()
  const { channels, goToChannel } = useChannels()
  const { selectedTagIds, setSelectedTagIds } = useTagFilter()
  const [retweetPostId, setRetweetPostId] = useState<string | null>(null)
  const [retweetArticleId, setRetweetArticleId] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400)
  const topOfFeedRef = useRef<HTMLDivElement>(null)
  const queryVariables = useMemo(
    () => ({
      channelSlug,
      selectedTagIds,
      search: debouncedSearchTerm,
    }),
    [channelSlug, selectedTagIds, debouncedSearchTerm]
  )

  const channel = useMemo(() => {
    if (!channelSlug) return null
    return channels.find((c) => c.slug === channelSlug) || null
  }, [channels, channelSlug])

  const { setIsBlurred } = useAuthenticatedLayout()

  useEffect(() => {
    if (channel) {
      if (!channel.hasCurrentUserJoined) {
        setIsBlurred(true)
        goToChannel(channel.slug)
      } else {
        setIsBlurred(false)
      }
    }

    return () => {
      setIsBlurred(false)
    }
  }, [channel, goToChannel, setIsBlurred])

  const {
    data: currentData,
    previousData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(FEED_QUERY_DOCUMENT, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: !!(channel && !channel.hasCurrentUserJoined),
  })
  const ccls = useCommunityClassname()

  const { logEvent } = useLogEvent()
  const navigate = useNavigate()

  const data = currentData || previousData
  const [searchParams, setSearchParams] = useSearchParams()

  const advertizedChannel = useMemo(() => {
    return channels.find(
      (c) => c.slug !== channelSlug && !c.hasCurrentUserJoined
    )
  }, [channels, channelSlug])

  useEffect(() => {
    const postId = searchParams.get("share_post_id")
    const articleId = searchParams.get("share_article_id")
    if (postId) setRetweetPostId(postId)
    if (articleId) setRetweetArticleId(articleId)
  }, [searchParams])

  useEffect(() => {
    if (data && !loading) {
      if (composerRef && composerRef.current) {
        if (searchParams.get("post") === "focus") {
          composerRef.current.focus()
          searchParams.set("post", "focused")
          setSearchParams(searchParams)
        }
      }
    }
  }, [composerRef, searchParams, setSearchParams, loading, data])

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const [runPostCreate, postCreateResult] =
    useSafeMutation(POST_CREATE_MUTATION)

  const createPost: ComposerOnSave = async (input: PostCreateInput) => {
    const { data, errors } = await runPostCreate({
      variables: {
        input,
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      toast.success("Created post")
      invariant(data?.postCreate.post.id)

      const mentionedUsers = data.postCreate.post.mentionedUsers || []
      const mentionedGroups = data.postCreate.post.mentionedGroups || []

      logEvent(AhoyEventTypeEnum.PostSubmitted, {
        topics_selected: data.postCreate.post.tag?.name,
        post_id: data.postCreate.post.id,
        channel: data.postCreate.post.channel?.name,
        channel_id: data.postCreate.post.channel?.id,
        post_id_retweeted: data.postCreate.post.retweetPostId,
        article_id_retweeted: data.postCreate.post.retweetArticleId,
        is_anon: input.anonymous ? true : false,
        tagged_entities: mentionedUsers
          .map((u) => {
            return { id: u.id, type: "user" }
          })
          .concat(
            mentionedGroups.map((u) => {
              return { id: u.id, type: "group" }
            })
          ),
        number_entities_tagged: mentionedUsers.length + mentionedGroups.length,
      })

      // switch channel if the post was placed in a different channel
      if (
        channel &&
        data.postCreate.post.channel &&
        data.postCreate.post.channel.id !== channel.id
      ) {
        navigate(
          channelPath({ channelSlug: data.postCreate.post.channel.slug })
        )
      }

      setRetweetArticleId(null)
      setRetweetPostId(null)
      setSelectedTagIds([])
      refetch({ selectedTagIds: [] })

      return true
    }
  }

  const postComposerPost = useMemo(
    () => ({ channel, retweetArticleId, retweetPostId }),
    [channel, retweetArticleId, retweetPostId]
  )

  const { qaToolsEnabled } = useConfig()

  if (!data && loading)
    return (
      <div className="mt-8 flex items-center text-foreground">
        <LoadingIndicator /> Loading posts...
      </div>
    )
  if (error || !data) return <Error message="Error loading feed." />

  const posts = data.posts.edges.map((e) => e.node)

  return (
    <PostInViewportProvider>
      <div className="pb-16 w-full flex flex-col gap-4">
        <div className="md:hidden sm:block">
          <CTAChecklist />
        </div>

        <Card>
          <CardContent className="p-4">
            <div className="flex gap-4">
              {channel?.visibility === ChannelVisibilityEnum.Private && (
                <div>
                  <ChatLocked className="w-6 h-6" />
                </div>
              )}
              <div className="flex flex-col gap-4">
                <H6>
                  {channelSlug && channel ? channel.name : "Community Feed"}
                </H6>
                {channel?.description && <P>{channel.description}</P>}
              </div>
            </div>
          </CardContent>
        </Card>

        <PostComposer
          tags={data.tags}
          onSave={createPost}
          isSaving={postCreateResult.loading}
          ref={composerRef}
          key={`composer-${channel?.slug}`}
          post={postComposerPost}
          containerClassName={ccls({
            [CommunitySlug.Gotomillions]: "bg-card",
            default: "",
          })}
          allowAnonymous
          allowAutoTagging
        />

        <div className="md:grid md:grid-rows-none md:grid-cols-2 flex flex-col gap-4">
          <div className="text-xs tracking-wide flex items-center">
            <SearchInput
              placeholder="Search posts..."
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              className={cn(
                "rounded-2xl h-full",
                ccls({
                  [CommunitySlug.Gotomillions]: "bg-card",
                  default: "",
                })
              )}
              inputClassName="rounded-2xl text-xs font-medium"
            />
          </div>
          <TagFilter
            allTags={data.tags}
            selectedTagIds={selectedTagIds}
            setTagIds={setSelectedTagIds}
            containerClassName={ccls({
              [CommunitySlug.Gotomillions]: "bg-card",
              default: "",
            })}
            inputClassName={ccls({
              [CommunitySlug.Gotomillions]: "!bg-card",
              default: "",
            })}
          />
        </div>

        {(!channelSlug || channel?.id) && (
          <NewPostsIndicator
            channelId={channel?.id}
            queryVariables={queryVariables}
            onClick={() =>
              topOfFeedRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          />
        )}
        {channelSlug && <UnreadPostsIndicator channel={channel} />}

        <div ref={topOfFeedRef}>
          <PostFeed
            posts={posts}
            isChannel={!!channelSlug}
            isFiltered={selectedTagIds.length > 0}
            trackReadStates
            trackImpressions
            advertizedChannel={advertizedChannel}
          />
        </div>

        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: { postsCursor: data.posts.pageInfo.endCursor },
            })
          }
          canLoadMore={!loading && data.posts.pageInfo.hasNextPage}
          loadingText="Loading posts..."
          loading={loading && posts.length > 0}
        />
      </div>
      {qaToolsEnabled && (
        <div className="fixed bottom-8 right-8 z-50">
          <div className="flex flex-col items-end space-y-4">
            <h3 className="text-sm font-medium text-gray-500">QA Tools</h3>
            <div className="flex flex-col space-y-2">
              <button
                onClick={async () => {
                  const { errors } = await runUserUpdate({
                    variables: {
                      input: { checklistHidden: false, acknowledgedPwa: false },
                    },
                  })
                  if (!errors) {
                    window.location.reload()
                  }
                }}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-gray-600 text-sm"
              >
                Show Checklist
              </button>
            </div>
          </div>
        </div>
      )}
    </PostInViewportProvider>
  )
}

export const FEED_QUERY_DOCUMENT = gql(`
  query FeedScreen($selectedTagIds: [ID!], $channelSlug: String, $search: String, $postsCursor: String) {
    tags {
      ...TagConnection_Composer
      ...TagConnection_Filter
    }
    posts(selectedTagIds: $selectedTagIds, channelSlug: $channelSlug, search: $search, first: 20, after: $postsCursor) {
      edges {
        node {
          ...Post_Card
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)

const POST_CREATE_MUTATION = gql(`
  mutation PostCreate($input: PostCreateInput!) {
    postCreate(input: $input) {
      currentUser {
        id
        onboardingActions {
          introduceYourself
        }
      }
      post {
        id
        retweetPostId
        retweetArticleId
        mentionedUsers {
          id
        }
        mentionedGroups {
          id
        }
        tag {
          name
        }
        channel {
          id
          name
          slug
        }
      }
    }
  }
`)
