import { useQuery } from "@apollo/client"
import { formatInTimeZone } from "date-fns-tz"
import {
  adminNewIntroductionCyclePath,
  adminIntroductionCyclePath,
  adminEditIntroductionCyclePath,
} from "~/common/paths"
import { INTRODUCTION_CYCLES_ADMIN_LIST_QUERY_DOCUMENT } from "~/introduction-cycles/api"
import { LinkButton } from "~/ui/button"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

import { INTRODUCTIONS_FIXED_TIMEZONE } from "~/introduction-cycles/IntroductionCycleForm"
import { HeartHandshake } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Card } from "~/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"

export const AdminIntroductionsScreen = () => {
  const { data, loading, error, fetchMore } = useQuery(
    INTRODUCTION_CYCLES_ADMIN_LIST_QUERY_DOCUMENT
  )

  const introductionCycles =
    data?.introductionCycles?.edges.map((e) => e.node) || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data)
    return <Error message="Error loading introduction cycles." />

  return (
    <>
      <AdminHeader title="Introductions" Icon={HeartHandshake}>
        <LinkButton to={adminNewIntroductionCyclePath.pattern}>
          New Cycle
        </LinkButton>
      </AdminHeader>

      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Send Date</TableHead>
              <TableHead>Created</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {introductionCycles.map((cycle) => (
              <TableRow key={cycle.id}>
                <TableCell>{cycle.name}</TableCell>
                <TableCell>
                  {formatInTimeZone(
                    cycle.sendAt,
                    INTRODUCTIONS_FIXED_TIMEZONE,
                    "M/d/yyyy p zzz"
                  )}
                </TableCell>
                <TableCell>
                  {formatInTimeZone(
                    cycle.createdAt,
                    INTRODUCTIONS_FIXED_TIMEZONE,
                    "M/d/yyyy p zzz"
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <LinkButton
                      size="sm"
                      to={adminIntroductionCyclePath({
                        introductionCycleId: cycle.id,
                      })}
                    >
                      View
                    </LinkButton>
                    {!cycle.locked && (
                      <LinkButton
                        size="sm"
                        to={adminEditIntroductionCyclePath({
                          introductionCycleId: cycle.id,
                        })}
                      >
                        Edit
                      </LinkButton>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      {data.introductionCycles && (
        <InfiniteLoadMore
          onEndReached={() => {
            if (!data.introductionCycles) {
              return
            }
            fetchMore({
              variables: {
                after: data.introductionCycles.pageInfo.endCursor,
              },
            })
          }}
          canLoadMore={!loading && data.introductionCycles.pageInfo.hasNextPage}
          loadingText="Loading introduction cycles..."
          loading={loading && introductionCycles.length > 0}
        />
      )}
    </>
  )
}
