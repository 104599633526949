import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useCommunity } from "~/community/useCommunity"

export const CommunityLayout = () => {
  const { id } = useCommunity()

  useEffect(() => {
    document.body.dataset.community = id
  }, [id])

  return <Outlet />
}
