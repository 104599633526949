import { formatDate } from "date-fns"
import { useEffect, useMemo } from "react"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { CANCELED_STATUSES, PAST_DUE_STATUSES } from "~/common/stripe"
import { useCommunity } from "~/community/useCommunity"
import { useAuthenticatedLayout } from "~/layouts/AuthenticatedLayout"
import { Button } from "~/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/ui/dialog"
import SadComputer from "~/images/sad-computer.svg?react"
import { useQuery } from "@apollo/client"
import {
  USER_INACTIVE_STRIPE_SUBSCRIPTIONS_QUERY_DOCUMENT,
  useSubscription,
} from "~/subscriptions/SubscriptionProvider"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

export const SubscribeScreen = () => {
  const currentUser = useCurrentUser()
  const community = useCommunity()
  const { setIsBlurred } = useAuthenticatedLayout()
  const { openSubscriptionWizard } = useSubscription()
  const { data, loading } = useQuery(
    USER_INACTIVE_STRIPE_SUBSCRIPTIONS_QUERY_DOCUMENT,
    {
      variables: { userId: currentUser.id },
    }
  )

  const mostRecentlyCanceledSubscription = useMemo(() => {
    if (loading) return null
    return data?.user?.inactiveStripeSubscriptions?.[0]
  }, [data, loading])

  const canceledAt = useMemo(
    () => mostRecentlyCanceledSubscription?.canceledAt,
    [mostRecentlyCanceledSubscription]
  )

  useEffect(() => {
    setIsBlurred(true)
    return () => setIsBlurred(false)
  }, [setIsBlurred])

  const stripeSubscriptionStatus = useMemo(
    () => mostRecentlyCanceledSubscription?.status || "",
    [mostRecentlyCanceledSubscription]
  )

  const statusHistory = useMemo(
    () =>
      [...(mostRecentlyCanceledSubscription?.statusHistory || [])].sort(
        (a, b) => b.createdAt.localeCompare(a.createdAt)
      ),
    [mostRecentlyCanceledSubscription]
  )

  const previousStripeSubscriptionStatus = useMemo(
    () => statusHistory[0]?.status,
    [statusHistory]
  )

  const isCanceled = useMemo(
    () => CANCELED_STATUSES.includes(stripeSubscriptionStatus),
    [stripeSubscriptionStatus]
  )

  const wasPastDue = useMemo(
    () => PAST_DUE_STATUSES.includes(previousStripeSubscriptionStatus || ""),
    [previousStripeSubscriptionStatus]
  )

  const wasCanceledDueToNonPayment = useMemo(
    () => isCanceled && wasPastDue,
    [isCanceled, wasPastDue]
  )
  const wasCanceledByUser = useMemo(
    () => isCanceled && !wasCanceledDueToNonPayment,
    [isCanceled, wasCanceledDueToNonPayment]
  )

  const cta = useMemo(() => {
    if (!stripeSubscriptionStatus || stripeSubscriptionStatus === "paused") {
      return "Activate Membership"
    }
    if (isCanceled) {
      return "Reactivate Membership"
    }
  }, [stripeSubscriptionStatus, isCanceled])

  return (
    <Dialog open>
      <DialogContent noCloseButton className="outline-0 max-w-lg gap-6">
        {loading ? (
          <div className="h-32 flex items-center justify-center">
            <LoadingIndicatorCentered />
          </div>
        ) : (
          <>
            <DialogHeader>
              <SadComputer className="w-[72px] h-[72px] mx-auto mb-4" />
              <DialogTitle className="font-serif text-3xl tracking-tight text-center font-normal">
                <>
                  {(!stripeSubscriptionStatus ||
                    stripeSubscriptionStatus === "paused") && (
                    <>Activate your membership?</>
                  )}
                  {isCanceled && <>Reactivate your membership?</>}
                </>
              </DialogTitle>
            </DialogHeader>
            <div className="text-center text-xs flex flex-col gap-2">
              <>
                {!stripeSubscriptionStatus && (
                  <p>
                    To continue to{" "}
                    <span className="whitespace-nowrap">{community.name}</span>,
                    please activate your membership.
                  </p>
                )}
                {wasCanceledByUser && (
                  <>
                    <p>
                      Your membership to{" "}
                      <span className="whitespace-nowrap">
                        {community.name}
                      </span>{" "}
                      was canceled
                      {canceledAt &&
                        ` on ${formatDate(canceledAt, "MMM d, yyyy")}`}
                      .
                    </p>
                    <p>
                      Pick up where you left off by reactivating your membership
                      to{" "}
                      <span className="whitespace-nowrap">
                        {community.name}
                      </span>
                      .
                    </p>
                  </>
                )}
                {wasCanceledDueToNonPayment && (
                  <>
                    <p>
                      Your membership to{" "}
                      <span className="whitespace-nowrap">
                        {community.name}
                      </span>{" "}
                      was canceled
                      {canceledAt &&
                        ` on ${formatDate(canceledAt, "MMM d, yyyy")}`}{" "}
                      because we were unable to verify your payment method.
                    </p>
                    <p>
                      Pick up where you left off by updating your payment method
                      and reactivating your membership to{" "}
                      <span className="whitespace-nowrap">
                        {community.name}
                      </span>
                      .
                    </p>
                  </>
                )}
                {stripeSubscriptionStatus === "paused" && (
                  <p>
                    If you've enjoyed your time at{" "}
                    <span className="whitespace-nowrap">{community.name}</span>,
                    please activate your membership.
                  </p>
                )}
              </>
            </div>
            <DialogFooter className="justify-center sm:justify-center text-center sm:flex-col flex-col gap-4">
              <div>
                <Button
                  onClick={() =>
                    openSubscriptionWizard("PricingTableStep", {
                      showAllTiers: true,
                      disableSelectCurrentTier: false,
                    })
                  }
                  type="button"
                >
                  {cta}
                </Button>
              </div>

              <p className="text-2xs">
                Think this is a mistake? Contact us via <br />{" "}
                <a
                  href="mailto:memberships@workweek.com"
                  className="text-link underline"
                >
                  memberships@workweek.com
                </a>
              </p>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
