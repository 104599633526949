import { useQuery } from "@apollo/client"
import { formatInTimeZone } from "date-fns-tz"
import { CalendarDays } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { adminProEventEditPath, adminProEventNewPath } from "~/common/paths"
import {
  PRO_EVENTS_ADMIN_LIST_QUERY_DOCUMENT,
  PRO_EVENT_FIXED_TIMEZONE,
} from "~/pro-events/pro-events"
import { PublishedToggle } from "~/pro-events/PublishedToggle"
import { LinkButton } from "~/ui/button"
import { Card } from "~/ui/card"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"

export const AdminProEventsScreen = () => {
  const { data, loading, error, fetchMore } = useQuery(
    PRO_EVENTS_ADMIN_LIST_QUERY_DOCUMENT,
    {
      variables: {
        after: null,
      },
    }
  )

  const proEvents = data?.proEvents?.edges.map((e) => e.node) || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading pro events." />

  return (
    <>
      <AdminHeader title="Events" Icon={CalendarDays}>
        <LinkButton to={adminProEventNewPath({})}>New Pro Event</LinkButton>
      </AdminHeader>
      <Card>
        <Table className="bg-white Table-auto w-full">
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Event Date</TableHead>
              <TableHead>Published</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {proEvents.map((proEvent) => (
              <TableRow key={proEvent.id}>
                <TableCell>{proEvent.title}</TableCell>
                <TableCell>
                  {formatInTimeZone(
                    proEvent.date,
                    PRO_EVENT_FIXED_TIMEZONE,
                    "M/dd/yyyy zzz"
                  )}
                </TableCell>
                <TableCell>
                  <PublishedToggle
                    proEventId={proEvent.id}
                    published={proEvent.published}
                  />
                </TableCell>
                <TableCell className="text-right">
                  <LinkButton
                    variant="filled"
                    size="sm"
                    to={adminProEventEditPath({ proEventId: proEvent.id })}
                  >
                    Edit
                  </LinkButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      {data.proEvents && (
        <InfiniteLoadMore
          onEndReached={() => {
            if (!data.proEvents) {
              return
            }
            fetchMore({
              variables: {
                after: data.proEvents.pageInfo.endCursor,
              },
            })
          }}
          canLoadMore={!loading && data.proEvents.pageInfo.hasNextPage}
          loadingText="Loading pro events..."
          loading={loading && proEvents.length > 0}
        />
      )}
    </>
  )
}
