import { ChevronDown } from "lucide-react"
import * as React from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/ui/dropdown-menu"
import { ScrollArea } from "~/ui/scroll-area"

export type DropdownOptionType = {
  label: React.ReactNode
  value: string
}

interface DropdownMenuCheckboxesProps {
  title: string
  optionTitle?: string
  options: DropdownOptionType[]
  selected: DropdownOptionType[]
  onChange: (value: any[]) => void
  exclusive?: boolean
  trigger?: React.ReactNode
}

export function DropdownMenuCheckboxes({
  title,
  optionTitle,
  options,
  selected,
  onChange,
  exclusive = false,
  trigger,
}: DropdownMenuCheckboxesProps) {
  const ccls = useCommunityClassname()
  const isChecked = (value: string) => {
    const checked = selected.find((option) => option.value === value)

    return !!checked
  }

  const onCheckedChange = (option: DropdownOptionType) => {
    return (state: boolean) => {
      if (exclusive) {
        if (state) {
          onChange([option])
        } else {
          onChange([])
        }
      } else {
        if (state) {
          onChange([...selected, option])
        } else {
          onChange(selected.filter((s) => s.value !== option.value))
        }
      }
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {trigger || (
          <Button
            variant="input"
            className={cn(
              "w-full text-2xs bg-card",
              selected.length > 0 &&
                selected.length !== options.length &&
                ccls({
                  [CommunitySlug.Marketingland]: "text-library-dropdown-badges",
                  default: "text-primary",
                }),
              ccls({
                [CommunitySlug.Gotomillions]: "border-primary",
                default: "border-mercury",
              })
            )}
            post={<ChevronDown className="w-4 h-4" />}
          >
            {title}
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-72 p-4 rounded-lg" align="end">
        {optionTitle && (
          <>
            <DropdownMenuLabel>{optionTitle}</DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        )}
        <ScrollArea className="max-h-[300px] overflow-y-auto">
          {options.map((option, i) => {
            const checked = isChecked(option.value)
            const key =
              typeof option.value === "string" ||
              typeof option.value === "number"
                ? option.value
                : i

            return (
              <React.Fragment key={key}>
                <DropdownMenuCheckboxItem
                  checked={checked}
                  className="cursor-pointer"
                  onCheckedChange={onCheckedChange(option)}
                >
                  {option.label}
                </DropdownMenuCheckboxItem>
                {i !== options.length - 1 && <DropdownMenuSeparator />}
              </React.Fragment>
            )
          })}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
