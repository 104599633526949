import { useCallback, useEffect } from "react"
import { Button } from "~/ui/button"
import { editArticlePath } from "~/common/paths"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  ArticleCollaboratorsModal,
  useCreateCollaborator,
  useDestroyCollaborator,
} from "./ArticleCollaboratorsModal"
import { CreateContentModal } from "~/content/CreateContentModal"
import { Article_EditorContentFragment, Course } from "~/__generated__/graphql"
import { ExternalShareSettingsModal } from "./ExternalShareSettingModal"
import { Plus } from "lucide-react"

export const CreateContentButton = ({
  startWritingWithCollaborators = [],
}: {
  startWritingWithCollaborators?: string[]
}) => {
  const navigate = useNavigate()

  const [collaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false)
  const [collaboratorsModalArticle, setCollaboratorsModalArticle] =
    useState<Article_EditorContentFragment | null>(null)
  const [initialCollaboratorsProcessed, setInitialCollaboratorsProcessed] =
    useState<boolean>(false)
  const [isCreateContentModalOpen, setIsCreateContentModalOpen] =
    useState(false)
  const [externalSharingModalOpen, setExternalSharingModalOpen] =
    useState(false)

  const onCreateArticle = useCallback(
    (article: Article_EditorContentFragment) => {
      setIsCreateContentModalOpen(false)
      setCollaboratorsModalOpen(true)
      setCollaboratorsModalArticle(article)
    },
    []
  )
  const onCreateCourse = useCallback(
    (
      course: Pick<Course, "id"> & { article: Article_EditorContentFragment }
    ) => {
      setIsCreateContentModalOpen(false)
      setCollaboratorsModalOpen(true)
      setCollaboratorsModalArticle(course.article)
    },
    []
  )

  useEffect(() => {
    if (
      !initialCollaboratorsProcessed &&
      startWritingWithCollaborators.length > 0
    ) {
      setIsCreateContentModalOpen(true)
      setInitialCollaboratorsProcessed(true)
    }
  }, [initialCollaboratorsProcessed, startWritingWithCollaborators])

  const { createCollaborator } = useCreateCollaborator()
  const { destroyCollaborator } = useDestroyCollaborator()

  return (
    <>
      <Button
        theme="highlight"
        pre={<Plus className="w-4 h-4" />}
        onClick={() => setIsCreateContentModalOpen(true)}
      >
        Create
      </Button>

      <CreateContentModal
        open={isCreateContentModalOpen}
        onOpenChange={setIsCreateContentModalOpen}
        onCreateArticle={onCreateArticle}
        onCreateCourse={onCreateCourse}
      />
      {collaboratorsModalOpen && collaboratorsModalArticle && (
        <ArticleCollaboratorsModal
          setIsOpen={setCollaboratorsModalOpen}
          includeCollaborators={startWritingWithCollaborators}
          articleId={collaboratorsModalArticle.id}
          collaborators={collaboratorsModalArticle.collaborators}
          onAddCollaborator={createCollaborator}
          onRemoveCollaborator={destroyCollaborator}
          onSubmit={() => setExternalSharingModalOpen(true)}
        />
      )}
      <ExternalShareSettingsModal
        isOpen={externalSharingModalOpen}
        setIsOpen={setExternalSharingModalOpen}
        articleId={collaboratorsModalArticle?.id || ""}
        allowExternalShare={
          collaboratorsModalArticle
            ? collaboratorsModalArticle.allowExternalShare
            : true
        }
        onSubmit={() => {
          if (collaboratorsModalArticle?.id) {
            navigate(
              editArticlePath({ articleId: collaboratorsModalArticle?.id })
            )
          }
        }}
      />
    </>
  )
}
