import { IconChevronDown, IconFile } from "@codexteam/icons"
import AttachesTool from "@editorjs/attaches"

export class WorkweekPdfEmbedTool extends AttachesTool {
  static get toolbox() {
    return {
      icon: IconFile,
      title: "PDF Embed",
    }
  }

  // Copied from
  // https://github.com/editor-js/attaches/blob/master/src/index.js#L402
  showFileData() {
    this.nodes.wrapper.classList.add(this.CSS.wrapperWithFile)

    const { file, title } = this.data

    this.appendFileIcon(file)

    const fileInfo = make("div", this.CSS.fileInfo)

    this.nodes.title = make("div", this.CSS.title, {
      contentEditable: this.readOnly === false,
    })

    this.nodes.title.dataset.placeholder = this.api.i18n.t("File title")
    this.nodes.title.textContent = title || ""
    fileInfo.appendChild(this.nodes.title)

    if (file.size) {
      let sizePrefix
      let formattedSize
      const fileSize = make("div", this.CSS.size)

      if (Math.log10(+file.size) >= 6) {
        sizePrefix = "MiB"
        formattedSize = file.size / Math.pow(2, 20)
      } else {
        sizePrefix = "KiB"
        formattedSize = file.size / Math.pow(2, 10)
      }

      fileSize.textContent = formattedSize.toFixed(1)
      fileSize.setAttribute("data-size", sizePrefix)
      fileInfo.appendChild(fileSize)
    }

    const embeddedPdfText = make("div", "cdx-attaches__pdf-text")
    embeddedPdfText.innerHTML =
      "<b>Embedded PDF:</b> This will appear as an embedded PDF."
    fileInfo.appendChild(embeddedPdfText)

    this.nodes.wrapper.appendChild(fileInfo)

    if (file.url !== undefined) {
      const downloadIcon = make("a", this.CSS.downloadButton, {
        innerHTML: IconChevronDown,
        href: file.url,
        target: "_blank",
        rel: "nofollow noindex noreferrer",
      })

      this.nodes.wrapper.appendChild(downloadIcon)
    }
  }
}

const make = (
  tagName: string,
  classNames: string | string[] | null = null,
  attributes: any = {}
) => {
  const el = document.createElement(tagName)

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames)
  } else if (classNames) {
    el.classList.add(classNames)
  }

  for (const attrName in attributes) {
    // @ts-expect-error - TS doesn't like setting arbitrary attributes on elements
    el[attrName] = attributes[attrName]
  }

  return el
}
