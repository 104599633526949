import { useLazyQuery } from "@apollo/client"
import { parseISO } from "date-fns"
import { Lock } from "lucide-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { gql } from "~/__generated__"
import {
  AhoyEventTypeEnum,
  CommunitySlug,
  UserIntroductionsStateEnum,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { editIntroductionsPath } from "~/common/paths"
import { useIntroductionsSnoozedUntilMutation } from "~/introduction-cycles/IntroductionsSettingsModule"
import { Button } from "~/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { AvatarStack } from "~/ui/AvatarStack"
import { Link } from "~/ui/Link"
import {
  LoadingIndicator,
  LoadingIndicatorCentered,
} from "~/ui/LoadingIndicator"
import { Translation } from "~/common/Translation"
import { useCommunityClassname } from "~/community/useCommunity"

export const IntroductionsModule = () => {
  const ccls = useCommunityClassname()
  const { openSubscriptionWizard } = useSubscription()
  const currentUser = useCurrentUser()
  const { updateIntroductionsSnoozedUntil, date } =
    useIntroductionsSnoozedUntilMutation()
  const { logEvent } = useLogEvent()
  const [getIntroductionMatches, { loading, data }] = useLazyQuery(
    INTRODUCTION_MATCHES_QUERY_DOCUMENT,
    {
      variables: {
        userId: currentUser.id,
      },
    }
  )

  const [, setIntroductionsSnoozedUntil] = useState(
    currentUser.introductionsSnoozedUntil
      ? parseISO(currentUser.introductionsSnoozedUntil)
      : null
  )

  useEffect(() => {
    setIntroductionsSnoozedUntil(date)
  }, [date, setIntroductionsSnoozedUntil])

  useEffect(() => {
    if (
      !currentUser.permissions.canUseIntroductions ||
      !!currentUser.introductionsSnoozedUntil
    )
      return
    getIntroductionMatches()
  }, [currentUser, getIntroductionMatches])

  const users = useMemo(() => {
    if (!data) return []
    return data.introductionMatches.edges
      .map(({ node }) => node.matchedWithUser!)
      .filter(
        (user, index, self) => self.findIndex((u) => u.id === user.id) === index
      )
  }, [data])

  const introductionsMadeCount = useMemo(() => {
    if (!data) return 0
    return data.introductionMatches.totalCount
  }, [data])

  const handleTurnOn = useCallback(() => {
    logEvent(AhoyEventTypeEnum.IntroductionsInterestClicked)

    if (!currentUser.permissions.canUseIntroductions) {
      openSubscriptionWizard("PricingTableStep", {
        source: "IntroductionsModule",
        requiredFeature: "canUseIntroductions",
      })
    } else {
      updateIntroductionsSnoozedUntil(null)
    }
  }, [
    currentUser.permissions.canUseIntroductions,
    openSubscriptionWizard,
    updateIntroductionsSnoozedUntil,
    logEvent,
  ])

  if (
    currentUser.permissions.canUseIntroductions &&
    currentUser.introductionsState === UserIntroductionsStateEnum.Active &&
    introductionsMadeCount === 0
  ) {
    return null
  }

  return (
    <Card
      className={ccls({
        [CommunitySlug.Gotomillions]: "bg-secondary-background",
        default: "",
      })}
    >
      <CardHeader>
        <CardTitle className="flex flex-row justify-between items-center">
          Introductions
          <div>
            {!currentUser.permissions.canUseIntroductions && (
              <Lock className="w-4 h-4" />
            )}
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-4 flex flex-col gap-4">
        {!currentUser.permissions.canUseIntroductions ||
        [
          UserIntroductionsStateEnum.ActivelySnooze,
          UserIntroductionsStateEnum.DefaultSnooze,
        ].includes(currentUser.introductionsState) ? (
          <>
            <div className="text-xs">
              <Translation ns="introductionsModule" i18nKey="description" />
            </div>
            <div>
              <Button type="button" className="text-xs" onClick={handleTurnOn}>
                <Translation ns="introductionsModule" i18nKey="ctaButtonText" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="text-xs">
              Your membership includes receiving curated introductions to peers
              in the community. Helping you build genuine relationships is our
              favorite thing to do. Reply via email or send a DM!
            </div>
            <div className="font-medium">
              Introductions Made:{" "}
              {loading ? <LoadingIndicator /> : introductionsMadeCount}
            </div>
            {loading ? (
              <LoadingIndicatorCentered />
            ) : (
              <AvatarStack
                users={users}
                totalCount={introductionsMadeCount}
                overlap={8}
                size="md"
              />
            )}
            <Link
              to={editIntroductionsPath.pattern}
              className="text-xs text-textLight"
            >
              Change introduction settings
            </Link>
          </>
        )}
      </CardContent>
    </Card>
  )
}

const INTRODUCTION_MATCHES_QUERY_DOCUMENT = gql(`
  query IntroductionMatches($userId: ID!) {
    introductionMatches(userId: $userId, accepted: true, first: 20) {
      totalCount
      edges {
        node {
          id
          matchedWithUser {
            id
            ...User_Avatar
          }
        }
      }
    }
  }
`)
