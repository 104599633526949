import { useUserDialogContext } from "~/directory/UserDialogContext"
import { UserName } from "~/directory/UserName"
import Info from "~/images/icons/info.svg?react"
import Pin from "~/images/icons/pin.svg?react"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"
import { ClickableIconWrapper } from "~/ui/ClickableIconWrapper"
import { TableCell, TableRow } from "~/ui/table"
import { IntroductionMatchOverrideForm } from "./IntroductionMatchOverrideForm"
import { IntroductionMatch, IntroductionUser } from "./types"

export const IntroductionMatchRow = ({
  match,
  index,
  overriding,
  loading,
  locked,
  selected,
  onToggleAcceptanceClick,
  onToggleIgnoranceClick,
  onToggleOverrideClick,
  onOverrideMatch,
  onToggleSelectClick,
}: {
  match: IntroductionMatch
  index: number
  overriding: boolean
  loading: boolean
  locked: boolean
  selected: boolean
  onToggleAcceptanceClick: () => void
  onToggleIgnoranceClick: () => void
  onToggleOverrideClick: () => void
  onOverrideMatch: (userId: string) => void
  onToggleSelectClick: () => void
}) => {
  const canToggleAcceptance = !locked && !overriding && !match.ignored
  const canToggleIgnorance = !locked && !overriding && !match.accepted
  const canToggleOverride = !locked && !match.accepted && !match.ignored
  const canSelect = !locked && !match.accepted && !match.ignored

  return (
    <TableRow
      key={match.id}
      className={cn({
        "bg-green-100": match.accepted,
        "bg-blue-100": match.ignored,
        "bg-yellow": selected,
      })}
    >
      <TableCell>
        {canSelect && (
          <ClickableIconWrapper
            onClick={onToggleSelectClick}
            primary={selected}
          >
            <Pin height={18} width={18} />
          </ClickableIconWrapper>
        )}
      </TableCell>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <UserData user={match.user} />
      </TableCell>
      <TableCell
        className={cn({ "bg-yellow": match?.matchedWithUser?.creator })}
      >
        <div className="flex justify-between items-start gap-2">
          {overriding ? (
            <IntroductionMatchOverrideForm
              match={match}
              onOverrideMatch={onOverrideMatch}
            />
          ) : match.matchedWithUser ? (
            <UserData user={match.matchedWithUser} />
          ) : (
            <span>-----------------</span>
          )}
          {canToggleOverride && (
            <Button
              onClick={onToggleOverrideClick}
              disabled={loading}
              size="sm"
            >
              {overriding ? "Cancel" : "Override"}
            </Button>
          )}
        </div>
      </TableCell>
      <TableCell>{match.matchValue}</TableCell>
      <TableCell>
        {canToggleAcceptance && (
          <Button
            onClick={onToggleAcceptanceClick}
            disabled={loading}
            size="sm"
          >
            {match.accepted ? "Unaccept" : "Accept"}
          </Button>
        )}
      </TableCell>
      <TableCell>
        {canToggleIgnorance && (
          <Button onClick={onToggleIgnoranceClick} disabled={loading} size="sm">
            {match.ignored ? "Unsnooze" : "Snooze"}
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}

const UserData = ({ user }: { user: IntroductionUser }) => {
  const { openUserDialog } = useUserDialogContext()

  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="font-medium">
          <UserName user={user} />
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => openUserDialog(user.id)}
          className="p-0"
        >
          <Info height={18} width={18} />
        </Button>
      </div>
      <div className="text-gray-500">{user.email}</div>
    </div>
  )
}
