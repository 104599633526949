import { useEffect, useRef } from "react"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useAuthenticatedLayout } from "~/layouts/AuthenticatedLayout"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const ApplicationMostExcitedAboutScreen = () => {
  const currentUser = useCurrentUser()
  const { setIsBlurred } = useAuthenticatedLayout()
  const { openSubscriptionWizard } = useSubscription()
  const hasOpenedSubscriptionWizardRef = useRef(false)

  useEffect(() => {
    setIsBlurred(true)
    return () => setIsBlurred(false)
  }, [setIsBlurred])

  useEffect(() => {
    if (hasOpenedSubscriptionWizardRef.current) return
    hasOpenedSubscriptionWizardRef.current = true

    if (currentUser.mostExcitedAbout) {
      if (currentUser.activeStripeSubscription || currentUser.admin) {
        openSubscriptionWizard(
          "SocialContractStep",
          { disableSelectCurrentTier: false },
          (handle) => {
            handle.setDisableClose(true)
          }
        )
      } else {
        openSubscriptionWizard(
          "PricingTableStep",
          { disableSelectCurrentTier: false },
          (handle) => {
            handle.setDisableClose(true)
          }
        )
      }
    } else {
      openSubscriptionWizard(
        "IntentStep",
        { disableSelectCurrentTier: false },
        (handle) => {
          handle.setDisableClose(true)
        }
      )
    }
  }, [
    currentUser.admin,
    currentUser.mostExcitedAbout,
    openSubscriptionWizard,
    currentUser.activeStripeSubscription,
  ])

  return <></>
}
