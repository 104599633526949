import { FC, ReactNode, SVGProps } from "react"
import {
  Tooltip,
  ResponsiveContainer,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
} from "recharts"

import { CustomLegend } from "./CustomLegend"
import { CustomTooltip } from "./CustomTooltip"

import { Data } from "~/__generated__/graphql"

export const USER_DATA_KEY = "user"
export const USER_DATA_KEY_FOR_DISPLAY = "You"
export const COMMUNITY_DATA_KEY = "community"
export const COMMUNITY_DATA_KEY_FOR_DISPLAY = "Peer Avg"
export const BENCHMARK_DATA_KEY = "benchmark"
export const BENCHMARK_DATA_KEY_FOR_DISPLAY = "Global Average"
export const USER_COLOR = "#E02B59"
export const COMMUNITY_COLOR = "#FFD1DD"
export const BENCHMARK_COLOR = "#000000"

export type ChartData = Array<{
  name: string
  user: number
  community: number
  benchmark: number
}>

export interface Margin {
  top?: number
  right?: number
  left?: number
  bottom?: number
}

interface BenchmarkChartProps {
  ChartComponent: FC<any>
  children: ReactNode
  tooltip?: boolean
  legend?: boolean
  margin?: Margin
  height?: number
  data: Array<Data>
  domain?: Array<number>
  tickCount?: number
  axisValueFormatter?: (value?: string) => string
  yAxisLine?: boolean | SVGProps<SVGLineElement>
}

type ChartComponentProps = Parameters<BenchmarkChartProps["ChartComponent"]>[0]
interface Payload {
  value: string
}

export const BenchmarkChart: FC<ChartComponentProps> = ({
  axisValueFormatter = (value) => value || "",
  children,
  ChartComponent,
  data,
  domain,
  height = 300,
  legend = true,
  margin,
  tickCount,
  tooltip = true,
  yAxisLine = true,
}: BenchmarkChartProps) => {
  const margins = {
    ...{
      top: 10,
      right: 0,
      left: 0,
      bottom: 5,
    },
    ...(margin ? margin : {}),
  }

  const maxDomain = data.reduce((max, obj) => {
    return Math.max(
      ...[
        max,
        Math.max(...[obj.benchmark || 0, obj.user || 0, obj.community || 0]),
      ]
    )
  }, 0)

  const maxDomainString = Number.isInteger(maxDomain)
    ? maxDomain.toString()
    : maxDomain.toFixed(1)

  const formattedLabelMaxChars =
    axisValueFormatter(maxDomainString).length - maxDomainString.length

  const formattedLabelTranslation = (() => {
    if (formattedLabelMaxChars === 0) {
      return -24
    } else if (formattedLabelMaxChars === 1) {
      return -34
    } else {
      return -44
    }
  })()

  const CustomYAxisTick = ({
    x,
    y,
    payload,
  }: {
    x?: number
    y?: number
    payload?: Payload
  }) => (
    <g className="w-full" transform={`translate(${x},${y})`}>
      <text
        x={formattedLabelTranslation}
        y={0}
        dy={"0.255em"}
        textAnchor="start"
        className="w-full"
      >
        <tspan
          fill="#999999"
          className="tracking-widest w-full font-light text-xs text-left"
        >
          {axisValueFormatter(payload?.value)}
        </tspan>
      </text>
    </g>
  )

  const mappedDataForDisplay = data.map((d) => {
    return {
      name: d.name,
      [USER_DATA_KEY_FOR_DISPLAY]: d.user,
      [COMMUNITY_DATA_KEY_FOR_DISPLAY]: d.community,
      [BENCHMARK_DATA_KEY_FOR_DISPLAY]: d.benchmark,
    }
  })

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ChartComponent data={mappedDataForDisplay} margin={margins}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" />
        <YAxis
          axisLine={yAxisLine}
          tick={<CustomYAxisTick />}
          tickFormatter={(value) =>
            axisValueFormatter ? axisValueFormatter(value) : value
          }
          domain={domain}
          tickCount={tickCount}
        />
        <Line
          type="monotone"
          dataKey={BENCHMARK_DATA_KEY_FOR_DISPLAY}
          dot={false}
          stroke={BENCHMARK_COLOR}
          strokeWidth={3}
          strokeDasharray="5 5"
        />
        {children}
        {tooltip && (
          <Tooltip content={<CustomTooltip formatter={axisValueFormatter} />} />
        )}
        {legend && <Legend content={<CustomLegend />} />}
      </ChartComponent>
    </ResponsiveContainer>
  )
}
