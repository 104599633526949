import { DotsHorizontalIcon, EyeNoneIcon } from "@radix-ui/react-icons"
import { parseISO } from "date-fns"
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"
import { gql } from "~/__generated__"
import {
  ApplicationFieldEnum,
  OnboardingStateEnum,
  User_AdminFragment,
  UserSortEnum,
} from "~/__generated__/graphql"
import { useConfig } from "~/common/ConfigProvider"
import { formatTime } from "~/common/formatDate"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { useCommunity } from "~/community/useCommunity"
import { UserDialog } from "~/directory/UserDialog"
import { UserName } from "~/directory/UserName"
import { USER_ADMIN_UPDATE_MUTATION } from "~/screens/admin/AdminMembersScreen"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import { Checkbox } from "~/ui/checkbox"
import { useConfirm } from "~/ui/Confirm"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import { Skeleton } from "~/ui/skeleton"
import { TexasToast } from "~/ui/TexasToast"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import { ChangeEmailModal } from "../ChangeEmailModal"
import { FitProfileDisplay } from "./FitProfileDisplay"
import { UsersTable, UserTableFilter } from "./UsersTable"

const ApplicationsTableSkeleton = () => (
  <div className="flex flex-col gap-4">
    {Array.from({ length: PAGE_SIZE }).map((_, index) => (
      <div className="flex gap-2 items-center" key={index}>
        <div className="p-4">
          <Checkbox disabled />
        </div>
        <Card>
          <CardContent className="pt-4">
            <div className="flex gap-2 items-center">
              <div className="flex gap-2 flex-grow">
                <Skeleton className="rounded-full w-[60px] h-[60px]" />
                <div className="flex flex-col gap-1">
                  <Skeleton className="w-[120px] h-[16px]" />
                  <Skeleton className="w-[100px] h-[16px]" />
                  <Skeleton className="w-[140px] h-[16px]" />
                </div>
              </div>
              <div className="flex gap-1">
                <Skeleton className="w-36 h-12 rounded-full" />
                <Skeleton className="w-36 h-12 rounded-full" />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ))}
  </div>
)

type UserRowProps = {
  user: User_AdminFragment
}

const UserRow = ({ user }: UserRowProps) => {
  const { approve, reject, select, deselect, selectedUsers } =
    useContext(ApplicationsContext)
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const isSelected = useMemo(
    () => selectedUsers.some((u) => u.id === user.id),
    [selectedUsers, user]
  )
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false)
  const contextMenuTriggerRef = useRef<HTMLDivElement>(null)

  const onCheckedChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        select(user)
      } else {
        deselect(user)
      }
    },
    [select, deselect, user]
  )

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  return (
    <>
      {changeEmailModalOpen && (
        <ChangeEmailModal
          userId={user.id}
          email={user.email || ""}
          isOpen={changeEmailModalOpen}
          setIsOpen={setChangeEmailModalOpen}
          name={user.name}
        />
      )}
      {userDialogOpen && (
        <UserDialog
          isOpen={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={user}
        />
      )}

      <div className="flex gap-2 items-center">
        <div className="p-4">
          <Checkbox checked={isSelected} onCheckedChange={onCheckedChange} />
        </div>
        <ContextMenu>
          <ContextMenuContent>
            <ContextMenuItem
              onClick={() => approve(user)}
              className="cursor-pointer"
            >
              Approve
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => reject(user)}
              className="cursor-pointer"
            >
              Mark as Unfit
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => setChangeEmailModalOpen(true)}
              className="cursor-pointer"
            >
              Change Email
            </ContextMenuItem>
          </ContextMenuContent>
          <ContextMenuTrigger asChild>
            <Card ref={contextMenuTriggerRef}>
              <CardContent className="pt-4">
                <div className="flex gap-2 flex-wrap items-center">
                  <div className="flex gap-2 flex-grow">
                    <div>
                      <AvatarWithFallback user={user} size="2xl" />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-2 items-center">
                        <Button
                          variant="link"
                          size="inline"
                          className="text-primary"
                          onClick={() => setUserDialogOpen(true)}
                        >
                          <UserName user={user} />
                        </Button>
                        {user.hidden && (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <EyeNoneIcon className="text-pretext-grey" />
                              </TooltipTrigger>
                              <TooltipContent>
                                User is hidden from the directory
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                      </div>

                      <div className="text-2xs flex flex-col items-start gap-2">
                        <CopyToClipboard
                          text={user.email!}
                          className="text-2xs text-gray-500"
                        >
                          {user.email}
                        </CopyToClipboard>
                        <div>
                          Tier: <strong>{user.tier?.name}</strong>
                        </div>
                        <div>
                          Application completed at:{" "}
                          <strong>
                            {user.fitApplicationSubmittedAt
                              ? formatTime(
                                  parseISO(user.fitApplicationSubmittedAt)
                                )
                              : "n/a"}
                          </strong>
                        </div>
                        <div className="text-pretext-light-gray text-2xs">
                          {user.jobTitle} -{" "}
                          {user.companyName || "Unknown Company"} -{" "}
                          {user.place?.full || "Unknown Location"}
                        </div>
                        <FitProfileDisplay user={user} />
                      </div>
                    </div>
                  </div>

                  <div>
                    <Button
                      theme="success"
                      className="w-32 text-xs"
                      onClick={() => approve(user)}
                    >
                      Approve
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outline"
                      className="w-32 text-xs"
                      onClick={() => reject(user)}
                    >
                      Unfit
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={triggerContextMenu}
                    >
                      <DotsHorizontalIcon />
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </ContextMenuTrigger>
        </ContextMenu>
      </div>
    </>
  )
}

const ApplicationsContext = createContext<{
  approve: (user: User_AdminFragment) => void
  reject: (user: User_AdminFragment) => void
  select: (user: User_AdminFragment) => void
  deselect: (user: User_AdminFragment) => void
  selectedUsers: User_AdminFragment[]
}>({
  approve: () => {},
  reject: () => {},
  select: () => {},
  deselect: () => {},
  selectedUsers: [],
})

const PAGE_SIZE = 10
const DEFAULT_SORT = UserSortEnum.ApplicationPriority

interface ApplicationsTableProps {
  onboardingState?: OnboardingStateEnum | OnboardingStateEnum[]
}

export const ApplicationsTable = ({
  onboardingState,
}: ApplicationsTableProps) => {
  const { unfitRefundWindowInDays } = useConfig()
  const UNFIT_REFUND_WINDOW_IN_DAYS = unfitRefundWindowInDays || 30
  const [refresh, setRefresh] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState<User_AdminFragment[]>([])
  const [runUserUpdate] = useSafeMutation(USER_ADMIN_UPDATE_MUTATION)
  const [runUsersBulkReview] = useSafeMutation(USERS_BULK_REVIEW_MUTATION)
  const community = useCommunity()

  const showConfirm = useConfirm()

  const approve = useCallback(
    async (user: User_AdminFragment) => {
      const { errors } = await runUserUpdate({
        variables: {
          input: {
            userId: user.id,
            fit: true,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
      }
    },
    [runUserUpdate]
  )

  const doReject = useCallback(
    async (user: User_AdminFragment) => {
      const { errors } = await runUsersBulkReview({
        variables: {
          input: {
            userIds: [user.id],
            fit: false,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
      } else {
        setRefresh(refresh + 1)
      }
    },
    [runUsersBulkReview, refresh]
  )

  const reject = useCallback(
    async (user: User_AdminFragment) => {
      await showConfirm({
        title: "Are you sure? This action cannot be undone.",
        body: `Marking ${user.name} as unfit will cancel their subscription and refund any pending or completed charges from within the last ${UNFIT_REFUND_WINDOW_IN_DAYS} days.`,
        confirmText: "Mark as Unfit",
        onConfirm: () => doReject(user),
      })
    },
    [showConfirm, doReject, UNFIT_REFUND_WINDOW_IN_DAYS]
  )

  const approveSelected = useCallback(async () => {
    const { errors } = await runUsersBulkReview({
      variables: {
        input: {
          userIds: selectedUsers.map((u) => u.id),
          fit: true,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      setRefresh(refresh + 1)
      setSelectedUsers([])
    }
  }, [runUsersBulkReview, refresh, selectedUsers])

  const select = useCallback(
    (user: User_AdminFragment) => {
      setSelectedUsers([...selectedUsers, user])
    },
    [selectedUsers, setSelectedUsers]
  )

  const deselect = useCallback(
    (user: User_AdminFragment) => {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
    },
    [selectedUsers, setSelectedUsers]
  )

  const enabledFilters = useMemo(() => {
    const filters: UserTableFilter[] = ["tierId"]
    community.applicationFields.forEach((field) => {
      switch (field) {
        case ApplicationFieldEnum.CompanySize:
          filters.push("companySize")
          break
        case ApplicationFieldEnum.Industry:
          filters.push("industry")
          break
        case ApplicationFieldEnum.JobFunction:
          filters.push("jobFunction")
          break
        case ApplicationFieldEnum.Vertical:
          filters.push("vertical")
          break
        case ApplicationFieldEnum.YearsOfExperience:
          filters.push("yearsOfExperience")
          break
        default:
          break
      }
    })
    return filters
  }, [community.applicationFields])

  return (
    <>
      <TexasToast open={selectedUsers.length > 0}>
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <div>{selectedUsers.length} selected</div>
            <div>
              <Button variant="ghost" onClick={() => setSelectedUsers([])}>
                Deselect selected
              </Button>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <Button
                theme="success"
                className="w-32 text-xs"
                onClick={approveSelected}
              >
                Approve
              </Button>
            </div>
          </div>
        </div>
      </TexasToast>

      <ApplicationsContext.Provider
        value={{
          approve,
          reject,
          select,
          deselect,
          selectedUsers,
        }}
      >
        <UsersTable
          withFilters={enabledFilters}
          withSearch
          withSelectAll
          onSelectAll={(users) => setSelectedUsers(users)}
          variant="card"
          skeleton={<ApplicationsTableSkeleton />}
          defaultSort={DEFAULT_SORT}
          queryOptions={{
            unreviewedOnly: true,
            onboardingStates: onboardingState,
          }}
        >
          {(user) => <UserRow key={user.id} user={user} />}
        </UsersTable>
      </ApplicationsContext.Provider>
    </>
  )
}

const USERS_BULK_REVIEW_MUTATION = gql(`
  mutation UsersBulkReview($input: UsersBulkReviewInput!) {
    usersBulkReview(input: $input) {
      users {
        ...User_Admin
      }
    }
  }
`)
