import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { ArrowRight, Check } from "lucide-react"
import {
  AhoyEventTypeEnum,
  CommunitySlug,
  OfferCategoryEnum,
  OnboardingActionsEnum,
  RulesEngineEventTypeEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import {
  accountSettingsPath,
  channelPath,
  editProfilePath,
} from "~/common/paths"
import { useNavigate } from "react-router-dom"
import { camelCase } from "change-case"
import { useEffect, useRef, useState } from "react"
import { AddToHomescreenModal } from "~/components/modals/AddToHomescreenModal"
import { useLogEvent } from "~/analytics/EventsContext"
import { GetStartedLink } from "./GetStartedLink"
import { useComposerContext } from "~/post-composer/ComposerContext"
import { Translation, useTranslation } from "~/common/Translation"
import { useConfirm } from "~/ui/Confirm"
import { Button } from "~/ui/button"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useRulesEngine } from "~/rules-engine/RulesEngineContext"
import { useUserOffers } from "~/offers/UserOffersProvider"
import { addConfetti } from "~/common/addConfetti"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useChannels } from "~/common/ChannelsProvider"
import { ComposerOriginEnum } from "~/post-composer/PostComposer"
import { CreatorSignature } from "~/community/CreatorSignature"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { useCurrentUser } from "~/common/GlobalsProvider"

interface ChecklistItemProps {
  text: string
  action: OnboardingActionsEnum
  completed?: boolean
}

const ChecklistItem = ({
  text,
  action,
  completed = false,
}: ChecklistItemProps) => {
  const { composerRef } = useComposerContext() || {}
  const { logEvent } = useLogEvent()
  const navigate = useNavigate()
  const { t } = useTranslation("ctaChecklist")
  const [isAddToHomescreenModalOpen, setIsAddToHomescreenModalOpen] =
    useState(false)
  const { openSubscriptionWizard } = useSubscription()
  const showConfirm = useConfirm()
  const { introductionsChannel } = useChannels()

  const handleClick = () => {
    switch (action) {
      case OnboardingActionsEnum.EnhanceYourProfile:
        if (!completed) {
          logEvent(AhoyEventTypeEnum.NewUserChecklistItemClicked, {
            step_name: t("enhanceYourProfile"),
          })
        }
        navigate(editProfilePath.pattern)
        break
      case OnboardingActionsEnum.ReviewCommunityValues:
        if (!completed) {
          logEvent(AhoyEventTypeEnum.NewUserChecklistItemClicked, {
            step_name: t("reviewCommunityValues"),
          })
        }
        openSubscriptionWizard("SocialContractStep")
        break
      case OnboardingActionsEnum.IntroduceYourself:
        if (completed) return

        logEvent(AhoyEventTypeEnum.NewUserChecklistItemClicked, {
          step_name: t("introduceYourself"),
        })

        if (introductionsChannel) {
          navigate(channelPath({ channelSlug: introductionsChannel.slug }))
        }

        setTimeout(() => {
          if (composerRef?.current) {
            const { content } = composerRef.current.getPostData()
            if (content && content.trim().length > 0) {
              showConfirm({
                title: "Are you sure?",
                body: "You have a post in progress. If you start a new post, you will lose your current progress.",
                confirmText: "Start New Post",
                cancelText: "Cancel",
                onConfirm: () => {
                  composerRef?.current?.prefillContent(
                    t("introduction_template"),
                    ComposerOriginEnum.CTA_CHECKLIST
                  )
                },
              })
            } else {
              composerRef?.current?.prefillContent(
                t("introduction_template"),
                ComposerOriginEnum.CTA_CHECKLIST
              )
            }
          }
        }, 500)
        break
      case OnboardingActionsEnum.DownloadOurApp:
        if (!completed) {
          logEvent(AhoyEventTypeEnum.NewUserChecklistItemClicked, {
            step_name: t("downloadOurApp"),
          })
        }
        setIsAddToHomescreenModalOpen(true)
        break
      case OnboardingActionsEnum.OptIntoIntroductions:
        if (!completed) {
          logEvent(AhoyEventTypeEnum.NewUserChecklistItemClicked, {
            step_name: t("optIntoIntroductions"),
          })
        }
        navigate(accountSettingsPath.pattern)
        break
      default:
        console.log(`Unknown action: ${action}`)
    }
  }

  return (
    <>
      {action === OnboardingActionsEnum.DownloadOurApp && (
        <AddToHomescreenModal
          open={isAddToHomescreenModalOpen}
          onOpenChange={setIsAddToHomescreenModalOpen}
        />
      )}
      <div
        className="group flex items-center gap-3 p-2 border border-mercury rounded-full cursor-pointer hover:bg-highlight/70"
        onClick={handleClick}
      >
        <div
          className={cn(
            "rounded-full p-1",
            completed ? "bg-success" : "bg-mercury group-hover:bg-gray-400"
          )}
        >
          <Check className="h-4 w-4 text-white" />
        </div>
        <span
          className={cn(
            completed ? "" : "text-lightGray",
            "font-medium leading-none tracking-wide group-hover:text-white"
          )}
        >
          {text}
        </span>
      </div>
    </>
  )
}

const itemOrder = [
  OnboardingActionsEnum.ReviewCommunityValues,
  OnboardingActionsEnum.EnhanceYourProfile,
  OnboardingActionsEnum.IntroduceYourself,
  OnboardingActionsEnum.DownloadOurApp,
  OnboardingActionsEnum.OptIntoIntroductions,
]

export const CTAChecklist = () => {
  const { t } = useTranslation("ctaChecklist")
  const ccls = useCommunityClassname()

  const onboardingActionTexts: Record<string, string> = {
    [camelCase(OnboardingActionsEnum.DownloadOurApp)]: t("downloadOurApp"),
    [camelCase(OnboardingActionsEnum.EnhanceYourProfile)]:
      t("enhanceYourProfile"),
    [camelCase(OnboardingActionsEnum.IntroduceYourself)]:
      t("introduceYourself"),
    [camelCase(OnboardingActionsEnum.ReviewCommunityValues)]: t(
      "reviewCommunityValues"
    ),
    [camelCase(OnboardingActionsEnum.OptIntoIntroductions)]: t(
      "optIntoIntroductions"
    ),
  }

  const currentUser = useCurrentUser()
  const userIsFreeTier = currentUser?.tier?.level === TierLevelEnum.Free
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const openWasRef = useRef(false)
  const [isHidden, setIsHidden] = useState(
    (currentUser?.checklistHidden && !userIsFreeTier) ||
      (currentUser?.checklistHidden && currentUser?.unlockedWelcomeGift)
  )
  const [startHideAnimation, setStartHideAnimation] = useState(false)
  const community = useCommunity()

  useEffect(() => {
    const onboardingActions = currentUser?.onboardingActions ?? {}
    const relevantActions =
      currentUser?.tier?.level === TierLevelEnum.Free
        ? Object.entries(onboardingActions).filter(
            ([key]) => key !== "optIntoIntroductions"
          )
        : Object.entries(onboardingActions)

    const allCompleted = relevantActions.every(([, value]) => Boolean(value))

    if (
      openWasRef.current === false &&
      !isHidden &&
      allCompleted &&
      !currentUser?.checklistHidden
    ) {
      openWasRef.current = true
      runUserUpdate({
        variables: {
          input: {
            checklistHidden: true,
          },
        },
      })
      setTimeout(() => {
        addConfetti()

        setTimeout(() => {
          // Kick off the animation to hide the checklist
          setStartHideAnimation(!userIsFreeTier)

          // Hide the checklist after the animation is complete
          setTimeout(() => {
            setIsHidden(!userIsFreeTier)
          }, 1000)
        }, 4000)
      }, 1500)
    }
  }, [
    currentUser?.checklistHidden,
    currentUser?.onboardingActions,
    currentUser?.tier?.level,
    runUserUpdate,
    isHidden,
    userIsFreeTier,
  ])

  const items = Object.values(OnboardingActionsEnum)
    .filter((key) => {
      return camelCase(key) in (currentUser?.onboardingActions ?? {})
    })
    .filter((key) => {
      return (
        key !== OnboardingActionsEnum.OptIntoIntroductions ||
        currentUser.permissions.canUseIntroductions
      )
    })
    .map((key) => {
      const camelCaseKey = camelCase(key)

      return {
        action: key as OnboardingActionsEnum,
        text: onboardingActionTexts[camelCaseKey],
        completed: Boolean(
          currentUser?.onboardingActions?.[
            camelCaseKey as keyof NonNullable<
              typeof currentUser.onboardingActions
            >
          ] ?? false
        ),
      }
    })
    .sort((a, b) => {
      const aIndex = itemOrder.indexOf(a.action)
      const bIndex = itemOrder.indexOf(b.action)
      return aIndex - bIndex
    })

  const areAllItemsCompleted = items.every((item) => item.completed)

  const { createRulesEngineEvent } = useRulesEngine()
  const { refetchUserOffers, userOffers } = useUserOffers()

  const paidMembershipUpsellOffer = userOffers.find((userOffer) => {
    return userOffer.offer.category === OfferCategoryEnum.PaidMembershipUpsell
  })

  const unlockGift = async () => {
    await createRulesEngineEvent(
      RulesEngineEventTypeEnum.UserAcceptedWelcomeOffer
    )
    await refetchUserOffers()

    runUserUpdate({
      variables: {
        input: {
          unlockedWelcomeGift: true,
        },
      },
    })
  }

  if (paidMembershipUpsellOffer) return null
  const showCompletedMessage = areAllItemsCompleted // && currentUser?.checklistHidden

  if (isHidden) return null

  return (
    <Card
      className={cn(
        "bg-card-highlight-background duration-1000",
        startHideAnimation ? "animate-out zoom-out-95 fade-out-0" : "",
        ccls({
          [CommunitySlug.Gotomillions]: "bg-secondary-background",
          [CommunitySlug.Fintechtakes]: "border-primary",
          default: "",
        })
      )}
    >
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <div className="flex items-center gap-1">
            <span>Get Started</span>
            <ArrowRight className="w-6 h-6" />
          </div>
          <GetStartedLink />
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 pt-4">
        {showCompletedMessage && (
          <div className="text-center bg-white rounded-lg p-4 shadow animate-in fade-in-0 zoom-in-50 duration-700">
            <h3 className="text-base font-semibold mb-2">
              You're ready to make the most of your membership
            </h3>
            {community.slug !== CommunitySlug.Fintechtakes && (
              <div className="flex items-center justify-center mt-4">
                <CreatorSignature />
              </div>
            )}
          </div>
        )}
        {!showCompletedMessage &&
          items.map(({ text, action, completed }) => (
            <ChecklistItem
              key={action}
              text={text}
              action={action}
              completed={completed}
            />
          ))}

        {userIsFreeTier && (
          <div className="text-center">
            <Button
              variant="link"
              size="sm"
              onClick={unlockGift}
              disabled={!areAllItemsCompleted}
            >
              <Translation ns="ctaChecklist" i18nKey="unlockWelcomeGift" />
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
