import { useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { Article_CardFragment, CommunitySlug } from "~/__generated__/graphql"
import { articlePath, libraryPath } from "~/common/paths"
import { HighlightContent } from "~/components/HighlightContent"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { DeepDiveSkeleton } from "./DeepDiveModule"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { formatDate } from "~/common/formatDate"
import { UserName } from "~/directory/UserName"
import { useCommunityClassname } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { Translation } from "~/common/Translation"
import { gql } from "~/__generated__"

export const NewInLibraryModule = () => {
  const ccls = useCommunityClassname()
  const { data, loading } = useQuery(NEW_ARTICLES_QUERY)
  const { article, authorNames, formattedDate } = useMemo(() => {
    if (!data) return { article: null, authorNames: "", formattedDate: "" }
    const article = data.articles.edges[0]?.node as Article_CardFragment | null
    if (!article) return { article: null, authorNames: "", formattedDate: "" }

    const authorNames = article.collaborators
      .map((c) => {
        return c.user ? `${c.user.firstName} ${c.user.lastName}` : ""
      })
      .join(", ")
    const formattedDate = formatDate(
      article.markedLiveAt || article.createdAt,
      "MMM do, yyyy"
    )

    return { article, authorNames, formattedDate }
  }, [data])

  return !loading && !article ? null : (
    <Card
      className={cn(
        ccls({
          [CommunitySlug.Gotomillions]: "bg-secondary-background",
          default: "",
        })
      )}
    >
      <CardHeader>
        <CardTitle>
          <Translation ns="newInLibraryModule" i18nKey="title" />
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-4">
        {loading && <DeepDiveSkeleton />}
        {!loading && article && (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <StackedUserAvatars
                users={article.collaborators.map((c) => c.user)}
              />
              {authorNames && (
                <div className="text-2xs">
                  {article.collaborators.length === 1 ? (
                    <UserName user={article.collaborators[0].user} />
                  ) : (
                    authorNames
                  )}
                </div>
              )}
            </div>
            <div className="font-medium text-sm">
              <Link
                to={articlePath({ articleId: article.id })}
                className="hover:underline"
              >
                {article.revision?.title}
              </Link>
            </div>
            <div className="text-2xs text-pretext-light-gray">
              <HighlightContent
                content={article.revision?.description || ""}
                truncationLimit={150}
                lineLimit={3}
              />
            </div>
            <div className="text-2xs text-pretext-light-gray">
              {formattedDate}
            </div>
            <Link
              to={libraryPath.pattern}
              className={cn(
                "text-sm tracking-wide hover:underline text-primary",
                ccls({
                  [CommunitySlug.Gotomillions]: "text-highlight",
                  [CommunitySlug.Fintechtakes]: "text-link",
                  default: "",
                })
              )}
            >
              See More
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export const NEW_ARTICLES_QUERY = gql(`
  query NewArticles {
    articles(
      revisionType: APPROVED,
      visibilities: [LIVE],
      states: [APPROVED HAS_PENDING_EDITS],
      sort: CREATED_AT_DESC,
      first: 1
      newlyAdded: true
    ) {
      edges {
        node {
          ...Article_Card
        }
      }
    }
  }
`)
