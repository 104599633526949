import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import * as paths from "~/common/paths"
import { ErrorBoundary } from "./ui/ErrorBoundary"
import { LoadingScreen } from "./screens/LoadingScreen"
import { SignInScreen } from "./screens/SignInScreen"
import { SignInTokenScreen } from "./screens/SignInTokenScreen"
import { RequireUserSignedOut } from "./layouts/RequireUserSignedOut"
import { RequireUserSignedIn } from "./layouts/RequireUserSignedIn"
import { FeedScreen } from "./screens/FeedScreen"
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout"
import { ArticleEditorScreen } from "./screens/ArticleEditorScreen"
import { EventsScreen } from "./screens/EventsScreen"
import { AskTheBotScreen } from "./screens/AskTheBotScreen"
import { PostScreen } from "./screens/PostScreen"
import { EditPostScreen } from "./screens/EditPostScreen"
import { BenchmarkScreen } from "./screens/BenchmarkScreen"
import { RootLayout } from "./layouts/RootLayout"
import { AdminLayout } from "./layouts/AdminLayout"
import { AdminMembersScreen } from "./screens/admin/AdminMembersScreen"
import { AdminLeadsScreen } from "./screens/admin/AdminLeadsScreen"
import { AdminIntroductionsScreen } from "./screens/admin/introduction-cycles/AdminIntroductionsScreen"
import { AdminNewIntroductionCycleScreen } from "./screens/admin/introduction-cycles/AdminNewIntroductionCycleScreen"
import { AdminIntroductionCycleScreen } from "./screens/admin/introduction-cycles/AdminIntroductionCycleScreen"
import { AdminEditIntroductionCycleScreen } from "./screens/admin/introduction-cycles/AdminEditIntroductionCycleScreen"
import { AdminChannelsScreen } from "./screens/admin/AdminChannelsScreen"
import { AdminProEventsScreen } from "./screens/admin/pro-events/AdminProEventsScreen"
import { AdminProEventNewScreen } from "./screens/admin/pro-events/AdminProEventNewScreen"
import { AdminProEventEditScreen } from "./screens/admin/pro-events/AdminProEventEditScreen"
import { AdminExperimentsScreen } from "./screens/admin/AdminExperimentsScreen"
import { AdminExperimentScreen } from "./screens/admin/AdminExperimentScreen"
import { MyContentScreen } from "./screens/MyContentScreen"
import { LibraryScreen } from "./screens/LibraryScreen"
import { EditProfileScreen } from "./screens/EditProfileScreen"
import { ArticleScreen } from "./screens/ArticleScreen"
import { MessagesScreen } from "./screens/MessagesScreen"
import { DirectoryScreen } from "./screens/DirectoryScreen"
import { BookmarksScreen } from "./screens/BookmarksScreen"
import { RequireUserActive } from "./layouts/RequireUserActive"
import { RequireUserInactive } from "./layouts/RequireUserInactive"
import { SubscribeScreen } from "./screens/SubscribeScreen"
import { FeedSkeletonLayout } from "./layouts/FeedSkeletonLayout"
import { NotFoundScreen } from "./screens/NotFoundScreen"
import { PullToRefresh } from "./layouts/PullToRefresh"
import { AdminEventsScreen } from "./screens/admin/AdminEventsScreen"
import { AccountSettingsScreen } from "./screens/AccountSettingsScreen"
import { RequireUserOnboarded } from "./layouts/RequireUserOnboarded"
import { FitWaitingScreen } from "./screens/onboarding/FitWaitingScreen"
import { UnfitScreen } from "./screens/onboarding/UnfitScreen"
import { FitRedirecting } from "./layouts/FitRedirecting"
import { LegalPolicyScreen } from "./screens/LegalPolicyScreen"
import { AuthenticatedOrExternalLayout } from "./layouts/AuthenticatedOrExternalLayout"
import { AdminAutoMessagesScreen } from "./screens/admin/auto-messages/AdminAutoMessagesScreen"
import { AdminAutoMessagesNewScreen } from "./screens/admin/auto-messages/AdminAutoMessagesNewScreen"
import { AdminAutoMessagesEditScreen } from "./screens/admin/auto-messages/AdminAutoMessagesEditScreen"
import { AdminDebugSettingsScreen } from "./screens/admin/AdminDebugSettingsScreen"
import { AdminOffersScreen } from "./screens/admin/offers/OffersScreen"
import { AdminAdministratorsScreen } from "./screens/admin/administrators/AdminAdministratorsScreen"
import { AdminApplicationsScreen } from "./screens/admin/AdminApplicationsScreen"
import { AdminDashboardScreen } from "./screens/admin/AdminDashboardScreen"
import { AdminHrisScreen } from "./screens/admin/AdminHrisScreen"
import { AdminLibraryScreen } from "./screens/admin/AdminLibraryScreen"
import { AdminGroupsScreen } from "./screens/admin/AdminGroupsScreen"
import { AdminGroupNewScreen } from "./screens/admin/AdminGroupNewScreen"
import { AdminGroupEditScreen } from "./screens/admin/AdminGroupEditScreen"
import { AdminSailthruSyncsScreen } from "./screens/admin/AdminSailthruSyncsScreen"
import { useApplicationRoutes } from "./application/useApplicationRoutes"
import { AdminScreenVariantsScreen } from "./screens/admin/AdminScreenVariantsScreen"
import { AdminScreenVariantNewScreen } from "./screens/admin/AdminScreenVariantNewScreen"
import { AdminScreenVariantEditScreen } from "./screens/admin/AdminScreenVariantEditScreen"
import { AdminChannelNewScreen } from "./screens/admin/AdminChannelNewScreen"
import { AdminChannelEditScreen } from "./screens/admin/AdminChannelEditScreen"
import { CommunityLayout } from "./layouts/CommunityLayout"

const Routes = () => {
  const applicationRoutes = useApplicationRoutes()

  return createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />} element={<RootLayout />}>
      <Route element={<CommunityLayout />}>
        <Route path={paths.loadingPath.pattern} element={<LoadingScreen />} />
        <Route element={<PullToRefresh />}>
          <Route
            path={paths.privacyPolicyPath.pattern}
            element={<LegalPolicyScreen policy="privacyPolicy" />}
          />
          <Route
            path={paths.subscriberAgreementPath.pattern}
            element={<LegalPolicyScreen policy="subscriberAgreement" />}
          />

          <Route element={<FitRedirecting />}>
            <Route element={<RequireUserOnboarded />}>
              <Route element={<RequireUserActive />}>
                <Route element={<AuthenticatedOrExternalLayout />}>
                  <Route
                    path={paths.articlePath.pattern}
                    element={<ArticleScreen />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route element={<RequireUserSignedIn />}>
            <Route
              path={paths.onboardingFitWaitingPath.pattern}
              element={<FitWaitingScreen />}
            />
            <Route path={paths.unfitPath.pattern} element={<UnfitScreen />} />

            <Route element={<FitRedirecting />}>
              <Route element={<RequireUserOnboarded />}>
                <Route element={<AuthenticatedLayout />}>
                  <Route path="*" element={<NotFoundScreen />} />

                  <Route element={<RequireUserInactive />}>
                    <Route element={<FeedSkeletonLayout />}>
                      <Route
                        path={paths.subscribePath.pattern}
                        element={<SubscribeScreen />}
                      />
                    </Route>
                  </Route>

                  <Route element={<RequireUserActive />}>
                    <Route
                      path={paths.benchmarkPath.pattern}
                      element={<BenchmarkScreen />}
                    />
                    <Route
                      path={paths.feedPath.pattern}
                      element={<FeedScreen />}
                    />
                    <Route
                      path={paths.channelPath.pattern}
                      element={<FeedScreen />}
                    />
                    <Route
                      path={paths.postPath.pattern}
                      element={<PostScreen />}
                    />
                    <Route
                      path={paths.editPostPath.pattern}
                      element={<EditPostScreen />}
                    />
                    <Route
                      path={paths.directoryPath.pattern}
                      element={<DirectoryScreen />}
                    />
                    <Route
                      path={paths.myContentPath.pattern}
                      element={<MyContentScreen />}
                    />
                    <Route
                      path={paths.libraryPath.pattern}
                      element={<LibraryScreen />}
                    />
                    <Route
                      path={paths.editArticlePath.pattern}
                      element={<ArticleEditorScreen />}
                    />
                    <Route
                      path={paths.messagesPath.pattern}
                      element={<MessagesScreen />}
                    >
                      <Route path={paths.dmPath.pattern} element={null} />
                      <Route path={paths.roomPath.pattern} element={null} />
                    </Route>
                    <Route
                      path={paths.eventsPath.pattern}
                      element={<EventsScreen />}
                    />
                    <Route
                      path={paths.bookmarksPath.pattern}
                      element={<BookmarksScreen />}
                    />
                    <Route
                      path={paths.bookmarksTypedPath.pattern}
                      element={<BookmarksScreen />}
                    />
                    <Route
                      path={paths.askTheBotPath.pattern}
                      element={<AskTheBotScreen />}
                    />

                    <Route
                      path={paths.editProfilePath.pattern}
                      element={<EditProfileScreen />}
                    />
                    <Route
                      path={paths.accountSettingsPath.pattern}
                      element={<AccountSettingsScreen />}
                    />
                  </Route>

                  <Route
                    path="/profile"
                    element={<Navigate to={paths.editProfilePath.pattern} />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route element={<RequireUserSignedOut />}>
            <Route path={paths.signInPath.pattern} element={<SignInScreen />} />
            <Route
              path={paths.signInTokenPath.pattern}
              element={<SignInTokenScreen />}
            />
          </Route>

          {applicationRoutes}
        </Route>
      </Route>

      <Route element={<AdminLayout />} path="/admin">
        <Route
          path={paths.adminDashboardPath.pattern}
          element={<AdminDashboardScreen />}
        />
        <Route
          path={paths.adminApplicationsPath.pattern}
          element={<AdminApplicationsScreen />}
        />
        <Route
          path={paths.adminMembersPath.pattern}
          element={<AdminMembersScreen />}
        />
        <Route
          path={paths.adminLeadsPath.pattern}
          element={<AdminLeadsScreen />}
        />
        <Route
          path={paths.adminAdministratorsPath.pattern}
          element={<AdminAdministratorsScreen />}
        />
        <Route
          path={paths.adminAutoMessagesPath.pattern}
          element={<AdminAutoMessagesScreen />}
        />
        <Route
          path={paths.adminAutoMessageNewPath.pattern}
          element={<AdminAutoMessagesNewScreen />}
        />
        <Route
          path={paths.adminAutoMessageEditPath.pattern}
          element={<AdminAutoMessagesEditScreen />}
        />
        <Route
          path={paths.adminIntroductionsPath.pattern}
          element={<AdminIntroductionsScreen />}
        />
        <Route
          path={paths.adminNewIntroductionCyclePath.pattern}
          element={<AdminNewIntroductionCycleScreen />}
        />
        <Route
          path={paths.adminIntroductionCyclePath.pattern}
          element={<AdminIntroductionCycleScreen />}
        />
        <Route
          path={paths.adminEditIntroductionCyclePath.pattern}
          element={<AdminEditIntroductionCycleScreen />}
        />
        <Route
          path={paths.adminProEventsPath.pattern}
          element={<AdminProEventsScreen />}
        />
        <Route
          path={paths.adminProEventNewPath.pattern}
          element={<AdminProEventNewScreen />}
        />
        <Route
          path={paths.adminProEventEditPath.pattern}
          element={<AdminProEventEditScreen />}
        />
        <Route
          path={paths.adminChannelsPath.pattern}
          element={<AdminChannelsScreen />}
        />
        <Route
          path={paths.adminChannelNewPath.pattern}
          element={<AdminChannelNewScreen />}
        />
        <Route
          path={paths.adminChannelEditPath.pattern}
          element={<AdminChannelEditScreen />}
        />
        <Route
          path={paths.adminGroupsPath.pattern}
          element={<AdminGroupsScreen />}
        />
        <Route
          path={paths.adminGroupNewPath.pattern}
          element={<AdminGroupNewScreen />}
        />
        <Route
          path={paths.adminGroupEditPath.pattern}
          element={<AdminGroupEditScreen />}
        />
        <Route
          path={paths.adminLibraryPath.pattern}
          element={<AdminLibraryScreen />}
        />
        <Route
          path={paths.adminHrisPath.pattern}
          element={<AdminHrisScreen />}
        />

        <Route
          path={paths.adminDebugSettingsPath.pattern}
          element={<AdminDebugSettingsScreen />}
        />

        <Route
          path={paths.adminEventsPath.pattern}
          element={<AdminEventsScreen />}
        />
        <Route
          path={paths.adminSailthruSyncsPath.pattern}
          element={<AdminSailthruSyncsScreen />}
        />
        <Route
          path={paths.adminOffersPath.pattern}
          element={<AdminOffersScreen />}
        />
        <Route
          path={paths.adminScreenVariantsPath.pattern}
          element={<AdminScreenVariantsScreen />}
        />
        <Route
          path={paths.adminScreenVariantNewPath.pattern}
          element={<AdminScreenVariantNewScreen />}
        />
        <Route
          path={paths.adminScreenVariantEditPath.pattern}
          element={<AdminScreenVariantEditScreen />}
        />
        <Route
          path={paths.adminExperimentsPath.pattern}
          element={<AdminExperimentsScreen />}
        />
        <Route
          path={paths.adminExperimentPath.pattern}
          element={<AdminExperimentScreen />}
        />
        <Route
          path="/admin"
          element={<Navigate to={paths.adminDashboardPath.pattern} />}
        />
      </Route>
    </Route>
  )
}

export const router = createBrowserRouter(Routes())
